export const RECOMMANDATIONS_FULLSCREEN_BUTTON_STYLE = {
  default: {
    position: 'fixed'
  },
  smallContainer: {
    bottom: 10,
    right: 12
  },
  mediumContainer: {
    bottom: 5,
    right: 18
  },
  largeContainer: {
    bottom: 30,
    right: 36
  },
  extraLargeContainer: {
    bottom: 40,
    right: 48
  }
};

export const RECOMMANDATIONS_CONTAINER_STYLE = {
  default: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%'
  },
  smallContainer: { gap: '75px' },
  mediumContainer: { gap: '132px' },
  largeContainer: { gap: '200px' },
  extraLargeContainer: { gap: '300px' }
};

export const RECOMMANDATIONS_CONTAINER_VERTICAL_STYLE = {
  default: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%'
  },
  smallContainer: { gap: '37.5px' },
  mediumContainer: { gap: '66px' },
  largeContainer: { gap: '100px' },
  extraLargeContainer: { gap: '150px' }
};
