import { h } from 'preact';
import { useEffect, useRef, useState } from 'preact/hooks';
import { usePlayerContext, useStyles } from '../../hooks';
import { Button, LabeledButton } from '../common';
import { ERROR_STYLES as styles } from './styles';
import { FULLSCREEN_OFF_BUTTON_PATH } from '../../theme/svg';
import { TRANSITION_DURATION } from '../../theme/colors';

function Error({ code, message, canRetry, showCode, ip, isFullscreen }) {
  const [copied, setCopied] = useState(0);
  const player = usePlayerContext();
  const retryBtn = useRef(null);
  const backBtn = useRef(null);
  const mainDiv = useRef(null);
  const setStyles = useStyles();

  useEffect(() => {
    if (backBtn.current) return backBtn.current.base.focus();
    if (retryBtn.current) return retryBtn.current.base.focus();
    return mainDiv.current.focus();
  }, []);

  return (
    <div ref={mainDiv} className="ftv-magneto--error">
      <div
        role="status"
        tabIndex="0" /* eslint-disable-line */ ariaLabel={`${message.replace(/(<([^>]+)>)/gi,'')}${showCode ? ` (erreur ${code})` : ''}`}
        style={setStyles(styles.wrapper)}
      >
        <span
          role="presentation"
          onClick={(e) => {
            navigator.clipboard.writeText(e.target.innerHTML);
            setCopied(1);
            setTimeout(() => setCopied(0), 2000);
          }}
          ariaHidden
          style={styles.errorMessage}
          dangerouslySetInnerHTML={{ __html: message + (ip ? `<br/>IP: ${ip}` : '')} /* eslint-disable-line */}
        />
        {showCode && (
          <span ariaHidden style={{ ...setStyles(styles.errorCode) }}>
            {`( erreur ${code} )`}
          </span>
        )}
        <span style={{
          ...setStyles(styles.errorCode),
          transition: `opacity .2s ease-in-out ${TRANSITION_DURATION}ms`,
          opacity: copied
        }}
        >
          copié !
        </span>
      </div>
      <div style={setStyles(styles.errorButtons)}>
        <LabeledButton
          ref={backBtn}
          id="btn-error-back"
          label="retour"
          customStyle={{
            ...setStyles(styles.errorButton),
            ...setStyles(styles.tvOnlyButton)
          }}
        />
        {canRetry && (
          <LabeledButton
            ref={retryBtn}
            id="btn-error-retry"
            label="réessayer"
            onClick={() => player.retry()}
            customStyle={{ ...setStyles(styles.errorButton) }}
          />
        )}
      </div>
      <div style={styles.errorIcon}>
        {isFullscreen && (
          <Button
            label="quitter le plein écran (f)"
            ariaLabel="quitter le plein écran (f)"
            onClick={() => {
              player.fullscreen(!isFullscreen);
            }}
            last
            svg={FULLSCREEN_OFF_BUTTON_PATH}
            xs
          />
        )}
      </div>
    </div>
  );
}

export default Error;
