import { filter, map, bufferCount, switchMap, take, withLatestFrom, distinctUntilChanged } from 'rxjs/operators';
import { PLAYBACK_CANPLAY, PLAYBACK_CANPLAYTHROUGH, PLAYBACK_PLAY, PLAYBACK_PLAYING, PLAYBACK_PROGRESS, PLAYBACK_TIMEUPDATE } from '../../../store/types';

const MIDROLL = 'MIDROLL';

export const isMidroll = ({ isAd, position }) => isAd && position === MIDROLL;

export const timecodeAfterMidroll = (isAd$, adCountdown$, player, isTagUnique) => {
  const { rendererController: { currentTime$ }, events$ } = player;

  const timecodeAtMidroll$ = adCountdown$.pipe(
    distinctUntilChanged((a, b) => a.showCountdown === b.showCountdown),
    filter(({ showCountdown }) => showCountdown),
    // Countdown starts 5s before the adbreak, and we want to go back 10s before the adbreak
    // ence we compute the timer 5s before the start of the countdown
    withLatestFrom(currentTime$, (_, currentTime) => currentTime - 5)
  );

  const playingAfterMidroll$ = isAd$.pipe(
    filter(({ position, isAd }) => !isAd || isMidroll({ isAd, position })),
    map(({ isAd }) => isAd),
    bufferCount(2, 1),
    filter(([a, b]) => a && !b),
    switchMap(() => events$.pipe(
      filter((e) => (isTagUnique
        ? [PLAYBACK_CANPLAYTHROUGH, PLAYBACK_PROGRESS]
        : [PLAYBACK_PLAY, PLAYBACK_PLAYING, PLAYBACK_CANPLAY, PLAYBACK_TIMEUPDATE, PLAYBACK_CANPLAYTHROUGH, PLAYBACK_PROGRESS]
      ).includes(e))
    ).pipe(take(1)))
  );

  return playingAfterMidroll$.pipe(
    withLatestFrom(timecodeAtMidroll$, (_, timecode) => timecode)
  );
};

const rewindMidroll = {
  init(isAd$, adCountdown$, player, isTagUnique) {
    return timecodeAfterMidroll(isAd$, adCountdown$, player, isTagUnique).subscribe((timecode) => player.seek(timecode));
  }
};

export default rewindMidroll;
