/* eslint-disable react/no-unknown-property */
import { h } from 'preact';
import TvNextIconActive from './TvNextIconActive';

function TVPreviousActiveIcon({ height = 60, width = 60 }) {
  return (
    <TvNextIconActive width={width} height={height} style={{ transform: 'scaleX(-1)' }} />
  );
}

export default TVPreviousActiveIcon;
