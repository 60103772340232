import { h, Fragment } from 'preact';
import Warning from '../warning';
import TapLayer from './TapLayer';
import FeedbackOverlays from '../feedback/FeedbackOverlay';
import { connect, withBreakPoints } from '../../hoc';
import { PIPView } from '../info';
import { usePlayerContext } from '../../hooks';
import { SkipButton } from '../controls';
import { SKIP_BTN_INTRO_OVERRIDE } from '../common/buttons/styles';
import { UI_SKIP_INTRO_CLICKED, UI_SKIP_RECAP_CLICKED } from '../../../store/types';
import { COMING_NEXT, NEXT, USER_REQUEST_MEDIA_LOAD } from '../../../types';
import Spinner from '../spinner';

function SectionOverlay({
  waitStart,
  handleTap,
  containerVisible,
  forceHidden,
  mobile,
  extraSmall,
  isAd,
  skipIntroDuration,
  skipIntroTimeCode,
  showSkipIntro,
  showSkipRecap,
  skipRecapDuration,
  skipRecapTimeCode,
  showComingNext,
  next,
  playlist,
  showNextPlaylistBtn,
  currentPlaylistVideo
}) {
  const player = usePlayerContext();
  const getNextClickEvent = (isNext, isPlaylist) => {
    if (isNext) {
      return { name: NEXT, payload: COMING_NEXT };
    }

    if (isPlaylist) {
      const nextPlaylistVideo = currentPlaylistVideo + 1;
      const tracking = {
        /* position is the human readable version of the index, hence the +1 */
        diffusion: { mode: 'tunnel', position: nextPlaylistVideo, length: playlist.length },
        tracking: {
          pageProvenance: '',
          zoneProvenance: 'playlist_player',
          positionVignette: '',
          playProvenance: 'playlist'
        },
        comingNext: nextPlaylistVideo !== playlist.length ? {
          program: playlist[nextPlaylistVideo]?.title ?? playlist[nextPlaylistVideo].config.program,
          title: playlist[nextPlaylistVideo]?.preTitle ?? playlist[nextPlaylistVideo].config.title
        } : {}
      };
      return {
        action: USER_REQUEST_MEDIA_LOAD,
        value: {
          src: playlist[nextPlaylistVideo].src,
          config: {
            ...playlist[nextPlaylistVideo].config,
            intro: false,
            autostart: true,
            ...tracking
          }
        }
      };
    }
    return {};
  };
  return (
    <Fragment>
      <PIPView />
      <TapLayer
        /* TapLayer handles initial click to start player in autostart: false
        * and "tap to hide UI" behavior on mobile devices */
        isMobile={mobile}
        ariaLabel="lecture"
        onTap={waitStart ? () => player.play({ userGesture: true }) : handleTap}
        visible={(waitStart || (mobile && !containerVisible)) && !forceHidden}
      />
      <Spinner />
      <Warning />
      <FeedbackOverlays />
      {(!extraSmall && !isAd && (
      <SkipButton
        duration={skipIntroDuration}
        timecode={skipIntroTimeCode}
        label="passer le générique"
        show={showSkipIntro}
        style={SKIP_BTN_INTRO_OVERRIDE}
        clickEvent={UI_SKIP_INTRO_CLICKED}
      />
      )) }
      {(!extraSmall && !isAd && (
      <SkipButton
        duration={skipRecapDuration}
        timecode={skipRecapTimeCode}
        label="passer le récap"
        show={showSkipRecap}
        clickEvent={UI_SKIP_RECAP_CLICKED}
      />
      )) }
      {(!extraSmall && !isAd && (next || showNextPlaylistBtn) && (
      <SkipButton
        label="vidéo suivante"
        show={showComingNext}
        clickEvent={getNextClickEvent(next, showNextPlaylistBtn)}
        seek={false}
      />
      ))}
    </Fragment>
  );
}

const selector = ({
  playlist: { playlist, playlistEnabled = Boolean(playlist.length) },
  ui: {
    comingNextVisible,
    isFullscreen,
    forceHidden
  },
  media: { isAd, id },
  media: {
    skipIntro: { timecode: skipIntroTimeCode, duration: skipIntroDuration },
    skipRecap: { timecode: skipRecapTimeCode, duration: skipRecapDuration }
  },
  ui: { showSkipIntro, showSkipRecap, showComingNext, next }
}) => ({
  forceHidden,
  isAd,
  isFullscreen,
  comingNextVisible,
  skipIntroDuration,
  skipIntroTimeCode,
  showSkipIntro,
  showSkipRecap,
  skipRecapDuration,
  skipRecapTimeCode,
  showComingNext,
  next,
  playlist,
  showNextPlaylistBtn: playlistEnabled && playlist[playlist.length - 1].src !== id,
  currentPlaylistVideo: playlist.findIndex((v) => v.src === id)
});

export default withBreakPoints(connect(selector)(SectionOverlay));
