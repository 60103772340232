import { h } from 'preact';
import { useCallback, useMemo, useState } from 'preact/hooks';
import { TV_SKYP_BTN_STYLE } from './style';
import { usePlayerContext } from '../../../../ui/hooks';
import { Delayed } from '../../../../ui/hoc';
import { TVButton } from '../tvbutton';
import { DARK_PRIMARY_TEXT_COLOR, FTV_DARK_PRIMARY_BACKGROUND_COLOR, PRIMARY_FILL_COLOR } from '../../../../ui/theme/colors';

export function TVSkipButton({
  show,
  timecode,
  duration,
  label,
  clickEvent,
  seek = true
}) {
  if ((timecode && duration) === null) {
    return null;
  }
  const player = usePlayerContext();
  const [isActive, setIsActive] = useState(false);

  const btn = useCallback((node) => {
    if (node) setTimeout(() => node.focus(), 100);
  }, []);

  const style = useMemo(
    () => ({
      ...TV_SKYP_BTN_STYLE,
      animation: show ? 'ftv-magneto-skip-button-slide-up .3s' : 'ftv-magneto-skip-button-slide-down .3s',
      background: isActive ? FTV_DARK_PRIMARY_BACKGROUND_COLOR : 'rgba(240, 241, 243, 0.1)',
      border: isActive ? FTV_DARK_PRIMARY_BACKGROUND_COLOR : 'rgba(240, 241, 243, 0.1)',
      color: isActive ? DARK_PRIMARY_TEXT_COLOR : PRIMARY_FILL_COLOR
    }),
    [isActive, show]
  );

  return (
    <Delayed isMounted={show} shouldDelay delay={300}>
      {() => (
        <TVButton
          size={72}
          btnRef={btn}
          onClick={(e) => {
            e.stopPropagation();
            player.trigger(clickEvent);
            player.userEvents$.next(clickEvent);
            if (seek) {
              player.seek(timecode + duration);
            }
          }}
          onFocus={() => setIsActive(true)}
          onBlur={() => setIsActive(false)}
          label={label}
          style={style}
        />
      )}
    </Delayed>
  );
}
