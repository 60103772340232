import { Fragment, h } from 'preact';
import { useEffect, useMemo, useState } from 'preact/hooks';
import { connect } from '../../../ui/hoc';
import { computeVideoTitle } from '../../../utils/videoMetaUtils';
import { H1, H2, TITLE_WRAPPER, TV_LOGO_WRAPPER } from './styles';
import { selfOrKeyObject } from '../../../utils';

function HeaderInfos({
  videoAdditionalTitle,
  videoTitle,
  videoPreTitle,
  subTitle,
  forceShowTitle,
  videoLogo,
  isAd,
  isLive,
  isTv,
  isDVR,
  showTitle,
  showCountdown,
  isLargeTvScreen
}) {
  if (!videoTitle || isAd) return null;
  const { computedTitle, computedSubtitle } = useMemo(
    () => computeVideoTitle(
      videoTitle,
      subTitle,
      videoPreTitle,
      videoAdditionalTitle
    ),
    [videoTitle, subTitle, videoPreTitle, videoAdditionalTitle]
  );
  const shouldShowInfo = !isAd
  && showTitle
  && !showCountdown
  && (isDVR || isTv || forceShowTitle);

  const shouldDisplayLogo = Boolean(selfOrKeyObject(videoLogo, 'url')) && shouldShowInfo && isLive;

  const LOGO_STYLE = isLargeTvScreen ? TV_LOGO_WRAPPER.largeTv : TV_LOGO_WRAPPER.extraLargeTv;

  const [containerheight, setContainerHeight] = useState(TITLE_WRAPPER.height);

  const BOTTOM_MARGIN = 30;
  const METAS_HEIGHT = 50;

  useEffect(() => {
    let containerSize = (computedTitle.length ? METAS_HEIGHT : 0) + (computedSubtitle.length ? METAS_HEIGHT : 0);

    if (computedTitle.length && !computedSubtitle.length) {
      containerSize += BOTTOM_MARGIN;
    }
    setContainerHeight(containerSize);
  }, [computedTitle, computedSubtitle]);

  return (
    <Fragment>
      { shouldDisplayLogo && (
      <div style={LOGO_STYLE}>
        <img
          src={videoLogo?.url || videoLogo}
          alt={videoLogo?.alt || 'channel logo'}
          style={{ width: '100%' }}
        />
      </div>
      )}
      <div style={{ ...(shouldDisplayLogo ? { ...TITLE_WRAPPER, left: LOGO_STYLE.width } : TITLE_WRAPPER), height: containerheight }}>
        <h1 style={H1}>{computedTitle}</h1>
        <h2 style={H2}>{computedSubtitle}</h2>
      </div>
    </Fragment>
  );
}

const selector = ({
  media: {
    videoAdditionalTitle,
    videoTitle,
    videoPreTitle,
    subTitle,
    videoLogo,
    isAd,
    isLive,
    isDVR,
    isTv
  },
  ui: {
    showTitle,
    forceShowTitle,
    displayComingNextOnStart,
    isLargeTvScreen
  }
}) => ({
  videoAdditionalTitle,
  videoTitle,
  videoPreTitle,
  subTitle,
  forceShowTitle,
  videoLogo,
  isAd,
  isLive,
  isTv,
  isDVR,
  displayComingNextOnStart,
  showTitle,
  isLargeTvScreen
});

export default connect(selector)(HeaderInfos);
