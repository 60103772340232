import { PRIMARY_FILL_COLOR } from '../../theme/colors';

export const ERROR_STYLES = {
  wrapper: {
    default: {
      margin: 'auto',
      fontFamily: 'Brown',
      fontWeight: 400,
      fontSize: '18px'
    },
    extraLarge: { fontSize: '24px', padding: '0 48px' },
    large: { fontSize: '24px', padding: '0 48px' },
    medium: { fontSize: '18px', padding: '0 36px' },
    small: { fontSize: '16px', padding: '0 18px' },
    extraSmall: { fontSize: '12px', padding: '0 12px' },
    'largeTv+extraLargeTv': { padding: '0 80px' }
  },
  errorCode: {
    default: { display: 'block' }
  },
  errorMessage: {
    width: '100%',
    margin: '50px 0',
    cursor: 'pointer'
  },
  errorIcon: {
    position: 'fixed',
    bottom: '20px',
    marginLeft: 'calc(100% - 255px)'
  },
  errorButtons: {
    'largeTv+extraLargeTv': {
      display: 'flex',
      justifyContent: 'center',
      gap: 40,
      marginTop: 40
    },
    'extraLarge+large': { marginTop: 32 },
    medium: { marginTop: 28 },
    small: { marginTop: 26 },
    extraSmall: { marginTop: 24 }
  },
  errorButton: {
    default: { marginRight: 0, marginTop: 0 },
    small: { marginLeft: 0, marginRight: 0 },
    extraSmall: { marginLeft: 0, marginRight: 0 },
    extraLargeTv: {
      backgroundColor: '#F0F1F300',
      border: '1.75px solid #F0F1F3',
      color: PRIMARY_FILL_COLOR,
      height: 72,
      fontSize: 30,
      padding: '0 30px',
      borderRadius: 72
    },
    largeTv: {
      backgroundColor: '#F0F1F300',
      border: '1.75px solid #F0F1F3',
      color: PRIMARY_FILL_COLOR,
      height: 48,
      fontSize: 20,
      padding: '0 20px',
      borderRadius: 72
    }
  },
  tvOnlyButton: {
    default: { display: 'none' },
    'largeTv+extraLargeTv': {
      display: 'inherit'
    }
  }
};
