/* global tv */
/* eslint-disable no-underscore-dangle */

import { BehaviorSubject, combineLatest, fromEvent, lastValueFrom, merge, NEVER, of, ReplaySubject, Subject, throwError } from 'rxjs';
import {
  bufferCount,
  distinctUntilChanged,
  distinctUntilKeyChanged,
  filter,
  map,
  mapTo,
  scan,
  share,
  skip,
  startWith,
  switchMap,
  switchMapTo,
  take,
  tap,
  withLatestFrom
} from 'rxjs/operators';

import AdLayer from './AdLayer';
import MidrollManager from './MidrollManager';
import requiredFocus from './features/requiredFocus';
import { isTagUnique, isUrlReachable, sessionStorage, VOID_FN } from '../../utils';

import { freewheel as freewheelConfig } from '../../configuration';
import { ADBREAK_HOOK, BEFORE_HOOK } from '../../core/interruptor/types';
import { FREEWHEEL_AD, FREEWHEEL_AD_CDN, FREEWHEEL_DEFAULT_QUERIES, TIME_LEFT } from './types';
import { AD_COMPLETE, AD_COUNTDOWN, AD_END, AD_IMPRESSION, AD_IMPRESSION_END, AD_INITIATED, AD_START, DISPLAY_AD_COMPANION, PLAYBACK_END, PLAYBACK_PLAYING } from '../../store/types';
import { MUTE, PAUSE, PLAY, VOLUME } from '../../core/command/types';
import rewindMidroll from './features/rewindMidroll';
import { TIMESHIFTING_AUTO_CONTROLLER_LOADED } from '../../types';
import { Disposable } from '../../core';
import { TV_PLATFORMS } from '../../core/dom/types';

export default class FreewheelController extends Disposable {
  constructor({ player, playerConfig, init$ }) {
    super();
    this.events$ = player.events$;
    this.adContext$ = new ReplaySubject(1);
    this.slots$ = new ReplaySubject(1);
    this.currentSlot$ = new ReplaySubject(1);
    this.currenAdContext = null;
    this.withTagUnique = TV_PLATFORMS.includes(playerConfig?.platform);

    this.adContext$.subscribe((adContext) => {
      this.currenAdContext = adContext;
    });

    this.isAd$ = new BehaviorSubject({ isAd: false, position: null, isVpaid: false });

    this.isAdRequested$ = new ReplaySubject(1);

    merge(
      player.mediaController.medias$,
      fromEvent(player, TIMESHIFTING_AUTO_CONTROLLER_LOADED)
        .pipe(switchMap(() => player.timeshiftingAutoController.isOnLiveCurrentProgram$))
        .pipe(filter((isLive) => !isLive)),
      this.events$.pipe(filter((e) => e === PLAYBACK_END))
    )
      .pipe(map(() => false))
      .subscribe(this.isAdRequested$);

    this.adClick$ = new Subject();
    this.adLayer = new AdLayer(player.domController, player.videoUnlocker, this.isAd$);

    /* events */
    this.slotEndedEvent$ = new Subject();
    this.slotStartedEvent$ = new Subject();
    this.errorEvent$ = new Subject();
    this.adEvent$ = new Subject();
    this.slotEvents$ = new Subject();
    this.adCountdown$ = new Subject();
    this.pauseRollContent$ = new Subject();

    this.adsCountAndDurationsState$ = new Subject();

    FreewheelController.createAdsCountAndDurationsStateStream(this.slotEvents$).subscribe(this.adsCountAndDurationsState$);

    /**
     * PlayerCore will wait for this.setup promise before triggering
     * the core observable sequence. FreewheelController's initAdvertising call
     * depends on the init$ stream passed to FreewheelController
     * (init$ = PlayerCore's ready state stream setupReady$)
     */
    this.setup = FreewheelController.createSetupStream(playerConfig.showAd);

    /* dispose any previous adContext on new context creation */
    this.adContext$.pipe(bufferCount(2, 1)).subscribe(([{ adContext }]) => (adContext?.dispose()));

    init$
      .pipe(switchMapTo(this.setup), filter(({ init }) => init))
      .subscribe(() => this.initAdvertising(player, playerConfig));
  }

  static createAdsCountAndDurationsStateStream(slotEvents$) {
    return slotEvents$.pipe(
      filter(({ type }) => (AD_START === type)),
      map(({ slot }) => ({ totalDuration: slot.getTotalDuration() }))
    );
  }

  static createSetupStream(showAd) {
    /* should return a resolved promise even if FW fails to init */
    if (!showAd) {
      return Promise.resolve({ init: false, isAdServerReachable: true });
    }

    const formatSetupStatus = (isAdServerReachable = false) => ({ init: false, isAdServerReachable });

    return FreewheelController.isAdServerReachable()
      .then((reachable) => (
        reachable === true
          ? FreewheelController.importFreewheelLibrary()
          : Promise.resolve(formatSetupStatus(reachable))
      ))
      .catch(() => Promise.resolve(formatSetupStatus()));
  }

  static async isAdServerReachable() {
    return lastValueFrom(
      isUrlReachable(`${freewheelConfig.server}${FREEWHEEL_DEFAULT_QUERIES}`)
    );
  }

  static async loadScript() {
    const isAlreadyLoaded = window._fwadmanager
      || document.body.querySelector(`script[src="${FREEWHEEL_AD_CDN}"]`);

    if (isAlreadyLoaded) return Promise.resolve();

    const newScript = document.createElement('script');
    newScript.src = FREEWHEEL_AD_CDN;
    newScript.async = true;
    document.body.appendChild(newScript);

    return new Promise((resolve, reject) => {
      newScript.onload = () => resolve();
      newScript.onerror = () => reject(new Error(`Script load error for ${FREEWHEEL_AD_CDN}`));
    });
  }

  static async importFreewheelLibrary() {
    await FreewheelController.loadScript();
    return ({ type: FREEWHEEL_AD, init: true, isAdServerReachable: true });
  }

  static isValidSlot(slot) {
    return (slot && slot.getAdCount() > 0);
  }

  resetFreewheel() {
    this.setup = FreewheelController.createSetupStream(false);
  }

  setupInterruptions(player, playerConfig) {
    const { interruptor } = player;
    const { duration$ } = player.rendererController;
    const { dimensions$ } = player.domController;
    const { medias$: media$ } = player.mediaController;

    interruptor.mapToInterruption(this.midrollManager.adBreakTriggered$, ADBREAK_HOOK);
    FreewheelController.createMidrollCountDown(player, this.midrollManager, this.slots$).subscribe(this.adCountdown$);

    interruptor.registerInterruption(BEFORE_HOOK, {
      onWillStart: () => combineLatest(media$, duration$.pipe(skip(1)), dimensions$)
        .pipe(
          take(1),
          switchMap(([media, duration, { width, height }]) => {
            const {
              config: mediaConfig,
              markers: { pub: adConfig },
              video: { is_live: isLive } = { is_live: false }
            } = media;
            const nbMidroll = MidrollManager.midrollFromDuration(duration);
            const config = {
              ...FreewheelController.getAdConfig(nbMidroll, playerConfig, adConfig, mediaConfig),
              isLive
            };

            if (!config.showAd || (FreewheelController.getCappingId() && adConfig.capping)) return throwError(() => new Error());
            return of(this.createAdContext(config, duration, width, height, playerConfig?.platform));
          }),
          switchMap(({ adContext, adConfig }) => {
            tv.freewheel.SDK.setLogLevel(playerConfig?.debug
              ? tv.freewheel.SDK.LOG_LEVEL_DEBUG
              : tv.freewheel.SDK.LOG_LEVEL_QUIET);
            adContext.submitRequest(freewheelConfig.requestTimeout);
            this.isAdRequested$.next(true);
            return FreewheelController.handleRequestComplete(adContext, adConfig).pipe(
              tap((slots) => {
                /* handle side-effects */
                this.slots$.next(slots);
                this.adContext$.next({ adContext, adConfig });
                this.midrollManager.nbMidroll$.next(adConfig.nbMidroll);
                this.midrollManager.currentMidrollIndex$.next(0);
              }),
              map((slots) => slots.filter((slot) => slot.getTimePositionClass() === tv.freewheel.SDK.TIME_POSITION_CLASS_PREROLL)[0]),
              switchMap((slot) => {
                const willProbablyPlayAnAd = FreewheelController.isValidSlot(slot);
                const params = { slot, position: slot.getTimePositionClass(), willProbablyPlayAnAd };
                return (willProbablyPlayAnAd ? of(params) : throwError(() => params));
              })
            );
          })
        ),
      onDidStart: this.handleSlotStart.bind(this),
      onResolve: this.handleSlotResolve.bind(this),
      onCancel: this.handleSlotCancel.bind(this)
    });

    /* Register midroll interruption on ADBREAK_HOOK */
    interruptor.registerInterruption(ADBREAK_HOOK, {
      onWillStart: () => this.slots$.pipe(
        withLatestFrom(this.midrollManager.currentMidrollIndex$),
        switchMap(([slots, currentMidrollIndex]) => {
          const midrollSlot = slots.filter(
            (slot) => slot.getTimePositionClass() === tv.freewheel.SDK.TIME_POSITION_CLASS_MIDROLL
          )?.[currentMidrollIndex];

          const willProbablyPlayAnAd = FreewheelController.isValidSlot(midrollSlot);
          const params = {
            slot: midrollSlot,
            index: currentMidrollIndex,
            position: tv.freewheel.SDK.TIME_POSITION_CLASS_MIDROLL,
            willProbablyPlayAnAd
          };

          return willProbablyPlayAnAd ? of(params) : throwError(() => params);
        })
      ),
      onDidStart: this.handleSlotStart.bind(this),
      onResolve: this.handleSlotResolve.bind(this),
      onCancel: this.handleSlotCancel.bind(this)
    });
  }

  initAdvertising(player, playerConfig = {}) {
    const { events$: playerEvent$ } = player;
    const { commands$ } = player.commandController;

    this.midrollManager = new MidrollManager(player);
    /**
     * EVENT HANDLING
     * - mapRawEvents for Interruptor triggers and Youbora hits
     * - proxy player events to internal FW state
     * - proxy FW events to player events$
     */
    this.mapRawEvents(this.adContext$);
    this.slotEvents$.pipe(withLatestFrom(this.adsCountAndDurationsState$), FreewheelController.formatSlotEvent()).subscribe(playerEvent$);
    this.isAd$.pipe(filter(({ isAd }) => isAd), mapTo(PLAYBACK_PLAYING)).subscribe(playerEvent$); /* FW has no _playing event */
    this.subscribeToSlotEvents(this.slotEvents$);
    this.subscribeToTimeleft(this.currentSlot$, playerEvent$);

    const { slotEnded$, slotStarted$, adEvent$ } = FreewheelController.mapAdContextEvents(this.adContext$);
    merge(slotStarted$, slotEnded$, adEvent$).subscribe(this.slotEvents$);
    FreewheelController.registerPlayerEvents(playerEvent$, this.adContext$);

    /**
     * Features :
     * - requiredFocus on blur
     * - rewind after midroll
     * - handle custom adClick
     */
    requiredFocus.init(playerConfig, this.slotEvents$, this.currentSlot$, this.isAd$);
    rewindMidroll.init(this.isAd$, this.adCountdown$, player, isTagUnique(playerConfig));

    FreewheelController.setupAdClickStream(this.adClick$, this.adEvent$)
      .subscribe((adInstance) => adInstance.getRendererController().processEvent({ name: tv.freewheel.SDK.EVENT_AD_CLICK }));

    FreewheelController.updateIsAd(this.adEvent$, this.isAd$)
      .subscribe(this.isAd$);

    /**
     * Player commands handling
     * - IOS: Handle adLayer fullscreen change -> pause
     */
    this.isAd$.pipe(switchMap(({ isAd }) => (isAd ? FreewheelController
      .mapCommandToSlot(this.currentSlot$, merge(commands$, this.adLayer.fullscreenExit$.pipe(mapTo({ type: PAUSE })))) : NEVER)))
      .subscribe(({ type, slot, value }) => this.commandHandler(type, slot, value));

    /**
     * Setup Interruptions
     * - BEFORE_HOOK for preroll
     * - ADBREAK_HOOK for midroll
     */
    this.setupInterruptions(player, playerConfig);

    FreewheelController.setupCappingId(player.medias$, this.slotEvents$);

    FreewheelController.setupDisplayCompanionStream(this.adEvent$).subscribe(playerEvent$);
    FreewheelController.setupAdPauseStream(this.slots$).subscribe(this.pauseRollContent$);
  }

  static setupDisplayCompanionStream(adEvent$) {
    return adEvent$.pipe(
      filter((e) => e.subType === AD_INITIATED && e.adInstance._companionAdInstances.length),
      map((e) => ({
        name: DISPLAY_AD_COMPANION,
        payload: JSON.parse(e.adInstance._companionAdInstances[0]._creative._creativeRenditions[0]._primaryCreativeRenditionAsset._content)
      }))
    );
  }

  static setupAdPauseStream(slots$) {
    return slots$.pipe(
      map((slots) => slots.filter((slot) => slot.isPauseSlot() && slot.getAdInstances().length)),
      map((slots) => slots.map((slot) => ({
        image: slot.getAdInstances()[0].getActiveCreativeRendition().getPrimaryCreativeRenditionAsset().getUrl(),
        click: slot.getAdInstances()[0].getEventCallbackUrls(
          tv.freewheel.SDK.EVENT_AD_CLICK,
          tv.freewheel.SDK.EVENT_TYPE_CLICK
        ),
        impression: slot.getAdInstances()[0].getEventCallbackUrls(
          tv.freewheel.SDK.EVENT_AD_IMPRESSION,
          tv.freewheel.SDK.EVENT_TYPE_IMPRESSION
        )
      })))
    );
  }

  static setupCappingId(medias$, slotEvents$) {
    medias$.pipe(
      map(({ markers: { pub: { capping } } }) => capping),
      filter((capping) => Boolean(capping)),
      switchMap((capping) => slotEvents$.pipe(
        filter((evt) => evt === AD_IMPRESSION),
        switchMap(() => slotEvents$.pipe(
          bufferCount(3, 1),
          filter(([evt1, evt2, evt3]) => (
            evt1 === AD_COMPLETE
            && evt2 === AD_IMPRESSION_END
            && evt3.type === AD_END
          )),
          mapTo(capping)
        ))
      ))
    ).subscribe((capping) => {
      if (!FreewheelController.getCappingId()) {
        document.cookie = `cappingId=on;path=/;max-age=${capping}`;
      }
    });
  }

  subscribeToTimeleft(currentSlot$, playerEvent$) {
    currentSlot$.pipe(
      filter(Boolean),
      switchMap((slot) => fromEvent(this.adLayer.videoObj, 'timeupdate')
        .pipe(
          map(() => (slot.getTotalDuration() - slot.getPlayheadTime()).toFixed()),
          filter((remainingTime) => remainingTime > 0),
          distinctUntilChanged()
        )),
      map((remainingTime) => ({ name: TIME_LEFT, remainingTime }))
    ).subscribe(playerEvent$);
  }

  subscribeToSlotEvents(slotEvent$) {
    slotEvent$
      .pipe(filter((evt) => evt === tv.freewheel.SDK.EVENT_AD_IMPRESSION_END))
      .subscribe(() => { this.adLayer.videoObj.src = ''; });
  }

  static formatSlotEvent() {
    /* Format these two events to fit the player subscription system */
    return map(([slotEvt, { totalDuration }]) => ([AD_START, AD_END].includes(slotEvt?.type)
      ? ({
        name: slotEvt.type,
        position: slotEvt.slot.getTimePositionClass(),
        /* Freewheel gives us the theorical ad count given by the ad server,
           and we use the computed count of what ad really started on slot end
        */
        adCountExpected: slotEvt.slot.getAdCount(),
        adCountReal: slotEvt.realAdCount,
        adDurationExpected: Math.round(totalDuration),
        adDurationReal: Math.round(slotEvt.slot.getTotalDuration())
      })
      : slotEvt));
  }

  static mapSlotEvents(adContext$, event) {
    return FreewheelController.mapFreewheelEvent(adContext$, event, VOID_FN)
      .pipe(filter((e) => e.slot.getTimePositionClass() !== tv.freewheel.SDK.TIME_POSITION_CLASS_DISPLAY));
  }

  mapRawEvents(adContext$) {
    FreewheelController.mapSlotEvents(adContext$, tv.freewheel.SDK.EVENT_SLOT_STARTED)
      .subscribe(this.slotStartedEvent$);

    FreewheelController.mapSlotEvents(adContext$, tv.freewheel.SDK.EVENT_SLOT_ENDED)
      .subscribe(this.slotEndedEvent$);

    FreewheelController.mapFreewheelEvent(adContext$, tv.freewheel.SDK.EVENT_ERROR, VOID_FN)
      .subscribe(this.errorEvent$);

    FreewheelController.mapFreewheelEvent(adContext$, tv.freewheel.SDK.EVENT_AD, VOID_FN)
      .subscribe(this.adEvent$);
  }

  static createMidrollCountDown(player, midrollManager, slots$) {
    return merge(
      midrollManager.nextCuepoint$.pipe(
        filter((nextCuepoint) => nextCuepoint === null),
        map(() => ({ name: AD_COUNTDOWN, showCountdown: false, time: 0 }))
      ),
      midrollManager.nextCuepoint$.pipe(
        filter(Boolean),
        withLatestFrom(slots$, midrollManager.currentMidrollIndex$),
        filter(([, slots, currentMidrollIndex]) => {
          const midrollSlots = slots.filter((slot) => slot.getTimePositionClass() === tv.freewheel.SDK.TIME_POSITION_CLASS_MIDROLL);
          return midrollSlots[currentMidrollIndex]?.getAdCount() > 0;
        }),
        map(([nextCuepoint]) => nextCuepoint - player.getCurrentTime()),
        filter((time) => time < 5 && time > -1),
        map((time) => ({ name: AD_COUNTDOWN, showCountdown: true, time: Math.ceil(time) })),
        distinctUntilKeyChanged('time')
      )
    );
  }

  static registerPlayerEvents(playerEvent$, adContext$) {
    const eventMap = {
      playing: tv.freewheel.SDK.VIDEO_STATE_PLAYING,
      pause: tv.freewheel.SDK.VIDEO_STATE_PAUSED,
      ended: tv.freewheel.SDK.VIDEO_STATE_COMPLETED
    };

    return playerEvent$
      .pipe(filter((evt) => Object.keys(eventMap).includes(evt)), withLatestFrom(adContext$))
      .subscribe(([evt, { adContext }]) => adContext.setVideoState(eventMap[evt]));
  }

  static mapAdContextEvents(adContext$) {
    /*
      We add every ad impression after the slot started.
      This will give us the actual number of played ad in the slot
    */
    const realAdCount$ = FreewheelController
      .mapFreewheelEvent(
        adContext$,
        tv.freewheel.SDK.EVENT_SLOT_STARTED,
        ({ type, slot }) => ({ type, slot })
      ).pipe(switchMap(() => FreewheelController.mapFreewheelEvent(
        adContext$,
        tv.freewheel.SDK.EVENT_AD,
        ({ subType }) => subType
      ).pipe(
        filter((event) => event === AD_IMPRESSION),
        scan((realAdCount) => realAdCount + 1, 0),
        startWith(0)
      )));

    return {
      slotEnded$: FreewheelController
        .mapFreewheelEvent(
          adContext$,
          tv.freewheel.SDK.EVENT_SLOT_ENDED,
          ({ type, slot }) => ({ type, slot })
        ).pipe(
          filter((e) => e.slot.getTimePositionClass() !== tv.freewheel.SDK.TIME_POSITION_CLASS_DISPLAY),
          withLatestFrom(realAdCount$),
          map(([endEvent, realAdCount]) => ({ ...endEvent, realAdCount }))
        ),

      slotStarted$: FreewheelController
        .mapFreewheelEvent(
          adContext$,
          tv.freewheel.SDK.EVENT_SLOT_STARTED,
          ({ type, slot }) => ({ type, slot })
        ).pipe(filter((e) => e.slot.getTimePositionClass() !== tv.freewheel.SDK.TIME_POSITION_CLASS_DISPLAY)),

      /**
       * Filter out mute and volume events as their behaviour is inconsistent
       * -> these events are handled in FreewheelController::commandHandler
       */
      adEvent$: FreewheelController
        .mapFreewheelEvent(adContext$, tv.freewheel.SDK.EVENT_AD, ({ subType }) => subType)
        .pipe(filter(({ subType }) => ![
          tv.freewheel.SDK.EVENT_AD_MUTE,
          tv.freewheel.SDK.EVENT_AD_UNMUTE,
          tv.freewheel.SDK.EVENT_AD_VOLUME_CHANGE
        ].includes(subType)))
    };
  }

  static getAdConfig(nbMidroll, playerConfig = {}, adConfig = {}, mediaConfig = {}) {
    return {
      network: playerConfig.freewheelNetwork || freewheelConfig.network,
      profile: playerConfig.freewheelProfile || adConfig.profile || freewheelConfig.profile,
      server: playerConfig.freewheelServer || freewheelConfig.server,
      videoAsset: playerConfig.freewheelVideoAsset || adConfig.caid,
      siteSection: playerConfig.freewheelSiteSection || adConfig.csid,
      contentDuration: playerConfig.videoDuration,
      videoAssetFallback: adConfig.afid || freewheelConfig.videoAssetFallback,
      siteSectionFallback: adConfig.sfid || freewheelConfig.siteSectionFallback,
      autostart: playerConfig.autostart,
      adRequireFocus: playerConfig.adRequireFocus,
      freewheelDebug: playerConfig.freewheelDebug,
      cuepoints: adConfig.midroll_timecode || [],
      showAd: typeof mediaConfig.showAd !== 'undefined' ? mediaConfig.showAd : playerConfig.showAd,
      midroll: typeof mediaConfig.midroll !== 'undefined' ? mediaConfig.midroll : playerConfig.midroll,
      preroll: typeof mediaConfig.preroll !== 'undefined' ? mediaConfig.preroll : playerConfig.preroll,
      gdprConsent: mediaConfig.consent?.ad ?? playerConfig.consent?.ad,
      adUserId: mediaConfig.consent?.adUserId ?? playerConfig.consent?.adUserId,
      diffusionMode: mediaConfig.diffusion?.mode ? mediaConfig.diffusion.mode : null,
      isPreview6h: adConfig.isPreview6h,
      isPreview: adConfig?.isPreview,
      nbMidroll,
      cookiesconsent: mediaConfig.consent?.freewheel ?? !!playerConfig?.consent?.freewheel,
      publicId: (mediaConfig?.publicId ?? playerConfig?.publicId) || null,
      euidConsent: mediaConfig.consent?.euid ?? playerConfig.consent?.euid,
      idlConsent: mediaConfig.consent?.idl ?? playerConfig.consent?.idl,
      pauseroll: (mediaConfig.consent?.pauseroll ?? playerConfig.pauseroll) && adConfig.pauseroll.enabled
    };
  }

  static getAutoplayType(options = {}) {
    // autostart is undefined by default
    if (tv.freewheel.SDK.PLATFORM_IS_MOBILE === true || options.autostart === false) {
      return tv.freewheel.SDK.VIDEO_ASSET_AUTO_PLAY_TYPE_NONE;
    }

    return tv.freewheel.SDK.VIDEO_ASSET_AUTO_PLAY_TYPE_ATTENDED;
  }

  static getDurationType(isLive) {
    return isLive ? tv.freewheel.SDK.VIDEO_ASSET_DURATION_TYPE_VARIABLE : tv.freewheel.SDK.VIDEO_ASSET_DURATION_TYPE_EXACT;
  }

  static mapFreewheelEvent(adContext$, eventName, selector = ({ type }) => type) {
    return adContext$.pipe(switchMap(({ adContext, adConfig }) => fromEvent(adContext, eventName)
      .pipe(
        /* pass adContext and adConfig to selector */
        map((evt) => selector(evt, adContext, adConfig)),
        share()
      )));
  }

  static handleRequestComplete(adContext, { preroll, midroll, pauseroll }) {
    return fromEvent(adContext, tv.freewheel.SDK.EVENT_REQUEST_COMPLETE)
      .pipe(switchMap((evt) => {
        if (!evt.success) return throwError(() => new Error());
        const selectedTimePositions = [
          ...(preroll ? [tv.freewheel.SDK.TIME_POSITION_CLASS_PREROLL] : []),
          ...(midroll ? [tv.freewheel.SDK.TIME_POSITION_CLASS_MIDROLL] : []),
          ...(pauseroll ? [tv.freewheel.SDK.TIME_POSITION_CLASS_PAUSE_MIDROLL] : [])
        ];

        return of(evt.target
          .getTemporalSlots()
          .filter((slot) => selectedTimePositions.includes(slot.getTimePositionClass())));
      }));
  }

  static setupAdClickStream(adClick$, adEvent$) {
    return adEvent$.pipe(
      filter((evt) => evt.subType === AD_IMPRESSION),
      switchMap(({ adInstance }) => (adInstance.getEventCallbackUrls(
        tv.freewheel.SDK.EVENT_AD_CLICK,
        tv.freewheel.SDK.EVENT_TYPE_CLICK
      ).length > 0
        ? adClick$.pipe(map(() => adInstance))
        : NEVER))
    );
  }

  static updateIsAd(adEvent$, isAd$) {
    return adEvent$
      .pipe(
        filter((evt) => evt.subType === AD_IMPRESSION),
        withLatestFrom(isAd$),
        map(([{ adInstance }, isAdObj]) => {
          const isVpaid = adInstance.getEventCallbackUrls(
            tv.freewheel.SDK.EVENT_AD_CLICK,
            tv.freewheel.SDK.EVENT_TYPE_CLICK
          ).length === 0;

          return { ...isAdObj, isVpaid };
        })
      );
  }

  static getRandomNumber() {
    return Math.round(Math.random() * 1e16);
  }

  /*
   * pvrn: a random number generated per page view.
   * It is required for forecasting system to work properly.
   * Rule of thumb: when csid changes, generate new pvrn.
   */
  static getPageViewRandom(siteSection) {
    const identifier = `fw-pvrn-${siteSection}`;

    if (sessionStorage.getItem(identifier)) return sessionStorage.getItem(identifier);

    const pvrn = FreewheelController.getRandomNumber();
    sessionStorage.setItem(identifier, pvrn);

    return pvrn;
  }

  createAdContext(adConfig, duration, width, height, platform) {
    const {
      network,
      profile,
      server,
      videoAsset,
      siteSection,
      contentDuration = duration,
      videoAssetFallback,
      siteSectionFallback,
      diffusionMode,
      cuepoints = [],
      nbMidroll,
      gdprConsent,
      adUserId,
      cookiesconsent,
      euidConsent,
      idlConsent,
      publicId,
      isLive,
      pauseroll
    } = adConfig;

    const adManager = new tv.freewheel.SDK.AdManager();
    adManager.setNetwork(network);
    adManager.setServer(server);

    const adContext = adManager.newContext();
    adContext.setProfile(profile);
    adContext.setVideoAsset(
      videoAsset,
      contentDuration,
      null, // networkId
      null, // location
      FreewheelController.getAutoplayType(adConfig),
      FreewheelController.getRandomNumber(), // vprn: a random number generated per video instance
      null, // idType
      videoAssetFallback,
      FreewheelController.getDurationType(isLive)
    );
    adContext.setSiteSection(
      siteSection,
      null, // networkId
      FreewheelController.getPageViewRandom(siteSection),
      null, // idType
      siteSectionFallback
    );

    adContext.setCapability('amcb', 1); // For server side capping

    if (isLive) {
      adContext.setRequestMode(tv.freewheel.SDK.REQUEST_MODE_LIVE);
      adContext.setRequestDuration(72);
      adContext.addTemporalSlot('preroll', tv.freewheel.SDK.ADUNIT_PREROLL, null, null, null, 72, 72);
    } else {
      adContext.setRequestMode(tv.freewheel.SDK.REQUEST_MODE_ON_DEMAND);
    }

    if (nbMidroll > 0 && cuepoints.length > 0) {
      adContext.addTemporalSlot('preroll', tv.freewheel.SDK.ADUNIT_PREROLL, 0);

      for (let i = 1; i <= nbMidroll; i += 1) {
        adContext.addTemporalSlot(`midroll${String.fromCharCode(64 + i)}`, tv.freewheel.SDK.ADUNIT_MIDROLL, i * 10, null, i);
      }
    }

    if (pauseroll) {
      adContext.addTemporalSlot('pause', tv.freewheel.SDK.ADUNIT_PAUSE_MIDROLL, 10);
    }

    adContext.addKeyValue('cookiesconsent', `${cookiesconsent}`);

    if (adUserId) {
      adContext.addKeyValue('_fw_vcid2', `${adUserId}`);
    } else if (cookiesconsent && publicId) {
      adContext.addKeyValue('_fw_vcid2', `${publicId}`);
    }

    adContext.addKeyValue('_fw_player_height', `${height}`);
    adContext.addKeyValue('_fw_player_width', `${width}`);

    if (diffusionMode === 'tunnel') {
      // Freewheel does not take into account boolean value
      adContext.addKeyValue('tunnelvideo', 'true');
    }
    if (adConfig.isPreview) adContext.addKeyValue('videotype', 'preview');
    if (adConfig.isPreview6h) adContext.addKeyValue('videotype', 'preview6h');
    adContext.registerVideoDisplayBase(this.adLayer.getVideoDisplayBaseId());
    adContext.setParameter(
      tv.freewheel.SDK.PARAMETER_RENDERER_VIDEO_DISPLAY_CONTROLS_WHEN_PAUSE,
      false,
      tv.freewheel.SDK.PARAMETER_LEVEL_GLOBAL
    );

    /* disable FW default click handler */
    adContext.setParameter(
      tv.freewheel.SDK.PARAMETER_RENDERER_VIDEO_CLICK_DETECTION,
      false,
      tv.freewheel.SDK.PARAMETER_LEVEL_GLOBAL
    );

    adContext.addKeyValue('_fw_gdpr', '1');
    adContext.addKeyValue('_fw_gdpr_consent', `${gdprConsent || ''}`);
    if (publicId && cookiesconsent) {
      const fw3pUID = [];

      if (euidConsent) {
        fw3pUID.push(`euid:${euidConsent}`);
      }

      if (idlConsent) {
        fw3pUID.push(`idl:${idlConsent}`);
      }

      if (fw3pUID.length) {
        adContext.addKeyValue('_fw_3p_UID', fw3pUID.join());
      }
    }

    const myCompanionSlot = {
      companionSlot: 'ptgt=p&prct=text%2Fhtml_doc_lit_mobile%2Ctext%2Fhtml_doc_ref%2Ctext%2Fhtml_lit_nowrapper%2Ctext%2Fhtml_no_iframe&w=1920&h=650&flag=%2Bcmpn;'
    };

    adContext._adRequest._slotScanner._slots = myCompanionSlot;

    if (TV_PLATFORMS.includes(platform)) {
      adContext.addKeyValue('_fw_h_user_agent', 'Mozilla/5.0 (X11; Linux aarch64;STB) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/16.4 Safari/605.1.15 WPE/2.40.5 SOPOpenBrowser/2.40.5 Model/WHD95 Firmware/01.12.06');
    }

    return ({
      adContext,
      adConfig
    });
  }

  static getCappingId() {
    return /cappingId=([^;]+)/g.exec(document.cookie)?.[1] ?? '';
  }

  static mapCommandToSlot(currentSlot$, commands$) {
    return commands$.pipe(
      withLatestFrom(currentSlot$),
      map(([{ type, value }, slot]) => ({ type, slot, value }))
    );
  }

  commandHandler(type, currentSlot, value) {
    switch (type) {
      case PAUSE:
        currentSlot.pause();
        break;
      case PLAY:
        currentSlot.resume();
        break;
      case MUTE:
        // Freewheel SDK sets the volume to 0 before muting and does not restore it on unmute
        if (value) this.previousVolume = this.adLayer.videoObj.volume;
        if (!value) this.adLayer.videoObj.volume = this.previousVolume || 0.5;
        this.adLayer.videoObj.muted = value;
        this.slotEvents$.next(value ? tv.freewheel.SDK.EVENT_AD_MUTE : tv.freewheel.SDK.EVENT_AD_UNMUTE);
        break;
      case VOLUME:
        /**
         * Update the previous volume save to avoid the second ad volume being the first ad volume
         * when the first ad was muted and the first video volume was updated.
         * Needed when MUTE(false) is triggered at each video start
         */
        this.previousVolume = value;
        this.adLayer.videoObj.volume = value;
        this.slotEvents$.next(tv.freewheel.SDK.EVENT_AD_VOLUME_CHANGE);
        break;
      default:
        break;
    }
  }

  handleSlotStart({ slot, position, willProbablyPlayAnAd }) {
    this.isAd$.next({ isAd: true, position, willProbablyPlayAnAd });
    this.currentSlot$.next(slot);
    if (willProbablyPlayAnAd) {
      this.adLayer.showUI(this.currenAdContext?.adContext, this.withTagUnique);
    }
    setTimeout(() => { slot.play(); }, 0);

    return merge(
      this.errorEvent$.pipe(mapTo({ resolved: false })),
      this.slotEndedEvent$.pipe(mapTo({ resolved: true }))
    );
  }

  handleSlotCancel({ slot, index, position }) {
    if (!slot) { return this.handleSlotResolve(index); }

    setTimeout(() => { slot.play(); slot.stop(); }, 50);
    return this.slotEndedEvent$.pipe(take(1), switchMap(() => this.handleSlotResolve({ index, position })));
  }

  handleSlotResolve({ index, position, willProbablyPlayAnAd } = {}) {
    if (position === tv?.freewheel.SDK.TIME_POSITION_CLASS_MIDROLL) this.midrollManager.currentMidrollIndex$.next(index + 1);
    if (willProbablyPlayAnAd) {
      this.adLayer.hideUI(this.withTagUnique);
    }
    this.isAd$.next({ isAd: false, position });
    return of(null);
  }

  handleAdClick() {
    this.adClick$.next();
  }
}
