import { h } from 'preact';
import { useState, useEffect, useRef } from 'preact/hooks';
import { connect, withBreakPoints } from '../../../hoc';
import Button from '../../common/buttons/Button';
import EmbedScreen from './EmbedScreen';
import { usePlayerContext, useStyles } from '../../../hooks';
import {
  EMBED_DEFAULT_DIALOG_STYLE,
  EMBED_DIALOG_CONTENT_OPENED_STYLE,
  EMBED_DIALOG_CONTENT_STYLE
} from './styles';
import { useDialogContext, useOpenDialogsCounter, useRegisterDialog } from '../../../context/DialogContext';
import { Modal } from '../../common/modal';
import { EMBED_CLOSED, USER_CLICK } from '../../../../types';
import { MODAL_BASIC_STYLE, MODAL_CLOSING_BUTTON_STYLE } from '../../common/modal/types';
import { isValidateKeyboard } from '../../../utils/keyboard';
import { BUTTON_SQUARE_SIZE } from '../../../styles';

const style = {
  container: EMBED_DEFAULT_DIALOG_STYLE,
  content: EMBED_DIALOG_CONTENT_STYLE,
  openedContent: EMBED_DIALOG_CONTENT_OPENED_STYLE
};

function EmbedButton({
  isAd,
  embed,
  waitStart,
  small,
  extraSmall,
  embedError,
  message
}) {
  const player = usePlayerContext();
  const [copied, setCopied] = useState(false);
  const [opened, setOpened] = useState(false);
  const [isOutsideModal, setIsOutsideModal] = useState(null);
  const closeIconRef = useRef(null);
  const iconRef = useRef(null);
  const dialogCtx = useDialogContext();
  const setStyles = useStyles();
  const handleError = () => {
    if (embedError) {
      dialogCtx.closeAll();
      player.warn(message);
    }
  };

  const closingButtonSize = setStyles({
    extraSmall: { width: 16 },
    'small+medium+large+extraLarge': { width: 24 }
  });
  useRegisterDialog({ id: 'embed', openingMode: 'click', state: [opened, setOpened] });
  useOpenDialogsCounter(opened);

  useEffect(() => {
    if (opened && closeIconRef.current) {
      setIsOutsideModal(false);
      closeIconRef.current.focus();
    }

    if (opened && embedError) {
      handleError();
    } else {
      setCopied(false);
    }

    if (!opened && iconRef.current) {
      if (!isOutsideModal) iconRef.current.focus();
    }
  }, [embedError, iconRef.current, opened, isOutsideModal]);

  return (
    opened
      ? (
        <Modal
          closeComponent={(
            <Button
              type="icon"
              openingMode="click"
              label="fermer"
              ariaLabel="fermer"
              forceLabel
              disableFocus={false}
              zone="top"
              onClick={() => {
                player.userEvents$.next({
                  action: EMBED_CLOSED,
                  source: USER_CLICK
                });
                setOpened(false);
              }}
              onKeyDown={(e) => {
                player.userEvents$.next({
                  action: EMBED_CLOSED,
                  source: USER_CLICK
                });
                if (isValidateKeyboard(e)) {
                  setOpened(false);
                }
              }}
              size={closingButtonSize.width}
              icon="close"
              xs
              iconRef={closeIconRef}
              name="btn-close-embed"
              ariaHidden={false}
            />
      )}
          show={opened}
          attachedTo="btn-embed"
          styles={MODAL_BASIC_STYLE}
          onChange={(openedState) => {
            setIsOutsideModal(true);
            setOpened(openedState);
          }}
          closingButtonStyle={setStyles(MODAL_CLOSING_BUTTON_STYLE)}
        >

          <EmbedScreen onCopyFail={() => { setOpened(false); }} copyEmbedIframe={() => setCopied(true)} copied={copied} />

        </Modal>
      )
      : (
        <Button
          hidden={isAd || waitStart || extraSmall || !embed}
          type="icon"
          label="intégrer"
          ariaLabel="intégrer"
          forceLabel
          zone="top"
          name="btn-embed"
          openingMode="click"
          icon="embed"
          style={style}
          size={(setStyles(BUTTON_SQUARE_SIZE)).width}
          xs={(small || extraSmall)}
          onClick={() => setOpened(true)}
          onHover={() => player.requestEmbed()}
          iconRef={iconRef}
        />
      )
  );
}

const selector = ({
  media: {
    embed,
    isAd,
    embedError,
    message
  },
  ui: { waitStart }
}) => ({
  embed, isAd, waitStart, embedError, message
});
export default withBreakPoints(connect(selector)(EmbedButton));
