import { merge, of, timer, NEVER, fromEvent, combineLatest } from 'rxjs';
import {
  map,
  filter,
  withLatestFrom,
  take,
  debounceTime,
  mapTo,
  switchMap,
  share,
  skip,
  distinctUntilChanged,
  delay,
  startWith,
  combineLatestWith,
  scan
} from 'rxjs/operators';
import { createStore } from './binding';

import {
  PLAYBACK_CANPLAY,
  PLAYBACK_PLAY,
  PLAYBACK_PLAYING,
  PLAYBACK_PAUSE,
  PLAYBACK_END,
  PLAYBACK_WAITING,
  PLAYBACK_TIMEUPDATE,
  PLAYBACK_LOADSTART,
  PLAYBACK_DURATIONCHANGE,
  PLAYBACK_BITRATECHANGE,
  PLAYBACK_AVAILABLE_QUALITIES,
  PLAYBACK_SELECTED_QUALITY,
  PLAYBACK_CURRENT_QUALITY,
  VOLUME_CHANGE,
  VOLUME_MUTE,
  PLAYBACK_SEEKED,
  PLAYBACK_STOPPED,
  PLAYBACK_EMPTIED,
  WAITING_CHANGED,
  IS_INTRO_CHANGED,
  PLAYBACK_BUFFER,
  MEDIA_CHANGED,
  FULLSCREEN_CHANGED,
  AUDIO_AVAILABLE,
  AUDIO_SWITCHED,
  SUBTITLE_AVAILABLE,
  SUBTITLE_SWITCHED,
  SIZE_CHANGED,
  AD_STATUS_CHANGE,
  AD_PAUSE,
  AD_RESUME,
  AD_INITIATED,
  AD_TIME_LEFT,
  AD_COUNTDOWN,
  AD_VOLUME_CHANGE,
  AD_MUTE,
  AD_UNMUTE,
  UI_SEEKING,
  AUTO_START_CHANGE,
  NEXT_MEDIA,
  UPDATE_MEDIA,
  ON_PLAY_TRIGGERED,
  PLAYER_ERROR,
  PLAYER_ERROR_RESET,
  PLAYER_WARNING,
  PLAYER_WARNING_CLOSE,
  UI_CONFIG,
  PIP_ENTER,
  PIP_LEAVE,
  RENDERER_READY,
  RENDERER_DISPOSE,
  EMBED_KEY,
  EMBED_ERROR,
  ZAPPING_CHANGED,
  ZAPPING_INIT,
  FEEDBACK_CHANGED,
  UI_CAN_INTERACT,
  PLAYBACK_RATECHANGE,
  ON_VIEWERS_UPDATED,
  ON_VIEWERS_DISPLAYED,
  AD_IMPRESSION,
  TIMESHIFTING_CAN_DISPLAY_START_OVER_BUTTON,
  TIMESHIFTING_START_OVER_TIMESHIFTING,
  UPDATE_PROGRAM_MARKERS,
  UI_ACCESSIBILITY_VISIBILITY,
  LABEL_LIVE_CHANGED,
  LABEL_TIMELINE_CHANGED,
  LABEL_PUB_CHANGED,
  UI_TRACKS_MENU_SHOW,
  UI_TRACKS_MENU_HIDE,
  PLAYBACK_CURRENT_QUALITY_CHANGES,
  MEDIA_RESTARTED,
  RECOMMENDATIONS_CHANGED,
  RECOMMENDATIONS_DISPLAY_CHANGED,
  RECOMMENDATIONS_HIDE_VIDEO,
  HIGHLIGHTS_CHANGED,
  ORIENTATION_CHANGED,
  PAUSE_ROLL_IS_DISPLAYABLE,
  PAUSE_ROLL_CONTENT,
  TOGGLE_SHOW_PANNEL_LIVE_OPTION,
  UI_IS_INTERACTING
} from './types';

import { WARNING_TIMER } from '../error/types';

import { TIME_LEFT } from '../ad/freewheel/types';
import { RENDERER_CREATED, RENDERER_LIVE_STREAM_DETECTED } from '../core/renderer/types';
import { INTERRUPTION, FIRST_PLAY, PLAY } from '../core/command/types';
import { BACKGROUND, BROADCASTING_TYPE_AUDIO, NEW_VIDEO, USER_CLICK, USER_PANEL_LIVE_OPTIONS_CLOSE, VIDEO_START } from '../types';
import { bufferConsecutiveEvents } from '../utils/rx-utils';
import { INTERRUPTION_CANCELED, INTERRUPTION_END } from '../core/interruptor/types';
import { DAI_START, DAI_END } from '../ad/dai/types';
import { mapTracks } from '../utils/tracks';
import { DEFAULT_SETTINGS } from '../settings/types';
import { mapQualities } from '../utils/quality';
import { asPercent, removeDuplication } from '../utils';
import { TIMESHIFTING_AUTO_DURATION } from '../core/timeshifting/types';
import { TV_PLATFORMS } from '../core/dom/types';
import { BACK_BTN_SHORTCUTS } from '../core/shortcut/types';
import { CHAT_NAME, EMPTY_ACTIVE_NAME } from '../ui/components/wrappers/Zap/constants';

const SHORTCUT_TRUE_DELAY = 5;
const SHORTCUT_FALSE_DELAY = 640;

export function handleRenderer(store, rendererController, playerConfig$) {
  rendererController.duration$
    .subscribe((duration) => {
      store.dispatch({ type: PLAYBACK_DURATIONCHANGE, payload: { duration } });
    });

  rendererController.currentTime$
    .subscribe((currentTime) => {
      store.dispatch({ type: PLAYBACK_TIMEUPDATE, payload: { currentTime } });
    });

  rendererController.muted$
    .subscribe((muted) => {
      store.dispatch({ type: VOLUME_MUTE, payload: { muted } });
    });

  rendererController.volume$
    .subscribe((level) => {
      store.dispatch({ type: VOLUME_CHANGE, payload: { level } });
    });

  rendererController.buffered$
    .subscribe((buffered) => {
      store.dispatch({ type: PLAYBACK_BUFFER, payload: { buffered } });
    });

  rendererController.bitrate$
    .subscribe((bitrate) => {
      store.dispatch({ type: PLAYBACK_BITRATECHANGE, payload: { bitrate } });
    });

  rendererController.state$
    .pipe(filter((evt) => evt === RENDERER_LIVE_STREAM_DETECTED))
    .subscribe(() => store.dispatch({
      type: UPDATE_MEDIA,
      payload: { isLive: true }
    }));

  rendererController.state$
    .pipe(
      withLatestFrom(playerConfig$),
      filter(([evt, config]) => [
        ...[RENDERER_CREATED, RENDERER_READY, RENDERER_DISPOSE],
        ...(config.showAd && TV_PLATFORMS.includes(config.platform) ? [RENDERER_CREATED] : [])
      ].includes(evt)),
      map(([evt]) => evt)
    )
    .subscribe((type) => store.dispatch({ type }));

  rendererController.playbackRate$
    .subscribe((rate) => store.dispatch({ type: PLAYBACK_RATECHANGE, payload: { rate } }));
}

export function handleMediaRestart(store, { medias$, events$, commands$ }) {
  // Force currentTime to 0 when the same media is replayed
  const restart$ = medias$.pipe(switchMap(() => events$.pipe(
    filter((e) => e === PLAYBACK_END),
    switchMap(() => commands$.pipe(filter(({ type }) => type === PLAY), take(1)))
  )));

  restart$.subscribe(() => store.dispatch({ type: PLAYBACK_TIMEUPDATE, payload: { currentTime: 0 } }));
}

export function getBackgroundImage({ meta, config }) {
  return (config?.broadcastingType === BROADCASTING_TYPE_AUDIO)
    ? (meta?.images?.vignette_16x9 || BACKGROUND)
    : (config.image || meta.image_url || BACKGROUND);
}

export function handleMedia(store, { mediaController, currentVideo$ }) {
  currentVideo$.subscribe(() => store.dispatch({
    type: NEXT_MEDIA,
    payload: {
      loaded: false
    }
  }));
  mediaController.medias$
    .subscribe(({
      config, isLive, isDAI, meta, video, markers
    }) => {
      const { ui = {} } = store.getState();
      const { isFullscreen = false } = ui;
      store.dispatch({ type: PLAYBACK_RATECHANGE, payload: { rate: 1 } });
      store.dispatch({ type: PLAYBACK_TIMEUPDATE, payload: { currentTime: config.startTimecode ?? 0 } });
      store.dispatch({
        type: MEDIA_CHANGED,
        payload: {
          isLive,
          isDAI,
          isStartOverEnabled: video.is_startover_enabled,
          isDVR: video.is_DVR,
          comingNext: config.comingNext || {},
          id: meta.id,
          next: config.next,
          title: config.program || meta.title || '',
          embed: video.embed || false,
          preTitle: config.preTitle || meta.pre_title || '',
          additionalTitle: config.title || meta.additional_title || '',
          logo: config.logo || meta.logo || '',
          backgroundImage: getBackgroundImage({ meta, config }),
          resource: video.url,
          spritesheets: video.spritesheets || [],
          loaded: true,
          timeshifting: {
            type: Boolean(video?.timeshiftable) && isLive ? video.timeshiftable : null,
            startOverTimeshifting: false,
            isAbleToStartOver: false
          },
          broadcastedAt: meta.broadcasted_at || '',
          name: config?.name || '',
          skipIntro: {
            duration: video?.skip_intro?.duration ?? null,
            timeBeforeDismiss: video?.skip_intro?.time_before_dismiss ?? null,
            timecode: video?.skip_intro?.timecode ?? null
          },
          skipRecap: {
            duration: video?.previously?.duration ?? null,
            timeBeforeDismiss: video?.previously?.time_before_dismiss ?? null,
            timecode: video?.previously?.timecode ?? null
          },
          isHighlightable: video.is_highlightable,
          markers,
          isFullscreen,
          isTv: TV_PLATFORMS.includes(config.platform),
          isAudio: Boolean(config?.broadcastingType === BROADCASTING_TYPE_AUDIO),
          chat: config.chat
        }
      });
    });

  mediaController.concurrentViewers$
    .subscribe(({ numberViewers, minViewers }) => {
      store.dispatch({
        type: ON_VIEWERS_UPDATED,
        payload: { numberViewers }
      });

      if (minViewers <= numberViewers) {
        store.dispatch({
          type: ON_VIEWERS_DISPLAYED,
          payload: { displayedViewers: true }
        });
      }
    });
}

export function handleShortcuts(store, shortcutController) {
  shortcutController.shortcuts$
    .pipe(switchMap((keyCode) => merge(
      of(false),
      of(true).pipe(delay(SHORTCUT_TRUE_DELAY)),
      of(false).pipe(delay(SHORTCUT_FALSE_DELAY))
    ).pipe(map((feedback) => ({ feedback, keyCode })))))
    .subscribe(({ feedback, keyCode }) => {
      store.dispatch({
        type: FEEDBACK_CHANGED,
        payload: { keyCode, feedback }
      });
    });
}

export function handleEvents(store, { events$, playerConfig$, commands$ }) {
  const eventMapping = {
    [PLAYBACK_LOADSTART]: 'PLAY',
    [PLAYBACK_CANPLAY]: 'PLAY',
    [PLAYBACK_PAUSE]: 'PLAY',
    [PLAYBACK_STOPPED]: 'PLAY',
    [PLAYBACK_PLAY]: 'PAUSE',
    [PLAYBACK_PLAYING]: 'PAUSE',
    [PLAYBACK_END]: 'REPLAY',
    [PLAYBACK_WAITING]: 'PLAY',
    [PLAYBACK_EMPTIED]: 'PLAY'
  };

  /**
   * in order to hide play button and avoid flickering when autostart: true
   * we take only the first one
   */
  const loadstart$ = events$.pipe(
    filter((event) => [PLAYBACK_LOADSTART, PLAYBACK_CANPLAY].includes(event)),
    take(1),
    withLatestFrom(playerConfig$),
    filter(([, { autostart }]) => !autostart),
    map(([event]) => event)
  );

  const event$ = events$.pipe(filter((event) => Object.keys(eventMapping).includes(event)
    && ![PLAYBACK_LOADSTART, PLAYBACK_CANPLAY].includes(event)));

  merge(loadstart$, event$)
    .pipe(map((event) => ({ event, button: eventMapping[event] })))
    .subscribe(({ event, button }) => store.dispatch({ type: event, payload: { button } }));

  commands$
    .pipe(filter(({ type, userGesture }) => type === FIRST_PLAY && userGesture))
    .subscribe(() => store.dispatch({ type: ON_PLAY_TRIGGERED }));

  /* proxy events to store actions */
  events$.pipe(filter((event) => [PIP_ENTER, PIP_LEAVE, NEW_VIDEO].includes(event)))
    .subscribe((type) => store.dispatch({ type }));
}

export function handleDom(store, domController) {
  domController.dimensions$
    .subscribe((payload) => store.dispatch({ type: SIZE_CHANGED, payload }));
}

export function handleFullscreen(store, { fullscreenController }) {
  fullscreenController.fullscreenChange$.subscribe((isFullscreen) => {
    store.dispatch({ type: FULLSCREEN_CHANGED, payload: { isFullscreen } });
  });
}

export function handlePanel(store, { fullscreenController, userEvents$, freewheelController, pauserollController }) {
  fullscreenController.fullscreenChange$.subscribe((isFullscreen) => {
    if (!isFullscreen && store.getState().ui.panelLiveOption.currentTab === CHAT_NAME) {
      store.dispatch({
        type: TOGGLE_SHOW_PANNEL_LIVE_OPTION,
        payload: {
          show: false,
          currentTab: ''
        }
      });

      userEvents$.next({
        action: USER_PANEL_LIVE_OPTIONS_CLOSE,
        value: false,
        source: USER_CLICK
      });
    }
  });

  freewheelController.isAd$.pipe(
    filter(({ isAd, position }) => isAd && (position === 'PREROLL' || position === 'MIDROLL'))
  ).subscribe(() => {
    store.dispatch({
      type: TOGGLE_SHOW_PANNEL_LIVE_OPTION,
      payload: {
        show: false,
        currentTab: ''
      }
    });
    userEvents$.next({
      action: USER_PANEL_LIVE_OPTIONS_CLOSE,
      value: false,
      source: USER_CLICK
    });
  });

  pauserollController?.stream$.pipe(filter(({ payload: { isDisplayable } }) => isDisplayable)).subscribe(() => {
    store.dispatch({
      type: TOGGLE_SHOW_PANNEL_LIVE_OPTION,
      payload: {
        show: false,
        currentTab: ''
      }
    });
    userEvents$.next({
      action: USER_PANEL_LIVE_OPTIONS_CLOSE,
      value: false,
      source: USER_CLICK
    });
  });
}

export function handleAudioTracks(store, audioTracksController) {
  audioTracksController.availableTracks$
    .pipe(filter((x) => Boolean(x.length)), mapTracks())
    .subscribe((audiosAvailable) => store.dispatch({
      type: AUDIO_AVAILABLE,
      payload: {
        audiosAvailable,
        audioSelected: (
          audiosAvailable.find(
            (track) => track.default
                || track.language === DEFAULT_SETTINGS.languages.audio
          ) || audiosAvailable[0]
        ).index
      }
    }));

  audioTracksController.activeTrack$
    .subscribe((index) => store.dispatch({
      type: AUDIO_SWITCHED,
      payload: { audioSelected: index }
    }));
}

export function handleTextTracks(store, textTracksController) {
  textTracksController.availableTracks$.pipe(mapTracks())
    .subscribe((subtitlesAvailable) => {
      store.dispatch({
        type: SUBTITLE_AVAILABLE,
        payload: {
          subtitlesAvailable,
          subtitleSelected: (
            subtitlesAvailable.find(
              (track) => track.default
                  || track.language === DEFAULT_SETTINGS.languages.subtitles
            ) || { index: -1 }
          ).index
        }
      });
    });

  textTracksController.activeTrack$
    .subscribe((index) => {
      store.dispatch({
        type: SUBTITLE_SWITCHED,
        payload: { subtitleSelected: index }
      });
    });
}

export function handleAd(store, { events$, cmds$, freewheelController }) {
  /**
   * - block interactions as soon as an interruption is validated
   * - for preroll: canInteract will be resolved on end of intro animation
   * - for midroll: canInteract will be resolved on isAd === false
   */
  cmds$
    .pipe(filter(({ type }) => type === INTERRUPTION), startWith(false))
    .subscribe(() => store.dispatch({
      type: UI_CAN_INTERACT,
      payload: { canInteract: false }
    }));

  freewheelController.isAd$
    .pipe(distinctUntilChanged((a, b) => a.isAd === b.isAd && a.isVpaid === b.isVpaid))
    .subscribe((payload) => store.dispatch({ type: AD_STATUS_CHANGE, payload }));

  freewheelController.adCountdown$
    .subscribe((payload) => store.dispatch({ type: AD_COUNTDOWN, payload }));

  events$
    .pipe(filter((evt) => evt === AD_VOLUME_CHANGE))
    .subscribe(() => store.dispatch({
      type: AD_VOLUME_CHANGE,
      payload: { level: freewheelController.adLayer.videoObj.volume }
    }));

  events$
    .pipe(filter((evt) => [AD_MUTE, AD_UNMUTE].includes(evt)))
    .subscribe((evt) => store.dispatch({
      type: VOLUME_MUTE,
      payload: { muted: evt === AD_MUTE }
    }));

  events$
    .pipe(filter((evt) => evt.name === TIME_LEFT))
    .subscribe(({ remainingTime }) => store.dispatch({ type: AD_TIME_LEFT, payload: { remainingTime } }));

  /* debounce to prevent flickering during ad transition emitting pause / play */
  events$
    .pipe(
      filter((event) => event === AD_PAUSE || event === AD_RESUME || event === AD_INITIATED),
      debounceTime(50)
    )
    .subscribe((event) => {
      if (event === AD_PAUSE) {
        store.dispatch({ type: PLAYBACK_PAUSE, payload: { button: 'PLAY' } });
      } else {
        store.dispatch({ type: PLAYBACK_PLAYING, payload: { button: 'PAUSE' } });
      }
    });
}

export function handlePauseRoll(
  store,
  {
    pauserollController: { stream$, pauserolls$ }
  }
) {
  stream$
    .pipe(map(({ payload: { isDisplayable } }) => isDisplayable))
    .subscribe((pauseRollIsDisplayable) => {
      store.dispatch({
        type: PAUSE_ROLL_IS_DISPLAYABLE,
        payload: { pauseRollIsDisplayable }
      });
    });

  pauserolls$
    .pipe(filter((p) => p.length))
    .subscribe(([pauseRollContent]) => {
      store.dispatch({
        type: PAUSE_ROLL_CONTENT,
        payload: { pauseRollContent }
      });
    });
}

export function handleDAI(store, daiController, events$) {
  daiController.isAd$
    .pipe(distinctUntilChanged((a, b) => a.isAd === b.isAd))
    .subscribe((payload) => {
      store.dispatch({ type: AD_STATUS_CHANGE, payload });
      events$.next(payload.isAd ? DAI_START : DAI_END);
    });

  daiController.adCountdown$
    .subscribe((payload) => store.dispatch({ type: AD_COUNTDOWN, payload }));
}

export function handleLoader(store, { events$, playerConfig$, commands$, interruptions$, medias$ }) {
  const mapEventToBool = (event, val) => events$.pipe(
    filter((evt) => evt === event),
    map(() => val),
    share()
  );

  const newVideo$ = mapEventToBool(NEW_VIDEO, false);
  const videoStart$ = mapEventToBool(VIDEO_START, true);
  const timeupdate$ = mapEventToBool(PLAYBACK_TIMEUPDATE, false);
  const videoWaiting$ = mapEventToBool(PLAYBACK_WAITING, true);
  const adWaiting$ = merge(
    mapEventToBool(AD_IMPRESSION, false), /* ad is playing -> resolve waiting state */
    commands$.pipe(filter(({ type }) => type === INTERRUPTION), mapTo(true)), /* interrruption is validated = waiting */
    interruptions$.pipe(/* resolve waiting if interruption canceled or ended */
      filter(({ status }) => [INTERRUPTION_CANCELED, INTERRUPTION_END].includes(status)),
      /**
       * We need to delay here because INTERRUPTION_CANCELED, INTERRUPTION_END are mapped
       * to INTIATED_PLAY in command controller (note that INTIATED_PLAY are mapped to waiting=true)
       * where here we want waiting set to false
       */
      delay(0),
      mapTo(false)
    )
  );

  /* buffer 2 consecutive timeupdates after a playing event to be sure video is playing */
  const videoPlaying$ = events$.pipe(
    filter((evt) => evt === PLAYBACK_PLAYING),
    switchMap(() => events$.pipe(
      filter((evt) => evt !== 'progress'), /* get rid of progress events */
      bufferConsecutiveEvents({ count: 2, event: PLAYBACK_TIMEUPDATE }),
      map(() => false)
    ))
  );

  /**
   * WAITING STATE STREAM
   * on each new media, check config:
   * -> autostart === false : map to false, player is awaiting user action,
   * Once the video has actually started (VIDEO_START):
   * -> force initial waiting state for intro animation
   * -> use waiting and playing events to toggle waiting state
   */
  const waiting$ = merge(
    medias$.pipe(
      withLatestFrom(playerConfig$),
      switchMap(([, { autostart }]) => (autostart ? NEVER : of(false)))
    ),
    adWaiting$,
    videoStart$.pipe(
      switchMap(() => merge(
        of(true),
        videoWaiting$,
        videoPlaying$,
        /* on IOS, inconsistent 'playing' event not triggered after 'waiting'
          -> use second 'timeupdate' to resolve waiting state
          (first timeupdate is sometimes triggered while still waiting) */
        videoWaiting$.pipe(switchMap(() => timeupdate$.pipe(skip(1))))
      )),
      distinctUntilChanged()
    )
  );

  waiting$.subscribe((waiting) => {
    events$.next({ name: WAITING_CHANGED, payload: { waiting } });
    store.dispatch({ type: WAITING_CHANGED, payload: { waiting } });
  });

  merge(videoStart$, newVideo$)
    .pipe(
      withLatestFrom(playerConfig$, (isIntro, { intro = false } = {}) => isIntro && intro)
    )
    .subscribe((isIntro) => {
      events$.next({ name: IS_INTRO_CHANGED, payload: { isIntro } });
      store.dispatch({ type: IS_INTRO_CHANGED, payload: { isIntro } });
    });
}

export function handleUI(store, { events$, medias$, playerConfig$ }) {
  medias$
    .pipe(
      withLatestFrom(playerConfig$),
      map(([{ isLive, isDAI }, config]) => ({ ...config, isLive, isDAI }))
    )
    .subscribe(({
      showTitle, forceShowTitle, originUrl, origin, showAd, pip, isLive, showViewers, isDAI,
      comingNext: {
        showOnStart: displayComingNextOnStart
      }
    }) => {
      store.dispatch({
        type: UI_CONFIG,
        payload: {
          showTitle, forceShowTitle, originUrl, origin, showAd, pip, isLive, showViewers, displayComingNextOnStart, isDAI
        }
      });
    });

  events$.pipe(filter((evt) => [PLAYBACK_SEEKED, PLAYBACK_END].includes(evt)))
    .subscribe(() => {
      store.dispatch({
        type: UI_SEEKING,
        payload: { isSeeking: false }
      });
    });
}

export function handleAutostart(store, { playerConfig$ }) {
  playerConfig$.subscribe(({ autostart }) => store.dispatch({
    type: AUTO_START_CHANGE,
    payload: { autostart }
  }));
}

export function handleErrors(store, { errors$, events$ }) {
  errors$.pipe(filter(({ error: { fatal } }) => fatal)).subscribe(({ error }) => {
    store.dispatch({
      type: PLAYER_ERROR,
      payload: { error }
    });
  });

  /* Remove error overlay if a video is ready to play */
  events$.pipe(filter((event) => [PLAYBACK_CANPLAY, PLAYBACK_PLAY, PLAYBACK_PLAYING, NEW_VIDEO].includes(event)))
    .subscribe(() => {
      store.dispatch({ type: PLAYER_ERROR_RESET });
    });
}

export function handleEmbed(store, { embedKey$, errors$ }) {
  embedKey$.subscribe((embedKey) => {
    store.dispatch({
      type: EMBED_KEY,
      payload: { embedKey }
    });
  });
  errors$.subscribe(({ error }) => {
    store.dispatch({
      type: EMBED_ERROR,
      payload: { embedError: true, message: error.message }
    });
  });
}

export function handleWarnings(store, { warning$ }) {
  const closeWarnings$ = warning$.pipe(switchMap(() => timer(WARNING_TIMER).pipe(take(1))));
  warning$.subscribe((message) => store.dispatch({ type: PLAYER_WARNING, payload: { message } }));
  closeWarnings$.subscribe(() => store.dispatch({ type: PLAYER_WARNING_CLOSE }));
}

export function handleZapping(store, { medias$, metadata$ }) {
  const formatZapping = (zapping) => (zapping?.[0]?.title && zapping?.[0]?.channels ? zapping : [
    {
      title: 'En direct sur nos chaînes',
      channels: zapping,
      priority: 1
    }
  ])
    .map((list) => ({
      ...list,
      channels: removeDuplication(list.channels, 'id'),
      fluxType: list.priority > 1 ? 'event' : 'antenne'
    }))
    .sort((a, b) => b.priority - a.priority);

  metadata$
    .pipe(filter((zapping) => zapping.length))
    .subscribe((zapping) => store.dispatch({
      type: ZAPPING_INIT,
      payload: { list: formatZapping(zapping) }
    }));

  medias$
    .pipe(
      scan((_, m) => m),
      map(({ config: { zapping = [] }, meta, video }, index) => {
        let activeChannel = -1;
        const formated = formatZapping(zapping);
        const tabIndex = formated.findIndex(({ channels }) => {
          activeChannel = channels.findIndex(({ id }) => (id === meta.id || (id === meta.live_id && video.is_DVR)));
          return activeChannel >= 0;
        });

        const currentTabIndex = tabIndex === -1 ? 0 : tabIndex;
        if (index !== 0) {
          // We need to do this prevent active tab reseted when zapping from tab
          return ({
            ...store.getState().zapping.current,
            activeChannelId: formated[currentTabIndex].channels[activeChannel]?.id || ''
          });
        }
        return ({ tabIndex: currentTabIndex, activeChannel, activeChannelId: formated[currentTabIndex].channels[activeChannel]?.id || '' });
      })
    )
    .subscribe((current) => store.dispatch({ type: ZAPPING_CHANGED, payload: { current } }));
}

/* (hasQualities: qualities.length > 2 ): We don't want to show the quality setting if there is only 1 quality available,
 and the #1 is the 'AUTOMATIC_QUALITY' */
export function handleQualities(store, { qualities$, selectedQuality$ }, { bitrate$ }) {
  qualities$
    .subscribe((qualities) => store.dispatch({
      type: PLAYBACK_AVAILABLE_QUALITIES,
      payload: {
        qualities,
        hasQualities: qualities.length > 2
      }
    }));

  selectedQuality$
    .subscribe((selectedQualityLevel) => store.dispatch({ type: PLAYBACK_SELECTED_QUALITY, payload: { selectedQualityLevel } }));

  bitrate$
    .subscribe(({ level }) => store.dispatch({ type: PLAYBACK_CURRENT_QUALITY, payload: { currentQualityLevel: level } }));

  combineLatest(qualities$, selectedQuality$, bitrate$)
    .pipe(
      map(([qualities, selectedQualityLevel, { level: currentQualityLevel }]) => {
        const mappedQualities = mapQualities(qualities);
        const isAutomaticLevelSelected = selectedQualityLevel === -1;
        const currentQuality = mappedQualities.find((q) => q.levels.includes(currentQualityLevel));
        const currentSelectedQuality = mappedQualities.find((q) => q.levels.includes(selectedQualityLevel));
        const automaticLevelLabel = isAutomaticLevelSelected && currentQuality?.label ? `automatique (${currentQuality?.label})` : 'automatique';

        return {
          currentQualityLabel: isAutomaticLevelSelected ? automaticLevelLabel : currentSelectedQuality?.label,
          mappedQualities,
          isAutomaticLevelSelected,
          automaticLevelLabel
        };
      })
    )
    .subscribe((payload) => store.dispatch({ type: PLAYBACK_CURRENT_QUALITY_CHANGES, payload }));
}

export function handleTimeshifting(player, { isTimeshiftingStreamLive$, canDisplayStartOverStream$ }) {
  isTimeshiftingStreamLive$.subscribe((isTimeshiftingStreamLive) => player.store.dispatch({
    type: TIMESHIFTING_START_OVER_TIMESHIFTING,
    payload: {
      startOverTimeshifting: !isTimeshiftingStreamLive
    }
  }));
  canDisplayStartOverStream$.subscribe((isAbleToStartOver) => player.store.dispatch({
    type: TIMESHIFTING_CAN_DISPLAY_START_OVER_BUTTON, payload: { isAbleToStartOver }
  }));
}

export function handleTimeshiftingAuto(player, { programPositions$, segmentPositions$, duration$, isDirty$ }) {
  programPositions$
    .pipe(
      combineLatestWith(segmentPositions$),
      withLatestFrom(duration$, isDirty$),
      map(([[imagesEpg, segmentPositions], duration, isDirty]) => ({
        imagesEpg: imagesEpg.map((epg) => ({
          ...epg,
          programDistance: Math.abs(epg.programDistance - duration)
        })).reverse(),
        segmentPositions: segmentPositions.map(({ programDistance }) => programDistance),
        isEPGDirty: isDirty
      }))
    )
    .subscribe(({ imagesEpg, segmentPositions, isEPGDirty }) => {
      player.store.dispatch({
        type: UPDATE_PROGRAM_MARKERS,
        payload: {
          imagesEpg,
          programMarkers: segmentPositions,
          isEPGDirty
        }
      });
    });
}

export function handleNavigation(player, { navigation$ }) {
  navigation$.subscribe(() => {
    player.store.dispatch({
      type: UI_ACCESSIBILITY_VISIBILITY,
      payload: {
        /* We want any changes to have interaction on the UI so UI can be visible inside InteractionHandler function */
        displayUiAccessibility: Date.now()
      }
    });
  });
}

export function handleSkipButton(store, request$) {
  request$.subscribe((event) => store.dispatch({ type: event }));
}

export const handleLabels = (store, labelController) => {
  labelController.liveLabel$
    .subscribe((label) => store.dispatch({ type: LABEL_LIVE_CHANGED, payload: { label } }));

  labelController.timelineLabel$
    .subscribe((label) => store.dispatch({ type: LABEL_TIMELINE_CHANGED, payload: { label } }));

  labelController.pubLabel$
    .subscribe((label) => store.dispatch({ type: LABEL_PUB_CHANGED, payload: { label } }));
};

export function handleTracksAndPanelMenuUI(store, { events$, playerConfig$ }) {
  fromEvent(document, 'keyup')
    .pipe(withLatestFrom(playerConfig$))
    .subscribe(([{ keyCode, keyIdentifier, key: eventKey }, { platform }]) => {
      const { activeElement } = document;
      const closeBtn = document.getElementsByName(
        'close audio et sous-titres'
      )[0];
      const btnTracks = document.getElementsByName('btn-tracks');
      const btns = [
        ['btn-tracks', 'tracks', 'tracks-active'], // tracks buttons
        ['playlist', 'playlist-active'], // playlist buttons
        ['zapping', 'zapping-active'] // zapping buttons
      ].map((keys) => keys.reduce(
        (acc, key) => document.getElementsByName(key)[0] || acc,
        null
      ));

      // display of subtitles and audios container
      const [tracksView] = document.getElementsByName('tracks-view');
      const [programListView] = document.getElementsByName('program-list-view');

      const isDialogVisible = tracksView || programListView;
      const shouldKeepUIVisible = TV_PLATFORMS.includes(platform);

      // dialog close wrapper
      const [, dialogCloser] = document.getElementsByName('close-dialog');

      const activeViewButtonObj = [
        { view: tracksView, btn: btns[0], key: 'tracks' },
        { view: programListView, btn: btns[1], key: 'playlist' },
        { view: programListView, btn: btns[2], key: 'zapping' }
      ].find(({ view, btn }) => view && btn);

      const isEnterKey = keyCode === 13 || keyCode === 32; // Enter or Spacebar
      const backMapping = [27 /* Escape */, ...BACK_BTN_SHORTCUTS];
      const isBackKey = backMapping.includes(keyIdentifier)
      || backMapping.includes(eventKey)
      || backMapping.includes(keyCode);

      const isOpenTracksButtonFocused = activeViewButtonObj?.btn
        && (activeElement === activeViewButtonObj.btn
          || activeViewButtonObj?.btn?.contains(activeElement)
          || closeBtn === activeElement);
      const isCurrentViewContainsFocused = activeViewButtonObj?.view
        && activeViewButtonObj?.view?.contains(activeElement);
      const isOutOfBounds = activeViewButtonObj?.view
        && !isCurrentViewContainsFocused
        && !isOpenTracksButtonFocused;
      /* close when user Enter on close button */
      const hasClosed = (activeViewButtonObj?.view
          && dialogCloser
          && dialogCloser.contains(activeElement)
          && isEnterKey)
        // TODO: this condition is not right yet, we need to refocus to the tracks button
        // when clicking to the close button
        // for now it focus on the parent element of the button (same as in production)
        || (isEnterKey
          && activeElement === btnTracks
          && activeViewButtonObj?.view);

      const focus = (btn) => setTimeout(() => {
        btn?.focus();
      });
      const hideTracks = () => {
        store.dispatch({ type: UI_TRACKS_MENU_HIDE });
        events$.next(UI_TRACKS_MENU_HIDE);
      };

      if (isOpenTracksButtonFocused && isEnterKey) {
        // open when OK on remote
        store.dispatch({ type: UI_TRACKS_MENU_SHOW });
        if (shouldKeepUIVisible) {
          store.dispatch({
            type: UI_IS_INTERACTING,
            payload: { isInteracting: true }
          });
        }
      } else if (
        (isDialogVisible && isBackKey) // close when Back on remote & dialog visible
        || hasClosed
        || (isOutOfBounds && closeBtn !== activeElement)
      ) {
        // refocus origin button if View was not just visible but active
        if (isCurrentViewContainsFocused || hasClosed) focus(activeViewButtonObj?.btn);

        hideTracks();
        store.dispatch({
          type: TOGGLE_SHOW_PANNEL_LIVE_OPTION,
          payload: { show: false, currentTab: EMPTY_ACTIVE_NAME }
        });
        if (shouldKeepUIVisible) {
          store.dispatch({
            type: UI_IS_INTERACTING,
            payload: { isInteracting: false }
          });
        }
      }
    });
}

export function handleRecommendations(store, recommendationController, { playerConfig$, events$ }) {
  playerConfig$.pipe(withLatestFrom(recommendationController.isDisplayableWithCause$)).subscribe(
    ([{ isRestart }, recommendations]) => {
      if (!recommendations.isDisplayable) {
        store.dispatch({
          type: MEDIA_RESTARTED,
          payload: { showRestartButtonOnStartScreen: isRestart }
        });
      }
    }
  );

  recommendationController.shouldDisplay$.subscribe(
    ({ shouldDisplay, cause }) => {
      store.dispatch({ type: RECOMMENDATIONS_DISPLAY_CHANGED, payload: { shouldDisplay, cause } });
    }
  );

  recommendationController.recommendations$.subscribe((recos) => {
    store.dispatch({ type: RECOMMENDATIONS_CHANGED, payload: { recommendations: recos } });
  });

  events$.pipe(
    filter((e) => [PLAYBACK_END, PLAYBACK_TIMEUPDATE].includes(e)),
    map((e) => e === PLAYBACK_END),
    distinctUntilChanged()
  ).subscribe((hideVideo) => {
    store.dispatch({ type: RECOMMENDATIONS_HIDE_VIDEO, payload: { hideVideo } });
  });
}

export function handleHighlights(store, highlights$, duration$, timeshiftable$) {
  highlights$
    .pipe(
      /* skip with duration=0 */
      withLatestFrom(duration$.pipe(filter(Boolean)), timeshiftable$),
      map(([highlights, duration, timeshiftable]) => {
        const maxDuration = timeshiftable === 'auto' ? TIMESHIFTING_AUTO_DURATION : duration;
        return ({
          pinsPosition: highlights
            .filter((({ programDistance: marker }) => marker <= maxDuration))
            .map(({ programDistance, ...remaining }) => ({
              position: 100 - asPercent(programDistance, maxDuration),
              programDistance,
              ...remaining
            })),
          imagesHL: highlights
        });
      })
    )
    .subscribe(({ pinsPosition, imagesHL }) => {
      store.dispatch({
        type: HIGHLIGHTS_CHANGED,
        payload: { pinsPosition, imagesHL }
      });
    });
}

export function handleOrientation(store, domController) {
  domController.orientation$.subscribe((orientation) => {
    store.dispatch({
      type: ORIENTATION_CHANGED,
      payload: { orientation }
    });
  });
}

const defaultHandlers = {
  handleRenderer,
  handleMedia,
  handleMediaRestart,
  handleEvents,
  handleDom,
  handleAudioTracks,
  handleTextTracks,
  handleFullscreen,
  handlePanel,
  handleOrientation,
  handleAd,
  handlePauseRoll,
  handleLoader,
  handleUI,
  handleAutostart,
  handleErrors,
  handleWarnings,
  handleEmbed,
  handleShortcuts,
  handleQualities,
  handleLabels,
  handleTracksAndPanelMenuUI,
  handleRecommendations
};

export function storeFactory(player, handlers = defaultHandlers) {
  const store = createStore();

  const {
    interruptor: { interruptions$ },
    rendererController,
    mediaController,
    embedController,
    commandController,
    shortcutController,
    events$,
    playerConfig$,
    fullscreenController,
    commandController: { commands$ },
    domController,
    freewheelController,
    currentVideo$,
    errors$,
    warning$,
    labelController,
    recommendationController,
    pauserollController,
    userEvents$
  } = player;

  const { audioTracksController, textTracksController, qualityController } = rendererController;
  const { medias$ } = mediaController;

  /*
    Careful : All the handlers are not used here.
    Some are used in the peer controller, when the module is dynamically loaded (startover, zapping, dai, etc)
  */
  handlers.handleErrors(store, { errors$, events$ });
  handlers.handleWarnings(store, { warning$ });
  handlers.handleRenderer(store, rendererController, playerConfig$);
  handlers.handleMedia(store, { mediaController, currentVideo$ });
  handlers.handleEvents(store, { events$, playerConfig$, commands$ });
  handlers.handleDom(store, domController);
  handlers.handleFullscreen(store, { fullscreenController });
  handlers.handlePanel(store, { fullscreenController, freewheelController, userEvents$, pauserollController });
  handlers.handleOrientation(store, domController);
  handlers.handleAudioTracks(store, audioTracksController);
  handlers.handleTextTracks(store, textTracksController);
  handlers.handleAd(store, { events$, freewheelController, cmds$: commandController.commands$ });
  handlers.handlePauseRoll(store, { pauserollController });
  handlers.handleLoader(store, { interruptions$, events$, playerConfig$, commands$, medias$ });
  handlers.handleUI(store, { events$, medias$, playerConfig$ });
  handlers.handleAutostart(store, { playerConfig$ });
  handlers.handleEmbed(store, { embedKey$: embedController.embedKey$, errors$: embedController.errors$ });
  handlers.handleShortcuts(store, shortcutController);
  handlers.handleMediaRestart(store, { medias$: mediaController.medias$, events$, commands$ });
  handlers.handleQualities(store, qualityController, rendererController);
  handlers.handleLabels(store, labelController);
  handlers.handleTracksAndPanelMenuUI(store, { events$, playerConfig$ });

  handlers.handleRecommendations(store, recommendationController, { playerConfig$, events$ });
  return store;
}
