/* eslint-disable react/no-unknown-property */
import { h } from 'preact';
import TvNextIcon from './TvNextIcon';

function TVPreviousIcon({ height = 60, width = 60 }) {
  return (
    <TvNextIcon width={width} height={height} style={{ transform: 'scaleX(-1)' }} />
  );
}

export default TVPreviousIcon;
