import { h } from 'preact';
import { useState, useEffect, useMemo } from 'preact/hooks';
import { usePlayerContext, useStyles } from '../../../hooks';
import { secondsToHHMMSS } from '../../../utils';
import { connect, withBreakPoints } from '../../../hoc';
import { Slider, Titles } from '../../common';
import TimeDisplay from './TimeDisplay';
import Tooltip, { TYPE_AUTO } from './Tooltip';

import { TIMELINE_WRAPPER_STYLE, TIMELINE_TIME_HOUR_STYLE, SPRITES_METADATA_TITLE_PROGRAM_STYLES, SPRITES_METADATA_TITLE_VIDEO_STYLES, TOOLTIP_AUTO_CONTAINER_STYLES, EPG_PROGRAM_VISUAL_SIZE } from './styles';

import { useDialogContext } from '../../../context/DialogContext';
import { SLIDER_BUFFERED_COLOR, SLIDER_TIMELINE_LIVE_COLOR } from '../../../theme/colors';
import LiveCursor from './LiveCursor';
import Position from '../../../utils/Position';
import { dateToHhS, timeToText } from '../../../utils/time';
import {
  TIMESHIFTING_CONTROLLER_NAME,
  TIMESHIFTING_AUTO_DURATION,
  TIMESHIFTING_TYPE_AUTO,
  TIMESHIFTING_TYPE_MANUEL,
  TIMESHIFTING_AUTO_CONTROLLER_NAME
} from '../../../../core/timeshifting/types';
import '../../../theme/styles/sliders.css';
import { asPercent } from '../../../../utils';
import { findCurrentProgram,
  computeThumbnail,
  EMPTY_THUMBNAIL,
  computeSpritesheetLength,
  computeSeekTime,
  computeSeekPos,
  resolveprimaryTrackStyles,
  resolveSliderStyle,
  onStartSlide,
  setHoverTimeline,
  resolveBubbleLabel,
  onStopSlide,
  getThumbnailSizeFromSpritesheets,
  computeEPGSegmentsWidths,
  findHoveredHightlightVisual
} from './utils';
import Pins from './highlights/Pins';
import { HIGHLIGHTS_VISUSAL_CHANGED } from '../../../../store/types';
import HighlightVisual from './highlights/HighlightVisual';

const DEFAULT_THUMBNAIL_WIDTH = 128;
const DEFAULT_THUMBNAIL_HEIGHT = 72;
const TOOLTIP_SIZE = { default: { width: 79, height: 'max-content' }, medium: { width: 85 }, 'large+extraLarge': { width: 110 } };
const CURSOR_SIZE = 24;
const CURSOR_SIZE_EXTRA_SMALL = 20;

const STROKE_SIZE = 8;
const STROKE_EXTRA_SMALL_SIZE = 6;

const MAX_TIMELINE_VALUE_SHOW = 97;

function Timeline({
  currentTime,
  duration,
  buffered,
  isSeeking,
  spritesheets,
  expectedStartOverDuration,
  mobile,
  small,
  extraSmall,
  timeshifting,
  broadcastedAt,
  startOverTimeshifting,
  programMarkers,
  hoverTimeline,
  UIVisible,
  imagesEpg,
  isEPGDirty,
  pinsPosition,
  isHighlightable,
  imagesHL,
  highlightThumbnail,
  pinsHovered
}) {
  const player = usePlayerContext();
  const dialogCtx = useDialogContext();
  const setStyles = useStyles();
  const tooltipSize = setStyles(TOOLTIP_SIZE);
  const isTimeshiftingAuto = TIMESHIFTING_TYPE_AUTO === timeshifting;
  const isTimeshiftingManuel = TIMESHIFTING_TYPE_MANUEL === timeshifting;
  const hasHighlights = isHighlightable && pinsPosition.length > 0;

  /* computedDuration is used to substitute duration with the Startover stream's expected duration */
  const computedDuration = isTimeshiftingAuto ? TIMESHIFTING_AUTO_DURATION : duration;
  const [computedBuffer, setComputedBuffer] = useState(0);
  const [time, setTime] = useState(currentTime);
  const [seekTime, setSeekTime] = useState(0);
  const [seekTimePosition, setSeekTimePosition] = useState(0);
  const [spritesheetLength, setSpritesheetLength] = useState(0);
  const [thumbnail, setThumbnail] = useState(EMPTY_THUMBNAIL);
  const [segments, setSegments] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentSegment, setCurrentSegment] = useState(0);
  const [localHighlightThumbnail, setHighlightThumbnail] = useState(EMPTY_THUMBNAIL);

  const [thumbnailSize, setThumbnailSize] = useState({
    width: isTimeshiftingAuto || hasHighlights ? DEFAULT_THUMBNAIL_WIDTH : 0,
    height: isTimeshiftingAuto || hasHighlights ? DEFAULT_THUMBNAIL_HEIGHT : 0
  });
  const [PMax, setPMax] = useState(0);
  const [leftThumb, setLeftThumb] = useState(0);

  useEffect(() => setTime(isSeeking ? seekTime : currentTime), [isSeeking, currentTime, seekTime]);
  useEffect(
    () => spritesheets.images && setSpritesheetLength(computeSpritesheetLength(spritesheets, duration)),
    [spritesheets, duration]
  );

  useEffect(() => setComputedBuffer(buffered), [computedDuration]);

  const strokeSize = (extraSmall ? STROKE_EXTRA_SMALL_SIZE : STROKE_SIZE);

  const cursorSize = extraSmall ? CURSOR_SIZE_EXTRA_SMALL : CURSOR_SIZE;
  const containerMargin = extraSmall ? 0 : (cursorSize / 2) + 5;

  const programVisualWidth = setStyles(EPG_PROGRAM_VISUAL_SIZE).width;
  const programVisualSize = { width: programVisualWidth, height: programVisualWidth / (16 / 9) };

  const updateHighlightVisual = (nextSeekTime) => {
    const currentHighlight = findHoveredHightlightVisual({
      seekTime: Math.abs(nextSeekTime - duration),
      width: programVisualSize.width,
      height: programVisualSize.height,
      imagesHL
    });

    const nextHighlightVisual = !currentHighlight?.url ? EMPTY_THUMBNAIL : currentHighlight;

    player.store.dispatch({
      type: HIGHLIGHTS_VISUSAL_CHANGED,
      payload: {
        highlightThumbnail: nextHighlightVisual
      }
    });

    setHighlightThumbnail(nextHighlightVisual);
  };

  const onSlide = (pos, max) => {
    const progress = (pos / max);
    const maxPos = isTimeshiftingAuto || hasHighlights ? max : (duration / computedDuration) * max;
    const nextSeekTime = computeSeekTime(progress, duration, computedDuration, timeshifting);
    setSeekTime(nextSeekTime);
    setSeekTimePosition(pos > maxPos ? maxPos : pos);
    setPMax(maxPos);
    const { images = [] } = spritesheets;

    if (hasHighlights && !pinsHovered) {
      updateHighlightVisual(nextSeekTime);
    }

    if (!highlightThumbnail?.url && (images.length || imagesEpg.length)) {
      setThumbnail(
        isTimeshiftingAuto
          ? findCurrentProgram({
            seekTime: nextSeekTime,
            width: programVisualSize.width,
            height: programVisualSize.height,
            imagesEpg
          })
          : computeThumbnail({
            progress,
            spritesheets,
            spritesheetLength,
            ...thumbnailSize
          })
      );
    }
  };

  const hoverPosition = useMemo(
    () => computeSeekPos(duration, computedDuration, timeshifting, seekTime, seekTime),
    [duration, computedDuration, timeshifting, seekTime]
  );

  useEffect(
    () => {
      const size = isTimeshiftingAuto || hasHighlights
        ? { width: programVisualSize.width, height: programVisualSize.height }
        : getThumbnailSizeFromSpritesheets(spritesheets);
      return (thumbnail?.url || highlightThumbnail?.url) && setThumbnailSize(size);
    },
    [thumbnail?.url, highlightThumbnail?.url]
  );

  useEffect(() => {
    if (UIVisible && hoverTimeline) {
      const halfWidth = thumbnailSize.width / 2;
      const commonPosition = seekTimePosition - halfWidth;
      const rightLimit = (seekTimePosition + halfWidth) < PMax ? commonPosition : (PMax - thumbnailSize.width);

      setLeftThumb(commonPosition < 0 ? 0 : rightLimit);
    }
  }, [UIVisible, hoverPosition, hoverTimeline]);

  // In case of timeshifting auto, the slider should represent the 4 hour window,
  // so take the current delay to the live head, and substract it to 4h.s
  const value = ((isTimeshiftingAuto ? (TIMESHIFTING_AUTO_DURATION - (duration - time)) : time) / computedDuration) * 100;

  useEffect(() => {
    if (programMarkers.length) {
      let markers;

      if (programMarkers.length === 1 && programMarkers[0] >= TIMESHIFTING_AUTO_DURATION) {
        markers = [...programMarkers];
      } else {
        markers = programMarkers.filter(((marker) => marker < TIMESHIFTING_AUTO_DURATION));
      }
      const index = markers.findIndex((seg) => asPercent(seg, TIMESHIFTING_AUTO_DURATION) > (100 - value));
      setCurrentIndex(index);
      setCurrentSegment(index === -1 ? 0 : (markers.length - index));
      setSegments(computeEPGSegmentsWidths(markers));
    }
  }, [duration, value]);

  const useEpgTimeline = isTimeshiftingAuto && !isEPGDirty && !!segments.length;

  return (
    <div style={setStyles(TIMELINE_WRAPPER_STYLE)} id="ftv-magneto--ui-timeline">
      <Position>
        <TimeDisplay
          time={timeshifting ? broadcastedAt : time}
          small={small}
          displayFn={timeshifting ? dateToHhS : secondsToHHMMSS}
          hidden={extraSmall || isTimeshiftingAuto}
          style={timeshifting ? useStyles()(TIMELINE_TIME_HOUR_STYLE) : {}}
        />
        <Slider
          multiSegments={useEpgTimeline}
          maxValue={computedDuration}
          value={value}
          /* buffer is used in two way : for the buffering stream and for the live stream */
          buffer={timeshifting ? 100 : computedBuffer}
          touch={mobile}
          strokeSize={strokeSize}
          cursorSize={cursorSize}
          onStartSlide={() => !pinsHovered && onStartSlide(player)}
          onSlide={onSlide}
          onStopSlide={(pos, max) => !pinsHovered && onStopSlide({
            pos, max, duration, computedDuration, timeshifting, player, startOverTimeshifting, imagesHL
          })}
          onMouseEnter={() => setHoverTimeline({ store: player.store, hoverTimeline: true, dialogOpened: dialogCtx.dialogOpened })}
          onFocus={() => setHoverTimeline({ store: player.store, hoverTimeline: true, dialogOpened: dialogCtx.dialogOpened })}
          onMouseLeave={() => setHoverTimeline({ store: player.store, hoverTimeline: false, dialogOpened: dialogCtx.dialogOpened })}
          onBlur={() => setHoverTimeline({ store: player.store, hoverTimeline: false, dialogOpened: dialogCtx.dialogOpened })}
          onMouseMove={(pos, maxPos) => (!isSeeking && onSlide(pos, maxPos))}
          slices={segments}
          currentSegment={currentSegment}
          currentIndex={currentIndex}
          styles={{
            container: {
              flexGrow: 1,
              marginLeft: !isTimeshiftingAuto || timeshifting === null ? containerMargin : 0,
              marginRight: !timeshifting ? containerMargin : 0,
              position: 'relative'
            }
          }}
          primaryTrackStyles={{ ...resolveprimaryTrackStyles(timeshifting, startOverTimeshifting) }}
          secondaryTrackStyles={{ backgroundColor: timeshifting ? SLIDER_TIMELINE_LIVE_COLOR : SLIDER_BUFFERED_COLOR, left: '0px' }}
          sliderStyle={{ ...resolveSliderStyle(timeshifting, startOverTimeshifting) }}
          name="slider-timeline"
          disableFocus={false}
          accessibleName="curseur de progression"
          ariaValueText={!timeshifting
            ? `${timeToText(time)} sur ${timeToText(duration)}`
            : ''}
          trackSize={setStyles({ default: { height: 8 }, extraSmall: { height: 6 } })}
        >

          {/* MAIN TIMELINE TOOLTIP */}
          {!pinsHovered && !highlightThumbnail?.url && (!useEpgTimeline || !isTimeshiftingAuto || isTimeshiftingManuel) && (
          <Tooltip
            withBackgroundPosition
            hidden={extraSmall}
            hover={hoverTimeline}
            isSliding={isSeeking}
            thumbnail={thumbnail}
            dimensions={{
              thumbnail: thumbnailSize,
              tooltip: tooltipSize
            }}
            label={useMemo(
              () => resolveBubbleLabel(seekTime, duration, timeshifting),
              [seekTime, timeshifting]
            )}
            bubblePosition={{
              left: leftThumb,
              ...setStyles({ default: { top: -19 }, large: { top: -26 }, medium: { top: -22 } })
            }}
            // Hide hover seek at 3% before duration to avoid overlay on direct label and seek tooltip
            style={{ ...(timeshifting && hoverPosition >= MAX_TIMELINE_VALUE_SHOW ? { opacity: 0 } : {}) }}
          />
          )}

          {/* EPG Program Visual */}
          { !pinsHovered && !highlightThumbnail?.url && hoverTimeline && isTimeshiftingAuto && useEpgTimeline && (
          <Tooltip
            hidden={extraSmall}
            live
            hover={hoverTimeline}
            isSliding={isSeeking}
            thumbnail={thumbnail}
            dimensions={{
              thumbnail: thumbnailSize,
              tooltip: tooltipSize
            }}
            type={TYPE_AUTO}
            bubblePosition={{ left: leftThumb }}
            style={setStyles(TOOLTIP_AUTO_CONTAINER_STYLES)}
          >
            <Titles
              title={thumbnail.title}
              containerStyle={{ display: 'flex', flexDirection: 'column', gap: 2 }}
              subTitle={thumbnail.additionalTitle}
              titleStyle={setStyles(SPRITES_METADATA_TITLE_PROGRAM_STYLES)}
              subTitleStyle={setStyles(SPRITES_METADATA_TITLE_VIDEO_STYLES)}
            />
          </Tooltip>
          )}

          {/* LIVE POSITION INDICATORS  */}
          {timeshifting && (
          <LiveCursor /* KNOB */
            position={{
              ...{ left: duration > expectedStartOverDuration ? '100%' : `${((duration / expectedStartOverDuration) * 100)}%` },
              ...(timeshifting && !startOverTimeshifting ? { left: isTimeshiftingAuto ? 'calc(100% - 1px)' : '100%' } : {})
            }}
            strokeSize={strokeSize}
            cursorSize={cursorSize}
            onClick={() => (startOverTimeshifting && player.backToLive(TIMESHIFTING_TYPE_AUTO
              ? TIMESHIFTING_AUTO_CONTROLLER_NAME
              : TIMESHIFTING_CONTROLLER_NAME)
            )}
            style={{ ...(startOverTimeshifting
              ? {
                backgroundColor: 'red',
                cursor: 'pointer',
                pointerEvents: 'auto'
              }
              : {})
            }}
            live
            timeshifting={(isTimeshiftingAuto && useEpgTimeline)}
          />
          )}

          { hoverTimeline && hasHighlights && localHighlightThumbnail?.url && (
          <HighlightVisual
            mini={extraSmall}
            width={programVisualSize.width}
            height={programVisualSize.height}
            thumbnailSize={thumbnailSize}
            thumbnail={highlightThumbnail}
            hoverTimeline
            isSeeking
            left={leftThumb}
          />
          )}
          { hasHighlights && !extraSmall && (
          <Pins
            pins={pinsPosition}
            duration={duration}
            currentTimePosition={value}
            programVisualSize={programVisualSize}
            thumbnailSize={thumbnailSize}
            thumbnail={highlightThumbnail}
            hoverTimeline={hoverTimeline}
            isSeeking={isSeeking}
            leftThumb={leftThumb}
            timelineHighlightThumbnail={highlightThumbnail}
            pinsHovered={!!localHighlightThumbnail?.url}
          />
          )}
        </Slider>
        <TimeDisplay
          time={computedDuration}
          small={small}
          hidden={timeshifting || extraSmall}
        />
      </Position>
    </div>
  );
}

const selector = (store) => {
  const { media, playback, ui, highlights } = store;
  const { currentTime, buffered } = playback;
  const { isSeeking, hoverTimeline, UIVisible, timelineLabel } = ui;

  const {
    duration,
    spritesheets,
    startOverLive,
    expectedStartOverDuration,
    timeshifting: {
      type: timeshifting,
      startOverTimeshifting
    },
    broadcastedAt,
    programMarkers,
    imagesEpg,
    isEPGDirty,
    isHighlightable
  } = media;

  return ({
    duration,
    currentTime,
    isSeeking,
    buffered,
    hoverTimeline,
    spritesheets,
    startOverLive,
    expectedStartOverDuration,
    timeshifting,
    broadcastedAt,
    startOverTimeshifting,
    programMarkers,
    UIVisible,
    imagesEpg,
    timelineLabel,
    isEPGDirty,
    isHighlightable,
    ...highlights
  });
};

export default withBreakPoints(connect(selector)(Timeline));
