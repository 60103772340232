import { MEDIA_CHANGED, UPDATE_SETTING } from '../types';

const initialState = {
  comingNext: {
    enabled: false,
    value: true
  }
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case MEDIA_CHANGED: {
      const { isLive } = payload;

      return ({
        ...state,
        comingNext: {
          ...state.comingNext,
          enabled: Boolean(!isLive),
          value: initialState.comingNext.value
        }
      });
    }

    case UPDATE_SETTING: {
      return ({
        ...state,
        ...Object.keys(payload).reduce((settings, setting) => ({
          ...settings,
          [setting]: {
            ...state[setting],
            value: payload[setting]
          }
        }), {})
      });
    }

    default: {
      return state;
    }
  }
};
