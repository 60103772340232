import { TEXT_LEVEL_1_FONT_SIZE_ONLY, TEXT_LEVEL_2_FONT_SIZE, TEXT_LEVEL_3_FONT_SIZE, textStyleOnXLines } from '../../styles';
import { DARK_PRIMARY_TEXT_COLOR, FOURTH_TEXT_COLOR, FTV_DARK_PRIMARY_BACKGROUND_COLOR, PRIMARY_FILL_COLOR } from '../../theme/colors';
import { mapKeyBreakpointToContainer } from '../../utils/tools';
import { BASE_STYLE } from '../common/titles/styles';

export const WRAPPER_STYLE = {
  default: {
    ...BASE_STYLE.default,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'left',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  extraLargeContainer: { width: '457px' },
  largeContainer: { width: '305px' },
  mediumContainer: { width: '305px' },
  smallContainer: { width: '305px' }
};

const txtLvl1Container = mapKeyBreakpointToContainer(TEXT_LEVEL_1_FONT_SIZE_ONLY);
const txtLvl2Container = mapKeyBreakpointToContainer(TEXT_LEVEL_2_FONT_SIZE);
const txtLvl3Container = mapKeyBreakpointToContainer(TEXT_LEVEL_3_FONT_SIZE);

export const SEE_ALSO_STYLE = { ...txtLvl1Container,
  default: { marginBottom: '22.5px', fontWeight: 'bold' },
  smallContainer: { marginBottom: '10px', fontWeight: 'bold', ...txtLvl1Container.smallContainer },
  mediumContainer: { marginBottom: '15px', fontWeight: 'bold', ...txtLvl1Container.mediumContainer },
  largeContainer: { marginBottom: '15px', fontWeight: 'bold', ...txtLvl1Container.largeContainer },
  extraLargeContainer: { marginBottom: '22.5px', fontWeight: 'bold', ...txtLvl1Container.extraLargeContainer }
};

export const RECO_IMAGE_STYLE = {
  default: {
    width: '305px',
    height: '172px',
    borderRadius: '8px',
    position: 'relative',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    aspectRatio: '16/9',
    marginBottom: '16px'
  },
  extraLargeContainer: { marginBottom: '24px', width: '457px', height: '257px' }
};

export const BADGE_STYLE = {
  default: {
    position: 'relative',
    padding: '4px 8px',
    top: '12px',
    left: '12px',
    borderRadius: '5px',
    backgroundColor: PRIMARY_FILL_COLOR,
    color: 'black',
    fontSize: '12px'
  },
  smallContainer: {
    top: '12px',
    left: '12px',
    fontSize: '12px',
    height: '20px'
  },
  mediumContainer: {
    top: '12px',
    left: '12px',
    fontSize: '14px',
    height: '24px'
  },
  'largeContainer+extraLargeContainer': {
    top: '12px',
    left: '12px',
    fontSize: '18px',
    height: '32px'
  }
};
export const ICON_STYLE = { position: 'absolute', top: '10px', right: '10px', width: '18px', height: '18px', backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center center' };
export const CSA_STYLE = { position: 'absolute', bottom: '10px', right: '10px', width: '18px', height: '18px', backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center center' };
export const TITLE_STYLE = {
  extraLargeContainer: { ...txtLvl2Container.extraLargeContainer, marginBottom: '6px' },
  largeContainer: { ...txtLvl2Container.largeContainer, marginBottom: '4px' },
  mediumContainer: { ...txtLvl2Container.mediumContainer, marginBottom: '4px' },
  default: { ...textStyleOnXLines(1), ...txtLvl2Container.default, marginBottom: '4px' },
  smallContainer: { ...txtLvl2Container.smallContainer, marginBottom: '4px' }
};

export const SYNOPSIS_STYLE = {
  ...txtLvl3Container,
  extraLargeContainer: { marginBottom: '22.5px' },
  default: { ...textStyleOnXLines(2), fontFamily: 'Segoe UI', fontSize: '16px', fontWeight: 400, color: FOURTH_TEXT_COLOR, marginBottom: '15px' }
};

export const WATCH_BUTTON_STYLE = {
  default: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    background: 'linear-gradient(90deg, rgba(52, 64, 73, 0.75) 0%, rgba(37, 49, 56, 0.75) 100%)',
    borderRadius: '8px',
    outline: '2px solid #303841',
    padding: '12px',
    height: '84px',
    gap: '12px'
  },
  extraLargeContainer: { height: '126px', padding: '18px', gap: '18px' }
};

export const PLAY_ICON_STYLE = { display: 'flex' };
export const META_STYLE = { display: 'flex', flexDirection: 'column', height: '' };
export const WATCH_STYLE = {
  ...txtLvl2Container,
  mediumContainer: { fontSize: '16px' },
  largeContainer: { fontSize: '18px' },
  extraLargeContainer: { marginBottom: '3px', fontSize: '18px' },
  default: { fontFamily: 'Segoe UI', marginBottom: '2px' }
};
export const SUBTITLE_STYLE = { ...txtLvl3Container, default: { fontSize: 16, fontWeight: 400, fontFamily: 'Segoe UI', ...textStyleOnXLines(1) } };
export const DURATION_STYLE = { ...txtLvl3Container, default: { fontSize: 16, fontFamily: 'Segoe UI', color: FOURTH_TEXT_COLOR } };

export const PLAYER_IMAGE_STYLE = {
  default: {
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center', borderRadius: '8px'
  },
  extraLargeContainer: { width: '327px', height: '184px' },
  largeContainer: { width: '218px', height: '123px' },
  mediumContainer: { width: '208px', height: '117px' },
  smallContainer: { width: '166px', height: '93px' }
};

export const PLAYER_CONTAINER_STYLE = {
  default: { width: '100%' },
  extraLargeContainer: { width: '327px', height: '184px' },
  largeContainer: { width: '218px', height: '123px' },
  mediumContainer: { width: '208px', height: '117px' },
  smallContainer: { width: '166px', height: '93px' }
};
export const PLAYER_WRAPPER_STYLE = {
  default: { display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }
};

export const PLAYER_BUTTON_STYLE = {
  default: { display: 'flex', ...BASE_STYLE.default, width: 'max-content', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', padding: '0px 16px' },
  smallContainer: { height: '32px' },
  mediumContainer: { height: '36px' },
  largeContainer: { height: '44px', padding: '0px 20px' },
  extraLargeContainer: { height: '48px', padding: '0px 20px' }
};

export const PLAYER_BUTTON_DARK_STYLE = {
  ...PLAYER_BUTTON_STYLE,
  default: {
    ...PLAYER_BUTTON_STYLE.default,
    backgroundColor: FTV_DARK_PRIMARY_BACKGROUND_COLOR,
    color: DARK_PRIMARY_TEXT_COLOR
  }
};

export const PLAYER_BUTTON_ICON_STYLE = { default: { marginRight: 4 }, 'largeContainer+extraLargeContainer': { marginRight: 8 } };

export const REPLAY_SVG_SIZE = {
  'largeContainer+extraLargeContainer': { width: 28, height: 28 },
  mediumContainer: { width: 24, height: 24 },
  'smallContainer+extrasmallContainer': { width: 20, height: 20 }
};

export const PLAY_SVG_SIZE = {
  extraLargeContainer: { width: 60, height: 60 },
  largeContainer: { width: 48, height: 48 },
  mediumContainer: { width: 48, height: 48 },
  smallContainer: { width: 17, height: 17 },
  extrasmallContainer: { width: 17, height: 17 }
};

export const METADATA_STYLE = {
  ...txtLvl3Container,
  default: { color: '#B9B9B9', fontSize: '12px' }
};
