import { fromEvent, merge } from 'rxjs';
import { switchMap, map, skip, distinctUntilChanged, mapTo, shareReplay, bufferCount, debounceTime } from 'rxjs/operators';
import { useState, useRef, useEffect, useContext, useCallback } from 'preact/hooks';
import { Player } from '..';
import { VOID_FN } from '../../utils';
import { observeStore } from '../hoc/rxjs-connect';
import { breakpointSelector } from '../hoc';
import { RIGTH, LEFT, DOWN, UP, SWIPE_START, SWIPE_MOVE, SWIPE_END, SWIPE_VERTICAL, SWIPE_HORIZONTAL } from './types';

export const useStateRef = (value) => {
  const [state, setState] = useState(value);
  const ref = useRef(state);
  useEffect(() => { ref.current = state; }, [state]);

  return [state, setState, ref];
};

export const usePlayerContext = () => useContext(Player);

export const useDebouncedEffect = (effect, delay, deps) => {
  const ref = useRef(VOID_FN);
  useEffect(() => {
    const timeout = setTimeout(() => {
      ref.current = effect();
    }, delay);

    return () => {
      clearTimeout(timeout);
      ref.current();
    };
  }, deps);
};

const BREAKPOINTS_ORDER = [
  'default',
  'extraLargeContainer', 'extraLarge',
  'largeContainer', 'large',
  'extraLargeTvContainer', 'extraLargeTv',
  'largeTvContainer', 'largeTv',
  'mediumContainer', 'medium',
  'smallContainer', 'small',
  'tablet',
  'mobile',
  'ios',
  'extraSmallContainer', 'extraSmall'
];

export const resolveStyles = (bp, styles = {}) => {
  /**
   * - styles must be passed per bp key - ie : { default: {}, small: {}, mobile: {} }
   * - composite keys can be built using '+' operator - ie : { 'mobile+small': {} }
   * - "negative" keys are preceded by the '!' operator - ie { '!large': {} }
   * - composite and negative keys can be combined - ie : { '!large+mobile': {} }
   *
   * -> First parse styles per bp key, split/build composite keys, and detect "negative" keys
   * -> sort styles to avoid unwanted overrides due to ordering of breakpoint keys
   *
   * NOTE: bp -> breakpoint
   */
  const parsedStyles = Object
    .entries(styles)
    .reduce((parsed, [key, val]) => ({
      ...parsed,
      ...key.split('+').reduce((bpStyles, bpKey) => {
        const isNegative = bpKey.startsWith('!');
        // const parsedKey = isNegative ? bpKey.substr(1) : bpKey;

        return ({
          ...bpStyles,
          [bpKey]: {
            styles: { ...(parsed[bpKey] ? parsed[bpKey].styles : {}), ...val },
            match: (x) => (isNegative ? !x : x)
          }
        });
      }, {})
    }), {});

  return Object
    .entries(parsedStyles)
    .sort(([key1], [key2]) => BREAKPOINTS_ORDER.indexOf(key1) - BREAKPOINTS_ORDER.indexOf(key2))
    .reduce((finalStyles, [key, { styles: bpStyle, match }]) => ({
      ...finalStyles,
      // Do we have a match for the breakpoint or the negative one. This is why we test without the first character, that can be '!'
      ...(match(bp[key.includes('!') ? key.substring(1) : key]) || key === 'default' ? bpStyle : {})
    }), {});
};

export const useStyles = () => {
  const { store } = usePlayerContext();
  const [breakpoints, setBreakpoints] = useState(breakpointSelector(store.getState()));
  useEffect(() => {
    const sub = observeStore(store, breakpointSelector, (state) => setBreakpoints(state));
    return () => sub.unsubscribe();
  }, []);

  return useCallback((styles) => resolveStyles(breakpoints, styles), Object.values(breakpoints));
};

export const useTextLoader = () => {
  const timer = useRef();
  const [loading, setLoading] = useState(false);
  const [dots, setDots] = useState('');

  const step = (i, n) => {
    timer.current = setTimeout(() => {
      setDots([...Array(i % (n + 1)).keys()].map(() => '.').join(''));
      requestAnimationFrame(() => step(i + 1, n));
    }, 100);
  };

  useEffect(() => {
    if (loading) step(0, 3);
    else {
      clearInterval(timer.current);
      setDots('');
    }

    return () => clearInterval(timer.current);
  }, [loading]);

  return [dots, setLoading];
};

const GESTURE_TIME_WINDOW = 300;
const GESTURE_MIN_DISTANCE = 150;

const swipeStream = (ref, axis, { minDistance, gestureTimeWindow, debounce }) => {
  const position = `page${axis}`;
  const getDirection = (_axis, distance) => {
    if (_axis.match(/X/g)) return distance > 0 ? RIGTH : LEFT;
    return distance > 0 ? UP : DOWN;
  };

  const start$ = fromEvent(ref, 'touchstart').pipe(debounceTime(debounce), shareReplay(1));

  return start$.pipe(switchMap(({ changedTouches: [startChange] }) => {
    const startTime = new Date().getTime();
    const startPosition = startChange[position];

    const move$ = fromEvent(ref, 'touchmove', { passive: true }).pipe(
      map(({ changedTouches: [moveChange] }) => moveChange[position]),
      skip(1),
      distinctUntilChanged(),
      bufferCount(2, 1),
      map(([prev, current]) => current - prev),
      map((distance) => ({
        action: SWIPE_MOVE,
        [`d${axis}`]: distance,
        direction: getDirection(axis, distance),
        distance: Math.abs(distance)
      }))
    );

    const end$ = fromEvent(ref, 'touchend', { passive: true }).pipe(
      map(({ changedTouches: [endChange] }) => {
        if (new Date().getTime() - startTime < gestureTimeWindow) {
          const movement = endChange[position] - startPosition;

          if (Math.abs(movement) > minDistance) {
            return movement < 0 ? -1 : 1;
          }
        }
        return 0;
      }),
      map((swipe) => ({ action: SWIPE_END, [`d${axis}`]: swipe }))
    );

    return merge(start$.pipe(mapTo({ action: SWIPE_START })), move$, end$);
  }));
};

export const useGesture = (ref, gestureName, callback, config = {
  minDistance: GESTURE_MIN_DISTANCE,
  gestureTimeWindow: GESTURE_TIME_WINDOW,
  debounce: 0
}, active) => {
  useEffect(() => {
    if (!ref?.current || !active) return null;
    let sub;
    switch (gestureName) {
      case SWIPE_VERTICAL:
        sub = swipeStream(ref.current, 'Y', config).subscribe(callback);
        break;
      case SWIPE_HORIZONTAL:
      default:
        sub = swipeStream(ref.current, 'X', config).subscribe(callback);
        break;
    }
    return () => sub?.unsubscribe();
  }, [active]);
};

export const userPreviousState = (value, defaultValue = null) => {
  const ref = useRef(defaultValue);
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

/**
 * useLazy is a preact hook to lazy import a preact component, with or without a condition
 *
 * @example
 * const ZAPPING_COMPONENT_PATH = './components/wrappers/Zapping';
 * ...
 * const [isZappingInit, Zapping] = useLazy(
 *   () => import(ZAPPING_COMPONENT_PATH),
 *   () => isFullscreen && zappingEnabled,
 *   [isFullscreen, zappingEnabled]
 * );
 * ...
 * {isZappingInit ? <Zapping /> : <div />} // in JSX
 *
 * @param {() => preact.AnyComponent} moduleCallback - delegate component import
 * @param {() => Boolean} lazyCallback - delegate the condition to import component
 * @param {any[]} dependencies - map lazyCallback deps to let preact known state of lazyCallback changed
 * @returns {[Boolean, preact.AnyComponent | null]} a boolean to do a show/hide condition and the component if ready
 */
export const useLazy = (moduleCallback, lazyCallback = () => true, dependencies = undefined) => {
  const [lazyComponent, setLazyComponent] = useState(null);

  useEffect(async () => {
    const shouldRender = lazyCallback();

    if (!shouldRender || lazyComponent) {
      return;
    }

    setLazyComponent(await moduleCallback());
  }, dependencies);
  const isInit = Boolean(lazyComponent);
  return [isInit, lazyComponent || {}];
};
