/* eslint-disable react/no-unknown-property */
import { h } from 'preact';
import SVGWrapper from '../../SVGWrapper';

function TvNextIconActive({ height = 60, width = 60, ...props }) {
  return (
    <SVGWrapper
      width={width}
      height={height}
      viewBox="0 0 83 83"
      fill="none"
      style={props?.style || {}}
    >
      <path fill-rule="evenodd" clip-rule="evenodd" d="M41.25 82.5C64.0317 82.5 82.5 64.0317 82.5 41.25C82.5 18.4683 64.0317 0 41.25 0C18.4683 0 0 18.4683 0 41.25C0 64.0317 18.4683 82.5 41.25 82.5ZM56.2528 57.1156C56.2528 58.3717 57.2711 59.39 58.5272 59.39C59.7833 59.39 60.8016 58.3717 60.8016 57.1156V25.2744C60.8016 24.0183 59.7833 23 58.5272 23C57.2711 23 56.2528 24.0183 56.2528 25.2744V57.1156ZM21 55.5574V27.0067C21 25.2411 22.9242 24.1488 24.4402 25.0539L49.4221 39.9684C50.9335 40.8708 50.891 43.0742 49.3459 43.9176L24.3641 57.5538C22.8485 58.381 21 57.2841 21 55.5574Z" fill="white" />

    </SVGWrapper>
  );
}

export default TvNextIconActive;
