import 'abortcontroller-polyfill/dist/abortcontroller-polyfill-only';
import { Subject, BehaviorSubject, merge, of, from, combineLatest, ReplaySubject, NEVER, fromEvent } from 'rxjs';
import { map, mapTo, filter, switchMap, take, share, shareReplay, withLatestFrom, scan, skip, debounceTime, bufferCount, startWith } from 'rxjs/operators';

import PlayerUI from './ui';
import { storeFactory } from './store';

import DomController from './core/dom';
import MediaController from './core/media';
import RendererController from './core/renderer';
import CommandController from './core/command';
import FullscreenController from './core/dom/fullscreen';
import ShortcutController from './core/shortcut';
import EmbedController from './core/embed';
import RetryController from './core/retry';
import FreewheelController from './ad/freewheel';
import DebugController from './debug';
import PEER_CONTROLLERS from './peerControllers';

import { systemInfo, VOID_FN, deepMerge, getAdjacentPlaylistMedias, removeDuplication } from './utils';
import error from './error';
import { INITIALIZATION_ERROR } from './error/types';

import Interruptor from './core/interruptor';

import { gateway, epg, highlights, audio, mediation, liveInfo } from './configuration';
import {
  NEW_PLAYER,
  NEW_VIDEO,
  VIDEO_START,
  NEXT,
  NO_OP,
  SETTINGS_OPENED,
  SETTINGS_CLOSED,
  BROADCASTING_TYPE_VIDEO,
  NEW_CONTENT,
  USER_OPENED_MENU,
  USER_CLOSED_MENU,
  AUTO,
  DISPOSED,
  USER_REMOTE_ZAPPING_CLICKED,
  USER_REMOTE_CHAT_CLICKED,
  USER_PANEL_LIVE_OPTIONS_CLOSE,
  USER_REMOTE_HIGHLIGHTS_CLICKED,
  USER_PANEL_ZAPPING_SWITCH_CLICKED,
  USER_PANEL_ZAPPING_PROGRAM_CLICKED,
  USER_PANEL_OPENED,
  USER_PANEL_CLOSED,
  USER_PANEL_CLICKED,
  SECRET_DEBUG_HASH
} from './types';
import { PLAY, FIRST_PLAY, INITIATED_PLAY } from './core/command/types';
import { BEFORE_HOOK, AFTER_HOOK, ADBREAK_HOOK, INTERRUPTION_CANCELED, INTERRUPTION_END, INTERRUPTION_WILL_START } from './core/interruptor/types';
import {
  PLAYER_ERROR_RETRY,
  CONFIG_OVERRIDE,
  PLAYBACK_STOPPED,
  PLAYBACK_PAUSE,
  TITLE_CHANGE,
  UI_TOGGLE_HOVER_LABEL,
  UI_SHOW_REPORTER,
  ZAPPING_META_CHANGED,
  UI_IS_INTERACTING,
  PLAYBACK_END,
  PLAYER_CLOSE,
  PLATFORM,
  ZAPPING_CHAT_CHANGED,
  PLAYLIST_CHANGED
} from './store/types';
import { RENDERER_READY, RENDERER_LIVE_STREAM_DETECTED } from './core/renderer/types';
import FeedBackController from './core/dom/feedback';
import ReporterController from './core/reporter';
import { FOCUS_NEXT_ELEMENT, FOCUS_PREVIOUS_ELEMENT, KEYBOARD_FOCUS, SHORTCUT_MAP } from './core/shortcut/types';
import { MONITORING_EXEMPTED } from './core/monitoring/types';
import NpawController from './core/monitoring/npaw';
import { LabelController } from './core/label';
import SettingsController from './settings';
import MaxwellController from './monitoring/maxwell';
import { RecommendationController } from './core/recommendations';
import { TIMESHIFTING_AUTO_DURATION, TIMESHIFTING_TYPE_AUTO } from './core/timeshifting/types';
import { TVShortcutController } from './core/shortcut/tvshortcut';
import { PIANO_VALUE_REPLAY } from './monitoring/piano/types';
import { TV_PLATFORMS } from './core/dom/types';
import { PauseRollController } from './core/pauseroll';
import VisibilityController from './core/mobile/visibilityController';
import { PLATFORM_TV } from './core/media/types';
import VideoUnlocker from './utils/videoUnlocker';
import TvPlayerUI from './ui-tv';
import CoreUI from './ui/CoreUI';
import { Disposable } from './core';
import EpgController from './core/epg';
import { ACTION_LIVE_PANEL_CLOSED, ACTION_LIVE_PANEL_OPENED, ACTION_LIVE_PANEL_ZAPPING_CLICKED, ACTION_ZAPPING_TAB_CLICKED, DEFAULT_CLOSE_ACTION, HIGHLIGHTS_NAME, LIVE_OPTION_PANEL_USER_EVENTS_TO_FEATURE, ZAPPING_NAME } from './ui/components/wrappers/Zap/constants';
import Media from './core/media/Media';

export const DEFAULT_CONFIG = {
  showAd: true,
  adRequireFocus: true,
  midroll: true,
  preroll: true,
  autostart: true,
  preferences: {},
  preload: true,
  origin: 'france.tv',
  next: false,
  showTitle: true,
  forceShowTitle: false,
  pip: false,
  comingNext: {
    showOnStart: true,
    timecode: null,
    duration: null,
    timeBeforeDismiss: null
  },
  webservices: {
    gateway: gateway.url,
    epg: epg.url,
    highlights: highlights.url,
    audio: audio.url,
    mediation,
    liveInfo: liveInfo.url
  },
  zapping: [],
  tracking: {},
  videoProductId: '',
  showViewers: false,
  minViewers: 0,
  reporter: true,
  consent: {
    estat: MONITORING_EXEMPTED,
    npaw: MONITORING_EXEMPTED,
    nielsen: undefined,
    freewheel: false,
    adUserId: null,
    euid: null,
    idl: null
  },
  debug: false,
  adways: false,
  publicId: null,
  notifyOnWatchtime: null,
  intro: false,
  userLoggedIn: false,
  loginRequired: false,
  platform: '',
  env: {
    connection_type: '',
    device: '',
    app_version: '',
    firmware: ''
  },
  broadcastingType: BROADCASTING_TYPE_VIDEO,
  pauseroll: true,
  chat: null,
  chattable: false,
  quanteec: true,
  nielsen: true
};

export default class Magnetoscope {
  constructor(domNode, config = {}) {
    if (!domNode) throw error({ message: 'Missing target dom node' });

    this.errors$ = new Subject();
    this.setupReady$ = new Subject();
    this.nextVideo$ = new Subject().pipe(scan((_, media, index) => ({ ...media, config: { ...media.config, index } }), {}));
    this.events$ = new BehaviorSubject(NEW_PLAYER);
    this.quickSeek$ = new Subject();
    this.stop$ = new Subject();
    this.retry$ = new BehaviorSubject(false);
    this.restart$ = new BehaviorSubject(false);
    this.warning$ = new Subject();
    this.isAd$ = new Subject();
    this.layers = {};
    this.listeners = {};
    this.focused$ = new Subject();
    this.playerConfig$ = new ReplaySubject(1);
    this.shouldDisplayReco$ = new BehaviorSubject(false);
    this.userEvents$ = new ReplaySubject(1);
    this.videoStart$ = new Subject();

    Magnetoscope
      .createUserEventsResetStream({ nextVideo$: this.nextVideo$, userEvents$: this.userEvents$ })
      .subscribe(this.userEvents$);

    /* force intro to false */

    const hydratedConfig = deepMerge(
      DEFAULT_CONFIG,
      config,
      { intro: false },
      new URLSearchParams(window.location.search).has(SECRET_DEBUG_HASH) ? { debug: true } : {}
    );
    this.config$ = this.setupReady$.pipe(
      mapTo(hydratedConfig),
      shareReplay(1)
    );

    this.videoUnlocker = new VideoUnlocker({
      max: TV_PLATFORMS.includes(hydratedConfig.platform) ? 1 : 15
    });

    this.domController = new DomController({
      domNode,
      events$: this.events$,
      setupReady$: this.setupReady$,
      shouldDisplayReco$: this.shouldDisplayReco$,
      config: hydratedConfig,
      userEvents$: this.userEvents$
    });

    this.interruptor = new Interruptor({
      /* trigger interruption cancel$ on each new video */
      cancel$: this.nextVideo$
    });

    this.currentVideo$ = Magnetoscope.createCurrentVideoStream({
      nextVideo$: this.nextVideo$,
      interruptions$: this.interruptor.interruptions$
    });

    this.mediaController = new MediaController(this);

    this.setupPeerControllers(PEER_CONTROLLERS, {
      medias$: this.mediaController.medias$,
      systemInfo$: of(systemInfo),
      playerConfig$: this.playerConfig$
    });

    Magnetoscope.createPlayerConfigStreamForCurrentVideo({
      medias$: Media.pendingMedia$,
      config: hydratedConfig
    })
      .subscribe(this.playerConfig$);

    this.commandController = new CommandController({
      medias$: this.mediaController.medias$,
      events$: this.events$,
      interruptions$: this.interruptor.interruptions$,
      playerConfig$: this.playerConfig$
    });

    this.freewheelController = new FreewheelController({
      player: this,
      playerConfig: hydratedConfig,
      init$: this.setupReady$
    });

    this.rendererController = new RendererController({
      currentVideo$: this.currentVideo$,
      medias$: this.mediaController.medias$,
      commands$: this.commandController.commands$,
      dom$: this.domController.dom$,
      events$: this.events$,
      isAd$: this.freewheelController.isAd$,
      playerConfig$: this.playerConfig$,
      platform: hydratedConfig.platform,
      showAd: hydratedConfig.showAd,
      interruptions$: this.interruptor.interruptions$,
      videoUnlocker: this.videoUnlocker
    });

    this.activeVideoTag$ = Magnetoscope.createActiveVideoTagStream({
      freewheelController: this.freewheelController,
      activeRenderer$: this.rendererController.activeRenderer$
    });

    this.fullscreenController = new FullscreenController({
      container: domNode,
      activeVideoTag$: this.activeVideoTag$
    });

    this.shortcutController = !TV_PLATFORMS.includes(hydratedConfig.platform)
      ? new ShortcutController({
        player: this
      })
      : new TVShortcutController({
        player: this
      });

    this.feedbackController = new FeedBackController({ userEvents$: this.userEvents$ });
    this.maxwellController = new MaxwellController({ medias$: this.mediaController.medias$, playerConfig$: this.playerConfig$ });

    Magnetoscope.createVideoStartStream({
      interruptions$: this.interruptor.interruptions$,
      commands$: this.commandController.commands$
    }).subscribe(this.videoStart$);

    this.isLive$ = new ReplaySubject(1);
    Magnetoscope.createIsLiveStream({
      medias$: this.mediaController.medias$,
      rendererState$: this.rendererController.state$
    }).subscribe(this.isLive$);

    this.recommendationController = new RecommendationController(this);

    this.timeshiftable$ = new ReplaySubject(1);
    Magnetoscope.createIsTimeshiftableStream({ medias$: this.mediaController.medias$ })
      .subscribe(this.timeshiftable$);

    this.epgController = new EpgController(this);

    this.settingsController = new SettingsController({
      player: this,
      medias$: this.mediaController.medias$,
      userEvents$: this.userEvents$,
      audioTracks$: this.rendererController.audioTracks$,
      textTracks$: this.rendererController.textTracks$,
      commands$: this.commandController.commands$,
      events$: this.events$,
      interruptions$: this.interruptor.interruptions$
    });

    this.embedController = new EmbedController({
      medias$: this.mediaController.medias$,
      origin: hydratedConfig.origin,
      playerConfig$: this.playerConfig$
    });

    if (hydratedConfig.debug) {
      this.debugController = new DebugController({
        playerConfig$: this.playerConfig$,
        mediaController: this.mediaController,
        events$: this.events$,
        rendererController: this.rendererController,
        errors$: this.errors$,
        commandController: this.commandController
      });
    }

    this.npawController = new NpawController(this, hydratedConfig);

    this.handleStopOnLoad(this.events$);
    this.handleNextAuto(this.events$, this.mediaController.medias$);
    this.handleStopCommand(
      this.stop$,
      this.currentVideo$,
      this.events$,
      this.isLive$,
      this.timeshiftable$,
      this.playerConfig$
    );
    this.handleRestartCommand(this.restart$, this.currentVideo$);
    this.handleRetryCommand(this.retry$, this.currentVideo$, this.rendererController.currentTime$, hydratedConfig);
    this.handleQuickSeek(this.quickSeek$, this.rendererController.currentTime$, this.mediaController.medias$);
    this.handleConfigOverrides(this.config$, this.currentVideo$);
    this.handleDomCleanUp(this.mediaController.medias$);
    this.handleFeedBack(this.feedbackController.feedback$);
    this.handleReporter(this.playerConfig$);

    this.createPlayerHooks(BEFORE_HOOK, AFTER_HOOK, ADBREAK_HOOK);

    this.interruptor.mapToInterruption(
      Magnetoscope.createBeforeHookStream(
        this.mediaController.medias$,
        this.commandController.commands$
      ),
      BEFORE_HOOK
    );

    this.mediaController.errors$.subscribe(this.errors$);
    this.rendererController.errors$.subscribe(this.errors$);

    this.retryController = new RetryController({
      errors$: this.errors$
    });
    this.handleRetry(this.retryController.retry$);

    this.reporterController = new ReporterController({ medias$: this.medias$, activeVideoTag$: this.activeVideoTag$, errors$: this.errors$ });

    /* expose errors and renderer ready on main events$ stream */
    this.createEventsProxyStream().subscribe(this.events$);

    /* preload assets before initializing Magnetoscope */

    this.setupSubscription = from(CoreUI.checkFonts()).subscribe(() => {
      const { platform } = hydratedConfig;

      if (TV_PLATFORMS.includes(platform)) {
        this.ui = new TvPlayerUI({ player: this, container: domNode });
        this.store.dispatch({
          type: PLATFORM,
          payload: { platform }
        });
        this.setupReady$.next();
      } else {
        this.ui = new PlayerUI({ player: this, noUI: hydratedConfig.noUI, container: domNode });
        this.setupReady$.next();
      }
      this.ui.init();
    });

    this.stop$.subscribe(() => {
      this.setupSubscription.unsubscribe();
    });

    this.labelController = new LabelController(this);

    this.visibilityController = new VisibilityController();
    this.pauserollController = new PauseRollController(this);

    Magnetoscope.createOnBeforeUnloadStream().subscribe(() => this.dispose());

    this.store = storeFactory(this);

    this.npawController.init();

    Magnetoscope.mapUserEventsToEvents(this.userEvents$).subscribe(this.events$);
    Magnetoscope.mapLiveOptionsPanelUserEventsToEvents(this.userEvents$, this.store).subscribe(this.events$);
  }

  static createPlayerConfigStreamForCurrentVideo({ medias$, config }) {
    return medias$.pipe(map(({ config: mediaConfig }) => deepMerge(config, mediaConfig)));
  }

  static mapUserEventsToEvents(userEvents$) {
    return userEvents$.pipe(
      filter(({ action }) => [
        SETTINGS_OPENED,
        SETTINGS_CLOSED,
        USER_OPENED_MENU,
        USER_CLOSED_MENU,
        USER_PANEL_OPENED,
        USER_PANEL_CLOSED,
        USER_PANEL_CLICKED
      ].includes(action)),
      map(({ action, ...payload }) => ({ name: action, payload }))
    );
  }

  // Todo: to remove when/if Magneto integ is/has been removed: using userEvents internally will be enough
  static mapLiveOptionsPanelUserEventsToEvents(userEvents$, store) {
    return userEvents$.pipe(
      filter(({ action }) => [
        USER_REMOTE_ZAPPING_CLICKED,
        USER_REMOTE_CHAT_CLICKED,
        USER_REMOTE_HIGHLIGHTS_CLICKED,
        USER_PANEL_LIVE_OPTIONS_CLOSE,
        USER_PANEL_ZAPPING_SWITCH_CLICKED,
        USER_PANEL_ZAPPING_PROGRAM_CLICKED
      ].includes(action)),
      switchMap(({ action, value }) => {
        const {
          zapping: {
            current: { tabIndex, lastTabIndex },
            list
          },
          ui: {
            panelLiveOption: { currentTab: currentLiveOptionTab, lastTab: lastLiveOptionTab }
          }
        } = store.getState();

        if (action === USER_PANEL_ZAPPING_SWITCH_CLICKED) {
          const { from: switchingFromTabIndex, to: switchingToTabIndex } = value;
          const { title: titlePreviousTab } = list[switchingFromTabIndex];
          const { title: titleNewTab } = list[switchingToTabIndex];

          return of({
            name: ACTION_ZAPPING_TAB_CLICKED,
            payload: {
              from: titlePreviousTab,
              to: titleNewTab
            }
          });
        }

        if (action === USER_PANEL_ZAPPING_PROGRAM_CLICKED) {
          const { title: titleCurrentTab } = list[tabIndex];
          const { title: titlePreviousTab = null } = list[lastTabIndex] || {};

          return of({
            name: ACTION_LIVE_PANEL_ZAPPING_CLICKED,
            payload: { ...value, currentTab: titleCurrentTab, previousTab: titlePreviousTab }
          });
        }

        const isToggleIconAction = Object.keys(
          LIVE_OPTION_PANEL_USER_EVENTS_TO_FEATURE
        ).includes(action);
        const isCloseAction = (isToggleIconAction && !value)
          || action === USER_PANEL_LIVE_OPTIONS_CLOSE;

        if (isCloseAction) {
          const button = LIVE_OPTION_PANEL_USER_EVENTS_TO_FEATURE[action]
            || DEFAULT_CLOSE_ACTION;

          return of({
            name: ACTION_LIVE_PANEL_CLOSED,
            payload: {
              feature: lastLiveOptionTab,
              button
            }
          });
        }

        const { title: currentZapTabName, ...currentZapTab } = list[tabIndex] || [{ title: '' }];
        const itemsCount = currentZapTab?.channels?.length || 0;

        const closeThePreviousEvent = isToggleIconAction && lastLiveOptionTab && value && {
          name: ACTION_LIVE_PANEL_CLOSED,
          payload: {
            feature: lastLiveOptionTab,
            button: LIVE_OPTION_PANEL_USER_EVENTS_TO_FEATURE[action]
          }
        };

        const openEvent = {
          name: ACTION_LIVE_PANEL_OPENED,
          payload: {
            feature: currentLiveOptionTab,
            tabName:
              action === USER_REMOTE_ZAPPING_CLICKED ? currentZapTabName : null,
            itemsCount: [ZAPPING_NAME, HIGHLIGHTS_NAME].includes(currentLiveOptionTab) ? itemsCount : null
          }
        };

        const events = [closeThePreviousEvent, openEvent].filter(Boolean);

        return of(...events);
      })
    );
  }

  static createBeforeHookStream(medias$, commands$) {
    /* BEFORE_HOOK triggered on each FIRST_PLAY of each new media */
    return medias$
      .pipe(switchMap(() => commands$.pipe(
        filter(({ type }) => type === FIRST_PLAY),
        take(1)
      )));
  }

  static createCurrentVideoStream({ nextVideo$, interruptions$ }) {
    /**
     * currentVideo$ needs to emit only when interruptor is in a READY state -
     * If previous status doesn't match a READY state, we wait for it
     */
    const lastInterruption$ = interruptions$.pipe(shareReplay(1));
    return nextVideo$
      .pipe(switchMap((video) => lastInterruption$
        .pipe(
          take(1),
          switchMap(({ status }) => (
            Interruptor.READY_FLAGS.includes(status)
              ? of(video)
              : interruptions$.pipe(
                filter((interruption) => Interruptor.READY_FLAGS.includes(interruption.status)),
                take(1),
                mapTo(video)
              )))
        )));
  }

  static createActiveVideoTagStream({ freewheelController, activeRenderer$ }) {
    /* default to autostart: true for every subsequent load calls (ie: NEXT call) */
    return freewheelController.isAd$
      .pipe(
        switchMap(({ isAd }) => (isAd
          ? of(freewheelController.adLayer.videoObj)
          : activeRenderer$
            .pipe(map(({ renderer }) => renderer.tagElement))
        )),
        shareReplay(1)
      );
  }

  static createVideoStartStream({ interruptions$, commands$ }) {
    /* VIDEO_START should emit only after BEFORE_HOOK interruption resolves */
    return interruptions$.pipe(
      filter(({ type, status }) => type === BEFORE_HOOK && status === INTERRUPTION_WILL_START),
      switchMap(() => interruptions$.pipe(
        filter(({ status, type }) => type === BEFORE_HOOK && [INTERRUPTION_CANCELED, INTERRUPTION_END].includes(status)),
        switchMap(() => commands$.pipe(
          filter(({ type }) => type === PLAY || type === INITIATED_PLAY),
          take(1)
        )),
        take(1),
        mapTo(VIDEO_START)
      )),
      share()
    );
  }

  static formatErrors(errors$) {
    /* format errors to event type object */
    return errors$.pipe(filter(({ error: e }) => e.fatal), map(({ error: e }) => ({ name: 'error', error: e })));
  }

  get medias$() {
    return this.mediaController.medias$;
  }

  createEventsProxyStream() {
    return merge(
      Magnetoscope.formatErrors(this.errors$),
      this.rendererController.state$.pipe(filter((state) => state === RENDERER_READY)),
      this.videoStart$
    );
  }

  static createIsLiveStream({ medias$, rendererState$ }) {
    return merge(
      medias$.pipe(map(({ isLive }) => isLive)),
      rendererState$.pipe(filter((evt) => evt === RENDERER_LIVE_STREAM_DETECTED), mapTo(true))
    ).pipe(share());
  }

  static createIsTimeshiftableStream({ medias$ }) {
    return medias$.pipe(map(({ video: { timeshiftable } }) => timeshiftable));
  }

  setupPeerControllers(peerCtrls, { medias$, systemInfo$, playerConfig$ }) {
    const setup$ = combineLatest(
      playerConfig$,
      medias$,
      systemInfo$,
      (config, media, sysInfo) => ({ config, media, systemInfo: sysInfo })
    ).pipe(switchMap((options) => {
      const ctrls = peerCtrls.filter(({ match, name }) => match(options) && !this[name]);
      return (
        ctrls.length
          ? merge(...ctrls.map((ctrl) => from(ctrl.load())
            .pipe(map(({ default: CtrlClass }) => ({ CtrlClass, ...ctrl })))))
          : NEVER
      );
    }));

    setup$.subscribe(({ name, CtrlClass, setup }) => {
      this[name] = new CtrlClass(this);
      setup(this[name], this);
      this.events$.next(`${name}Loaded`);
    });
  }

  load({ config = {}, ...video }) {
    if (!Object.prototype.hasOwnProperty.call(video, 'src')) {
      return error({
        type: INITIALIZATION_ERROR,
        message: 'Invalid parameter: you must supply Object{src: String, config: Object{}} to load()'
      });
    }
    this.events$.next(NEW_VIDEO);
    this.events$.next({ name: NEW_CONTENT, payload: video });

    const {
      ui: { isPIP },
      settings: { comingNext }
    } = this.store.getState();

    /**
     * check if comingNext setting is enabled and override autoplay
     * configuration with current value
     */
    const media = {
      ...video,
      config: {
        ...config,
        ...(comingNext.enabled && !config.isRestart ? { autostart: comingNext.value } : {})
      }
    };

    /* TODO: we should have implement an async "clean-up" stream to combine with currentVideo$
       in order to safely load new videos */
    return Promise
      /* exit PIP on chrome before new load -> PIPController.autoClose not working on Chrome */
      .resolve((isPIP && document.exitPictureInPicture) ? document.exitPictureInPicture() : null)
      .then(() => this.currentVideo$.next(media))
      .catch(() => this.currentVideo$.next(media));
  }

  retry() {
    this.retry$.next(true);
  }

  restart(config) {
    this.restart$.next(config);
  }

  play({ userGesture = false } = {}) {
    if (
      this.store.getState().media.timeshifting.type === TIMESHIFTING_TYPE_AUTO
      && this.getDuration()
      && (TIMESHIFTING_AUTO_DURATION < (this.getDuration() - this.getCurrentTime()))
    ) {
      this.seek(Math.abs(this.getDuration() - TIMESHIFTING_AUTO_DURATION));
    }
    this.commandController.play({ userGesture });
  }

  pause() {
    this.commandController.pause();
  }

  stop() {
    this.stop$.next();
  }

  seek(position, userGesture) {
    this.commandController.seek(position, userGesture);
  }

  forward() {
    this.quickSeek$.next(1);
  }

  rewind() {
    this.quickSeek$.next(-1);
  }

  mute(value = true) {
    this.commandController.mute(value);
  }

  volume(value) {
    this.commandController.volume(value);
  }

  speed(value) {
    this.commandController.speed(value);
  }

  fullscreen(active) {
    this.fullscreenController.fullscreen(active);
    this.store.dispatch({
      type: UI_IS_INTERACTING,
      payload: { isInteracting: false }
    });
  }

  next(payload) {
    this.events$.next({ name: NEXT, payload });
  }

  requestPIP(val) {
    return this.pipController?.requestPIP(val);
  }

  requestEmbed() {
    this.embedController.click$.next();
  }

  startOver(controllerName) {
    this[controllerName].startOver();
  }

  backToLive(controllerName) {
    this[controllerName].backToLive();
  }

  getPlayerContainer() {
    return [this.domController.container];
  }

  getLayer(name) {
    return this.domController.getLayer(name);
  }

  zappingClose() {
    this.store.dispatch({ type: ZAPPING_META_CHANGED, payload: { metaOpened: false } });
  }

  applyCustomInnerWrapperStyle(customStyle = {}) {
    DomController.applyCustomInnerWrapperStyle(this.getPlayerContainer()[0], customStyle);
  }

  resetInnerWrapperStyle() {
    DomController.resetInnerWrapperStyle(this.getPlayerContainer()[0]);
  }

  handleRestartCommand(restart$, currentVideo$) {
    restart$.pipe(
      filter(Boolean),
      withLatestFrom(currentVideo$),
      map(([restartConfig, video]) => ({ video, restartConfig }))
    ).subscribe(({ video, restartConfig }) => {
      // reset freewheel to avoid piano::player.start/feature:ad_after for video restarts
      this.freewheelController.resetFreewheel();

      this.load({
        src: video.src,
        config: {
          ...video.config,
          ...restartConfig,
          showAd: false, // force no ads and piano::player.start/cause:disabled_ads when restart video
          isRestart: true,
          tracking: { ...video.config.tracking, playProvenance: PIANO_VALUE_REPLAY },
          startTimecode: 0
        }
      });

      // Cheat: we need to next(false) so we can track the lancement_player with retry$
      // CF: PianoAd::adLess()
      setTimeout(() => restart$.next(false), 2000);
    });
  }

  handleStopCommand(stop$, currentVideo$, events$, isLive$, timeshiftable$, playerConfig$) {
    const pauseOnLive$ = events$.pipe(
      withLatestFrom(isLive$, timeshiftable$),
      filter(([event, isLive, timeshiftable]) => event === PLAYBACK_PAUSE && (isLive && !timeshiftable))
    );

    return merge(
      stop$,
      pauseOnLive$
    )
      .pipe(withLatestFrom(currentVideo$, playerConfig$, (_, video, config) => [video, config]))
      .subscribe(([video, config]) => {
        if (PLATFORM_TV.includes(config.platform)) {
          events$.next(PLAYER_CLOSE);
        }
        events$.next(PLAYBACK_STOPPED);
        const stoppedConfig = { ...video.config, ...{ autostart: false, showAd: false, preload: false } };
        this.load({
          src: video.src,
          config: stoppedConfig
        });
      });
  }

  handleStopOnLoad(events$) {
    /* Force 'stopped' event when loading new video */
    events$.pipe(
      filter((evt) => evt === NEW_VIDEO),
      skip(1),
      mapTo(PLAYBACK_STOPPED)
    ).subscribe(this.events$);
  }

  handleNextAuto(events$, medias$) {
    events$.pipe(
      filter((evt) => evt === PLAYBACK_END),
      withLatestFrom(medias$),
      map(([, { config: { platform, next }, meta: { id } }]) => ({ platform, next, id }))
    ).subscribe(({ platform, next, id }) => {
      const { nextMedia } = getAdjacentPlaylistMedias(this.store.getState(), id);

      if (nextMedia) {
        this.load(nextMedia);
        return;
      }

      if (next) {
        this.next(AUTO);
      }

      if (PLATFORM_TV.includes(platform) && !next) {
        this.events$.next(PLAYER_CLOSE);
      }
    });
  }

  handleRetryCommand(retry$, currentVideo$, currentTime$, config) {
    const hasMediaChanged$ = new BehaviorSubject(false);
    currentVideo$.pipe(
      bufferCount(2, 1),
      map(([media1, media2]) => !!media2?.src && media1?.src !== media2?.src)
    ).subscribe(hasMediaChanged$);

    return retry$
      .pipe(
        filter(Boolean),
        withLatestFrom(
          currentVideo$,
          currentTime$.pipe(
            bufferCount(6, 3), // because on error currentTime = 0
            map((cur) => (cur[2])),
            startWith(0) // we need to start with 0 because some error are thrown early before the renderer is created,
          ),
          this.playerConfig$.pipe(startWith(config)),
          hasMediaChanged$,
          (_, video, currentTime, playerConfig, hasMediaChanged) => ({
            video,
            currentTime: hasMediaChanged ? 0 : currentTime,
            playerConfig
          })
        )
      )
      .subscribe(({ video, currentTime, playerConfig }) => {
        this.store.dispatch({ type: PLAYER_ERROR_RETRY });
        this.load({
          src: video.src,
          config: {
            ...playerConfig,
            showAd: false,
            startTimecode: currentTime
          } });
        // Cheat: we need to next(false) so we can track the lancement_player with retry$
        // CF: PianoAd::adLess()
        setTimeout(() => retry$.next(false), 2000);
      });
  }

  handleQuickSeek(quickSeek$, currentTime$, media$) {
    return quickSeek$.pipe(
      withLatestFrom(
        currentTime$,
        media$,
        (dir, time, { video: { timeshiftable } }) => Math.min(
          Math.max(
            // If the is a shifting live, we want to stay in the last 4h of the video (so we can go back 4h from the duration at max)
            timeshiftable ? this.getDuration() - TIMESHIFTING_AUTO_DURATION : 0,
            time + (dir * 10)
          ),
          Math.floor(this.getDuration())
        )
      )
    ).subscribe((to) => {
      this.seek(to);
    });
  }

  handleConfigOverrides(playerConfig$, currentVideo$) {
    /* merge global config and active video config to reflect changes in UI */
    return combineLatest(playerConfig$, currentVideo$, (a, { config: b }) => ({ ...a, ...b }))
      .subscribe((config) => this.store.dispatch({
        type: CONFIG_OVERRIDE,
        payload: { config }
      }));
  }

  handleKeyEvent(keyCode, { shiftKey }) {
    let value = keyCode;

    if (SHORTCUT_MAP[keyCode] === KEYBOARD_FOCUS) { // TAB
      value = shiftKey ? FOCUS_PREVIOUS_ELEMENT : FOCUS_NEXT_ELEMENT;
    }

    this.shortcutController.stream$.next(value);
  }

  handleClickEvent() { this.shortcutController.click$.next(); }

  handleDomCleanUp(source$) {
    return source$.subscribe(() => this.domController.reset());
  }

  handleFeedBack(feedback$) {
    return feedback$.subscribe(() => {
      this.store.dispatch({ type: UI_TOGGLE_HOVER_LABEL, payload: { showHover: false } });
    });
  }

  static createOnBeforeUnloadStream() {
    return fromEvent(window, 'beforeunload');
  }

  static createUserEventsResetStream({ nextVideo$, userEvents$ }) {
    /**
     * Resets userEvents$ after own emission and on each new video :
     *
     * userEvents$ is generally used with a combineLatest/withLatestFrom operator
     * followed by some filtering -> resetting the stream simplifies the process as
     * userEvents$ is a ReplaySubject and WILL re-emit previous value on subscription
     * ie : live reached tracking needs to be able to ensure no userEvents$ happened
     */
    return merge(
      nextVideo$,
      userEvents$.pipe(
        filter(({ action }) => action !== NO_OP),
        debounceTime(650)
      )
    ).pipe(mapTo({ action: NO_OP }));
  }

  handleReporter(playerConfig$) {
    playerConfig$.subscribe(({ reporter }) => {
      this.store.dispatch({ type: UI_SHOW_REPORTER, payload: { reporter } });
    });
  }

  handleRetry(stream$) {
    return stream$.subscribe(() => this.retry());
  }

  handleAd(...isAdStreams) {
    return merge(...isAdStreams).subscribe(this.isAd$);
  }

  setAudioTrack(track) { this.rendererController.selectAudioTrack(track); }

  setSubtitleTrack(index) { this.rendererController.selectTextTrack(index); }

  setSubtitleContainer(node) { this.rendererController.setSubtitleContainer(node); }

  setVideoQuality(level) { this.rendererController.setVideoQuality(level); }

  createPlayerHooks(...hooks) {
    return hooks.map((hook) => this.interruptor.createHook(hook));
  }

  mapEventsToHooks(...events) {
    return events.map(({ source, from: _from, to }) => this.interruptor.mapToInterruption(source.pipe(filter((evt) => evt === _from)), to));
  }

  getCurrentTime() {
    const {
      playback: {
        currentTime
      },
      media: {
        isLive,
        timeshifting
      }
    } = this.store.getState();

    return isLive && timeshifting.type === null ? 0 : currentTime;
  }

  getDuration() {
    const { media: { duration } } = this.store.getState();

    return duration;
  }

  getCurrentProgress() {
    const { media: { isLive } } = this.store.getState();

    return (isLive || !this.getDuration()) ? 0 : parseFloat(((this.getCurrentTime() / this.getDuration()) * 100).toFixed(2));
  }

  select(selector = VOID_FN) {
    return selector(this.store.getState());
  }

  dispatch({ type, payload }) {
    this.store.dispatch({ type, payload });
  }

  on(eventName, callback) {
    if (!eventName || !callback) {
      throw error('Failed to execute \'on\', on Magnetoscope: 2 arguments are required. An event name and a callback function.');
    }

    if (!this.listeners[eventName]) this.listeners[eventName] = [];

    let subscription;

    /* events can be of type String or Object{name, payload} */
    function factory(event) {
      if (typeof event === 'string') callback(event);
      if (typeof event === 'object') callback(...Object.values(event));
    }

    if (eventName === '*') {
      subscription = this.events$.subscribe(factory);
    } else {
      subscription = this.events$
        .pipe(filter((event) => event === eventName || event.name === eventName))
        .subscribe(factory);
    }

    this.listeners[eventName].push({ callback, subscription });
  }

  off(eventName, callback) {
    if (!eventName || !callback) {
      throw error('Failed to execute \'off\', on Magnetoscope: 2 arguments are required. An event name and the associated callback function.');
    }

    const index = this.listeners[eventName].findIndex((listener) => listener.callback === callback);

    this.listeners[eventName][index].subscription.unsubscribe();
    this.listeners[eventName].splice(index, 1);
  }

  trigger(name, payload) {
    this.events$.next(payload ? ({ name, payload }) : name);
  }

  adClick(adType) {
    this[`${adType}Controller`].handleAdClick();
  }

  setInteractionLayer(layer) {
    this.interactionLayer = layer;
  }

  updateZappingMetadata(meta, updateMode) {
    return this.metadataController?.updateMetaData({ meta, updateMode });
  }

  /* eslint-disable-next-line */
  toJSON() {
    /**
     * avoid circular references when stringifying player instance.
     */
    return ({
      name: 'Magnetoscope',
      version: global.MAGNETO_VERSION
    });
  }

  setTitles(titles) {
    this.store.dispatch({
      type: TITLE_CHANGE,
      payload: { titles }
    });
  }

  report(question, rate) {
    this.reporterController.report(question, rate);
  }

  warn(message) {
    this.warning$.next(message);
  }

  dispose(emptyContainer) {
    this.events$.next(DISPOSED);
    // TODO Add a dispose for each controller and complete every stream. This is half-assed for now
    this.rendererController.activeRenderer$.subscribe((renderer) => RendererController.disposeRenderer(renderer));

    // eslint-disable-next-line no-restricted-syntax
    for (const [, value] of Object.entries(this)) {
      if (value instanceof Disposable) {
        value.dispose();
      }

      if (value instanceof Subject) {
        value.complete();
      }
    }

    if (this.ui) {
      this.ui.dispose(emptyContainer);
    }
  }

  static async isAdserverReachable() {
    const value = await FreewheelController.isAdServerReachable();
    return Boolean(value); // cast "unknown" as true
  }

  setReco(recommendations = []) {
    if (!recommendations.length) return;

    this.recommendationController.recommendations$.next(recommendations);
  }

  setChatAvailable(chatAvailable) {
    this.store.dispatch({
      type: ZAPPING_CHAT_CHANGED,
      payload: { chatAvailable }
    });

    this.events$.next({
      action: ZAPPING_CHAT_CHANGED
    });
  }

  setPlaylist(playlist) {
    this.store.dispatch({
      type: PLAYLIST_CHANGED,
      payload: { playlist: removeDuplication(playlist, 'src') }
    });
  }
}
