import { h } from 'preact';
import { useRef, useState, useLayoutEffect, useMemo } from 'preact/hooks';
import { usePlayerContext, useStyles } from '../../../hooks';
import { connect } from '../../../hoc';
import { Button } from '../../common';
import PlaybackWrapper from './PlaybackWrapper';

import {
  REWIND_BUTTON_PATH,
  TIMESHIFTING_BEGIN
} from '../../../theme/svg';

import {
  USER_CLICK,
  USER_FAST_FORWARD,
  USER_FAST_REWIND,
  USER_NEXT,
  USER_TIMESHIFTING_BEGIN,
  USER_TIMESHIFTING_BACK_TO_LIVE
} from '../../../../types';
import { computeButtonSize } from '../../../utils/tools';
import { DEFAULT_BUTTON_SIZE } from '../../common/buttons/styles';
import { PLAYBACK_MATCH_STATE, TIMESHIFTING_TYPE_MAP } from './const';
import { getAdjacentPlaylistMedias } from '../../../../utils';

function PlaybackControl(props) {
  const {
    centered, hidden, mobile, playButton, id, isLive, isAd, isDVR, isStartOverEnabled, next, showCountdown,
    timeshifting, isAbleToStartOverTS, startOverTimeshifting, playlist, playlistIdsAsString, playlistEnabled, showNextPlaylistBtn, previous
  } = props;
  const player = usePlayerContext();
  const wrapperRef = useRef(null);
  const [translate, setTranslate] = useState(0);
  const setStyle = useStyles();

  useLayoutEffect(() => {
    /**
     * Ensure PLAY button is always centered
     * 1. Count all visible buttons and find main button index
     */
    const buttons = wrapperRef.current.props.children;
    const visibleBtns = buttons.filter(({ props: { hidden: buttonHidden } }) => !buttonHidden);
    const { length } = visibleBtns;
    const mainBtnIndex = visibleBtns.findIndex(({ key }) => key === 'main-button');
    /**
     * 2. Determine if we should apply a correction to center the main button :
     * - Either the number of visible buttons is divisible by 2
     * - OR the number of buttons BEFORE the main button isn't balanced with the number of buttons AFTER
     */
    const shouldTranslate = centered && length > 1 && (length % 2 === 0 || length - 1 - mainBtnIndex !== mainBtnIndex);
    /* 3. Calculate delta between number of buttons BEFORE and AFTER main button to calculate translation */
    const delta = shouldTranslate ? ((length - mainBtnIndex - 1) - mainBtnIndex) : 0;
    /* 4. compute main button dimensions */
    const baseBtnSize = computeButtonSize({ size: DEFAULT_BUTTON_SIZE, xs: true, mobileFriendly: mobile });
    /* 5. Calculate correction based on btn size and delta direction */
    const correction = (delta > 0 ? -1 : 1) * (baseBtnSize / 2);
    /* 6. Calculate final displacement */
    setTranslate((delta * baseBtnSize) + (shouldTranslate ? correction : 0));
  }, [...Object.values(props)]);

  const { previousMedia, nextMedia, previousIndex, nextIndex } = useMemo(
    () => getAdjacentPlaylistMedias({ playlist: { playlist } }, id),
    [id, playlistIdsAsString]
  );

  return (
    <PlaybackWrapper centered={centered} hidden={hidden} ref={wrapperRef} styles={{ transform: `translateX(${translate}px)` }}>
      <Button /* PREVIOUS BUTTON */
        label="vidéo précédente"
        ariaLabel="vidéo précédente"
        ariaHidden="false"
        hidden={isAd || showCountdown || !previous || !previousMedia}
        onClick={() => {
          player.userEvents$.next({
            action: USER_NEXT,
            source: USER_CLICK
          });
          player.load({
            ...previousMedia,
            config: {
              ...previousMedia.config,
              diffusion: { mode: 'tunnel', position: previousIndex + 1, length: playlist.length },
              tracking: {
                pageProvenance: '',
                zoneProvenance: '',
                positionVignette: '',
                playProvenance: 'tunnel_previous'
              },
              comingNext: {
                program: playlist[previousIndex + 1]?.title ?? playlist[previousIndex + 1].config.program,
                title: playlist[previousIndex + 1]?.preTitle ?? playlist[previousIndex + 1].config.title
              }
            }
          });
        }}
        mobileFriendly={mobile}
        xs={!centered}
        name="btn-next"
        disableFocus={false}
        type="icon"
        icon="previous"
        size={setStyle({
          'extraSmall+medium': { width: 36 },
          small: { width: 40 },
          'large+extraLarge': { width: 44 },
          'largeTv+extraLargeTv': { width: 60 }
        }).width}
      />

      <Button /* START OVER BUTTON */
        label="revenir au début"
        ariaLabel="revenir au début"
        ariaHidden="false"
        hidden={
          !(isLive && (timeshifting ? isAbleToStartOverTS : isStartOverEnabled))
          || ((isAd || showCountdown) && !startOverTimeshifting) || (isDVR && !timeshifting)
        }
        onClick={() => {
          player.startOver(TIMESHIFTING_TYPE_MAP[timeshifting]);
          player.userEvents$.next({
            action: USER_TIMESHIFTING_BEGIN,
            source: USER_CLICK
          });
        }}
        type="animated"
        mobileFriendly={mobile}
        svg={TIMESHIFTING_BEGIN}
        xs={!centered}
        transition={{
          duration: 350,
          max: -360,
          apply: (val) => ({
            transformOrigin: '52.2% 50.2%',
            transform: `rotate(${val}deg)`
          })
        }}
        name="btn-start-over"
        disableFocus={false}
        icon="back-to-start"
        size={setStyle({
          'extraSmall+medium': { size: 36 },
          small: { size: 40 },
          'large+extraLarge': { size: 44 },
          'largeTv+extraLargeTv': { width: 60 }
        }).size}
      />

      <Button /* REWIND BUTTON */
        type="feedback"
        icon="rewind"
        label="reculer"
        ariaLabel="reculer de 10 secondes"
        ariaHidden="false"
        hidden={(isLive && !timeshifting) || ((isAd || showCountdown) && !startOverTimeshifting)}
        onClick={() => {
          player.userEvents$.next({ action: USER_FAST_REWIND, source: USER_CLICK });
          player.rewind();
        }}
        mobileFriendly={mobile}
        svg={REWIND_BUTTON_PATH}
        svgFeedback="text10"
        fnFeedback={(val) => `-${10 * val}`}
        transition={{ max: -45, apply: (val) => ({ transform: `rotate(${val}deg)` }) }}
        xs={!centered}
        name="btn-rewind"
        disableFocus={false}
        size={setStyle({
          'extraSmall+medium': { width: 36 },
          small: { width: 40 },
          'large+extraLarge': { width: 44 },
          'largeTv+extraLargeTv': { width: 60 }
        }).width}
      />

      <Button /* PLAY BUTTON */
        key="main-button"
        label={PLAYBACK_MATCH_STATE[playButton].label}
        ariaLabel={PLAYBACK_MATCH_STATE[playButton].label.replace(/ \(k\)/, ' raccourci touche k')}
        ariaHidden="false"
        type={['PLAY', 'PAUSE'].includes(playButton) ? 'icon' : 'default'}
        icon={playButton.toLowerCase()}
        toggle={playButton === 'PAUSE'}
        onClick={() => {
          if (PLAYBACK_MATCH_STATE[playButton].userEvent) {
            player.userEvents$.next({ action: PLAYBACK_MATCH_STATE[playButton].userEvent, source: USER_CLICK });
          }
          player[PLAYBACK_MATCH_STATE[playButton].fn]({ userGesture: true });
        }}
        svg={PLAYBACK_MATCH_STATE[playButton].svg}
        mobileFriendly={mobile}
        xs={!centered}
        xl
        name="btn-play"
        disableFocus={false}
        size={setStyle({
          'extraSmall+medium': { width: 48 },
          'small+large+extraLarge': { width: 60 },
          'largeTv+extraLargeTv': { width: 90 }
        }).width}
      />

      <Button /* FORWARD BUTTON */
        type="feedback"
        icon="forward"
        label="avancer"
        ariaLabel="avancer de 10 secondes"
        ariaHidden="false"
        hidden={(isLive && !timeshifting) || ((isAd || showCountdown) && !startOverTimeshifting)}
        onClick={() => {
          player.userEvents$.next({ action: USER_FAST_FORWARD, source: USER_CLICK });
          player.forward();
        }}
        mobileFriendly={mobile}
        svgFeedback="text10"
        fnFeedback={(val) => `+${10 * val}`}
        transition={{ max: 45, apply: (val) => ({ transform: `rotate(${val}deg)` }) }}
        xs={!centered}
        name="btn-forward"
        disableFocus={false}
        size={setStyle({
          'extraSmall+medium': { width: 36 },
          small: { width: 40 },
          'large+extraLarge': { width: 44 },
          'largeTv+extraLargeTv': { width: 60 }
        }).width}
      />

      <Button /* BACK TO LIVE BUTTON */
        label="revenir au direct"
        ariaLabel="revenir au direct"
        ariaHidden="false"
        hidden={(timeshifting ? !startOverTimeshifting : isLive)
          || ((isAd || showCountdown) && !startOverTimeshifting)
          || (!isDVR && !timeshifting)}
        onClick={() => {
          player.backToLive(TIMESHIFTING_TYPE_MAP[timeshifting]);
          player.userEvents$.next({
            action: USER_TIMESHIFTING_BACK_TO_LIVE,
            source: USER_CLICK
          });
        }}
        mobileFriendly={mobile}
        type="icon"
        icon="back-to-live"
        xs={!centered}
        name="btn-back-to-live"
        disableFocus={false}
        size={setStyle({
          'extraSmall+medium': { width: 36 },
          small: { width: 40 },
          'large+extraLarge': { width: 44 },
          'largeTv+extraLargeTv': { width: 60 }
        }).width}
      />

      <Button /* NEXT BUTTON */
        label="vidéo suivante"
        ariaLabel="vidéo suivante"
        ariaHidden="false"
        hidden={isDVR || isAd || showCountdown || (playlistEnabled ? (!showNextPlaylistBtn || !nextMedia) : !next)}
        onClick={() => {
          player.userEvents$.next({
            action: USER_NEXT,
            source: USER_CLICK
          });
          if (nextMedia) {
            player.load({
              ...nextMedia,
              config: {
                ...nextMedia.config,
                diffusion: { mode: 'tunnel', position: nextIndex + 1, length: playlist.length },
                tracking: {
                  pageProvenance: '',
                  zoneProvenance: '',
                  positionVignette: '',
                  playProvenance: 'tunnel_next'
                },
                comingNext: nextIndex + 1 < playlist.length ? {
                  program: playlist[nextIndex + 1]?.title ?? playlist[nextIndex + 1].config.program,
                  title: playlist[nextIndex + 1]?.preTitle ?? playlist[nextIndex + 1].config.title
                } : {}
              }
            });
          } else {
            player.next('button');
          }
        }}
        mobileFriendly={mobile}
        xs={!centered}
        name="btn-next"
        disableFocus={false}
        type="icon"
        icon="next"
        size={setStyle({
          'extraSmall+medium': { width: 36 },
          small: { width: 40 },
          'large+extraLarge': { width: 44 },
          'largeTv+extraLargeTv': { width: 60 }
        }).width}
      />

    </PlaybackWrapper>
  );
}

const selector = ({
  ui: { next },
  ad: { showCountdown },
  playback: { playButton },
  media: {
    id,
    isLive,
    isAd,
    isDAI,
    isDVR,
    isStartOverEnabled,
    timeshifting: {
      type: timeshifting,
      isAbleToStartOver: isAbleToStartOverTS,
      startOverTimeshifting
    }
  },
  playlist: { playlist, playlistIdsAsString, playlistEnabled = playlist.length > 0 }
}) => ({
  playButton: (
    (isLive && !timeshifting) && !(isAd && !isDAI) && playButton === 'PAUSE'
      ? 'STOP'
      : playButton
  ),
  id,
  isLive,
  isStartOverEnabled,
  isDVR,
  isAd,
  showCountdown,
  next,
  timeshifting,
  isAbleToStartOverTS,
  startOverTimeshifting,
  previous: !isLive && playlistEnabled && playlist[0].src !== id,
  showNextPlaylistBtn: !isLive && playlistEnabled && playlist[playlist.length - 1].src !== id,
  showPlaylist: !isLive && playlistEnabled,
  playlist,
  playlistIdsAsString,
  playlistEnabled
});

export default connect(selector)(PlaybackControl);
