import { h } from 'preact';
import { connect } from './rxjs-connect';

export const breakpointSelector = ({
  systemInfo: { isMobile: mobile, isIOS: ios, isTablet: tablet },
  ui: {
    isExtraLargeTvScreen: extraLargeTv,
    isExtraLargeScreen: extraLarge,
    isLargeTvScreen: largeTv,
    isLargeScreen: large,
    isMediumScreen: medium,
    isSmallScreen: small,
    isExtraSmallScreen: extraSmall,
    containerIsExtraLargeTvScreen: extraLargeTvContainer,
    containerIsLargeTvScreen: largeTvContainer,
    containerIsExtraLargeScreen: extraLargeContainer,
    containerIsLargeScreen: largeContainer,
    containerIsMediumScreen: mediumContainer,
    containerIsSmallScreen: smallContainer,
    containerIsExtraSmallScreen: extraSmallContainer,
    width,
    height,
    containerWidth,
    containerHeight
  }
}) => ({
  mobile, ios, large, largeTv, medium, small, extraSmall, width, height, containerWidth,
  containerHeight, tablet, extraLarge, extraLargeTv, extraLargeTvContainer,
  extraLargeContainer,
  largeTvContainer,
  largeContainer,
  mediumContainer,
  smallContainer,
  extraSmallContainer
});

export function withBreakPoints(Comp) {
  function ResponsiveComp({
    extraLargeTv,
    extraLarge,
    largeTv,
    large,
    medium,
    small,
    extraSmall,
    extraLargeTvContainer,
    extraLargeContainer,
    largeTvContainer,
    largeContainer,
    mediumContainer,
    smallContainer,
    extraSmallContainer,
    mobile,
    tablet,
    ios,
    width,
    height,
    containerWidth,
    containerHeight,
    ...props
  }) {
    return (
      <Comp
        extraLarge={extraLarge}
        extraLargeTv={extraLargeTv}
        extraLargeTvContainer={extraLargeTvContainer}
        extraLargeContainer={extraLargeContainer}
        largeTvContainer={largeTvContainer}
        largeContainer={largeContainer}
        mediumContainer={mediumContainer}
        smallContainer={smallContainer}
        extraSmallContainer={extraSmallContainer}
        large={large}
        largeTv={largeTv}
        medium={medium}
        small={small}
        extraSmall={extraSmall}
        mobile={mobile}
        tablet={tablet}
        ios={ios}
        width={width}
        height={height}
        containerWidth={containerWidth}
        containerHeight={containerHeight}
        {...props}
      />
    );
  }

  return connect(breakpointSelector)(ResponsiveComp);
}
