import { h } from 'preact';
import { useMemo, useState } from 'preact/hooks';
import { VOID_FN } from '../../../../utils';
import { Icon } from '../../../../ui/components/common/svg/icons';
import { BUTTON_CLASS, ENTER_BUTTTONS_LIST, NO_FUCUS_RING } from '../../../../core/shortcut/types';
import { ACTIVE_LABELED_BUTTON_STYLE, BUTTON_STYLE } from './style';
import { usePlayerContext } from '../../../../ui/hooks';
import { UI_IS_INTERACTING } from '../../../../store/types';
import Wrappable from '../wrappable';
import { BTN_ACTIVE_SCALE, BTN_DEFAULT_SCALE, BTN_SIZE } from './const';

const clickHandler = (e, { onClick, store }) => {
  e.preventDefault();
  e.stopPropagation();

  store.dispatch({
    type: UI_IS_INTERACTING,
    payload: { isInteracting: true }
  });
  store.dispatch({
    type: UI_IS_INTERACTING,
    payload: { isInteracting: false }
  });

  onClick(e);
};

function TVButton({
  id,
  label = '',
  name,
  icon,
  size = BTN_SIZE,
  onClick = VOID_FN,
  onKeyDown = VOID_FN,
  hidden,
  style = {},
  customActiveStyle = {},
  iconWrapperStyle = {},
  labelWrapperStyle = {},
  iconStyle = {},
  className = 'ftv-btn',
  btnRef,
  forceActive = false,
  labelFirst = false,
  wrapChildren = false,
  disableScale = false,
  ...othersProp
}) {
  const { store } = usePlayerContext();
  const [isActive, setIsActive] = useState(false);
  const active = isActive || forceActive;

  const transform = useMemo(() => {
    let scaleFactor = BTN_DEFAULT_SCALE;
    if (!disableScale && active) {
      scaleFactor = BTN_ACTIVE_SCALE;
    }
    return style.transform
      ? `${style.transform} scale(${scaleFactor})`
      : `scale(${scaleFactor})`;
  }, [style.transform, active, label, disableScale, size]);

  return (!hidden
    && (
    <div
      role="button"
      name={name}
      tabIndex="0"
      id={id}
      ref={btnRef}
      onClick={(e) => clickHandler(e, { onClick, store })}
      onFocus={() => setIsActive(true)}
      onBlur={() => setIsActive(false)}
      onKeyDown={(e) => {
        if ([13, 32 /* Space or Enter */, ...ENTER_BUTTTONS_LIST].includes(e.keyCode)) {
          clickHandler(e, { onClick, store });
          onKeyDown(e);
        }
      }}
      style={{
        ...style,
        height: size,
        transform,
        ...BUTTON_STYLE,
        ...(label && active ? ACTIVE_LABELED_BUTTON_STYLE : {}),
        ...(active ? customActiveStyle : {}),
        cursor: 'pointer'
      }}
      className={`${className} ${BUTTON_CLASS} ${NO_FUCUS_RING}`}
      {...othersProp}
    >
      {labelFirst && (
        <Wrappable wrap={wrapChildren} style={labelWrapperStyle}>
          {label}
        </Wrappable>
      )}
      {icon && (
      <Wrappable wrap={wrapChildren} style={iconWrapperStyle}>
        <Icon
          name={`${icon}${active ? '-active' : ''}`}
          width={size}
          height={size}
          style={iconStyle}
        />
      </Wrappable>

      )}
      {!labelFirst
      && (
        <Wrappable wrap={wrapChildren} style={labelWrapperStyle}>
          {label}
        </Wrappable>
      )}
    </div>
    )
  );
}

export { TVButton };
