import {
  BehaviorSubject,
  filter,
  map,
  switchMap,
  Subject,
  merge,
  withLatestFrom,
  combineLatest,
  distinctUntilChanged,
  distinctUntilKeyChanged,
  startWith,
  throttleTime
} from 'rxjs';
import { CAUSE_NO_RECO, CAUSE_PLAYER_NO_FULLSCREEN, CAUSE_PLAYER_TOO_SMALL, CAUSE_USER_CLOSED } from './types';
import { PLAYBACK_END } from '../../store/types';
import { BLOCK_RECOMMENDATIONS, NEED_RECO } from '../../types';
import { takeUntilWithLast } from '../../utils/rx-utils';
import { Disposable } from '..';
import { getAdjacentPlaylistMedias } from '../../utils';
import { DIFFUSION_MODE_SINGLE } from '../../settings/types';

export class RecommendationController extends Disposable {
  constructor(player) {
    super();
    const receivedRecommendationsFromIntegration$ = new BehaviorSubject(false);

    this.isDisplayableWithCause$ = new BehaviorSubject({ isDisplayable: false, cause: CAUSE_NO_RECO });
    this.recommendations$ = new BehaviorSubject([]);
    this.shouldDisplay$ = new Subject();

    RecommendationController.createRecommendationConfigDetectionStream(player.isLive$, this.recommendations$)
      .subscribe(receivedRecommendationsFromIntegration$);

    RecommendationController.createDisplayRecommendationsWithCauseStream(
      receivedRecommendationsFromIntegration$,
      {
        dimensions$: player.domController.dimensions$,
        fullscreenChange$: player.fullscreenController.fullscreenChange$,
        userEvents$: player.userEvents$
      }
    ).subscribe(this.isDisplayableWithCause$);

    RecommendationController.createNeedRecoStream(player)
      .subscribe((e) => player.events$.next(e));

    RecommendationController.createMediasEndDetectionStream(player, this.isDisplayableWithCause$)
      .subscribe(RecommendationController.restart.bind(this, player));

    // Log final object in the console with debug: true
    RecommendationController.createShouldDisplayStream(
      player.mediaController.medias$,
      player.events$,
      player.rendererController.currentTime$,
      this.isDisplayableWithCause$
    )
      .subscribe(this.shouldDisplay$);
    this.shouldDisplay$.subscribe(player.shouldDisplayReco$);

    // Log final object in the console with debug: true isDisplayableWithCause
    this.isDisplayableWithCause$
      .pipe(map((payload) => ({ name: 'recommendations', payload })))
      .subscribe((e) => player.events$.next(e));
  }

  static restart(player, { isDisplayableWithCause: { isDisplayable } }) {
    if (!isDisplayable) {
      player.restart({ autostart: false });
    }
  }

  static createNeedRecoStream(player) {
    const { mediaController: { medias$ }, playerConfig$ } = player;
    return medias$.pipe(
      withLatestFrom(playerConfig$),
      filter(([{ meta: { id }, isLive }, playerConfig]) => {
        const { playlist: { playlist } } = player.store.getState();
        const isSingleMedia = !playlist.length && (!playerConfig?.diffusion?.mode || playerConfig?.diffusion?.mode === DIFFUSION_MODE_SINGLE);
        const isPlaylistLastMedia = playlist.length && !getAdjacentPlaylistMedias({ playlist: { playlist } }, id).nextMedia;

        return !isLive && (isSingleMedia || isPlaylistLastMedia);
      }),
      map(([{ meta: { id } }]) => ({ name: NEED_RECO, payload: { src: id } }))
    );
  }

  static createRecommendationConfigDetectionStream(isLive$, recommendations$) {
    return isLive$.pipe(
      filter((isLive) => !isLive),
      switchMap(() => recommendations$.pipe(
        filter((recommendations) => recommendations && recommendations.length),
        map(() => true)
      ))
    );
  }

  static createDisplayRecommendationsWithCauseStream(
    isFlowEnabled$,
    { dimensions$, fullscreenChange$, userEvents$ }
  ) {
    return combineLatest([isFlowEnabled$, dimensions$, fullscreenChange$.pipe(startWith(false))]).pipe(
      distinctUntilChanged((
        [prevFlow, { containerIsExtraSmallScreen: prevXSmall }, prevFS],
        [nextFlow, { containerIsExtraSmallScreen: nextXSmall }, nextFS]
      ) => prevFlow === nextFlow && prevXSmall === nextXSmall && prevFS === nextFS),
      map(([isFlowEnabled, { containerIsExtraSmallScreen }, isFullscreen]) => {
        if (!isFlowEnabled) { return { isDisplayable: false, cause: CAUSE_NO_RECO }; }
        if (containerIsExtraSmallScreen) return { isDisplayable: false, cause: CAUSE_PLAYER_TOO_SMALL };
        if (!isFullscreen) return { isDisplayable: false, cause: CAUSE_PLAYER_NO_FULLSCREEN };

        return { isDisplayable: true };
      }),
      takeUntilWithLast(userEvents$.pipe(filter((e) => e.action === BLOCK_RECOMMENDATIONS)), { isDisplayable: false, cause: CAUSE_USER_CLOSED })
    );
  }

  static createMediasEndDetectionStream({ events$, playerConfig$, currentVideo$ }, isDisplayableWithCause$) {
    return events$.pipe(
      withLatestFrom(playerConfig$, currentVideo$, isDisplayableWithCause$),
      filter(([event, { next }]) => event === PLAYBACK_END && !next),
      map(([, , currentVideo, isDisplayableWithCause]) => ({ currentVideo, isDisplayableWithCause }))
    );
  }

  static createShouldDisplayStream(media$, events$, currentTime$, isDisplayableWithCause$) {
    const ended$ = media$.pipe(switchMap(() => events$.pipe(
      filter((e) => e === PLAYBACK_END),
      map(() => true),
      startWith(false)
    )));
    const afterClosingCredits$ = media$.pipe(
      map(({ video: { closing_credits: { timecode } } }) => timecode),
      filter(Boolean),
      switchMap((timecode) => currentTime$.pipe(
        throttleTime(50),
        map((currentTime) => currentTime > timecode),
        distinctUntilChanged()
      ))
    );

    return merge(ended$, afterClosingCredits$).pipe(
      switchMap((displayTiming) => isDisplayableWithCause$.pipe(
        map(({ isDisplayable, cause }) => ({ shouldDisplay: displayTiming && isDisplayable, cause })),
        distinctUntilKeyChanged('shouldDisplay')
      ))
    );
  }
}
