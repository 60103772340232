import { PLAYER_ERROR, PLAYER_ERROR_RETRY, PLAYER_ERROR_RESET } from '../types';

const initialState = {
  type: null,
  code: null,
  fatal: false,
  ip: null,
  message: null,
  orangeErrorCode: null,
  orangeUserCode: null,
  orangeMessage: null,
  orangeTitle: null,
  payload: {}
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case PLAYER_ERROR_RESET:
    case PLAYER_ERROR_RETRY: {
      return initialState;
    }
    case PLAYER_ERROR: {
      return { ...state, ...payload.error };
    }
    default: {
      return state;
    }
  }
};
