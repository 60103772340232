import { MAIN_GRADIENT } from '../../theme/colors';

export const UI_WRAPPER_STYLE = {
  position: 'relative',
  width: '100%',
  height: '100%',
  transition: 'opacity 0.3s, background 0.5s ease-in-out',
  outline: 'none',
  top: 0,
  userSelect: 'none',
  MozUserSelect: 'none',
  WebkitUserSelect: 'none',
  MsUserSelect: 'none',
  flex: 1
};

export const MAIN_WRAPPER_PADDING = {
  default: { padding: '33px 40px 20px 40px' },
  medium: { padding: '27px 30px 15px 30px' },
  small: { padding: '25px 18px 5px 18px' },
  extraSmall: { padding: '20px 14px 10px 14px' },
  'largeTv+extraLargeTv': { padding: '40px 80px 40px 80px' }
};

export const MAIN_WRAPPER_STYLE = {
  default: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    padding: 40,
    color: 'white',
    transition: 'opacity .2s ease-in-out'
  },
  'largeTv+extraLargeTv': {
    display: 'flex',
    justifyContent: 'end',
    flexDirection: 'column',
    gap: '24px'
  }
};

export const MAIN_WRAPPER_SHOW = {
  opacity: 1,
  backgroundImage: MAIN_GRADIENT,
  transition: 'opacity .2s ease-in-out'
};

export const MAIN_WRAPPER_HIDE = {
  opacity: 0,
  pointerEvents: 'none',
  backgroundImage: 'none'
};

export const FLEX_50_VERTICAL_STYLE = {
  flex: '0 0 100%',
  height: '50%'
};

export const SECTION_TOP_DEFAULT_STYLE = {
  default: {
    ...FLEX_50_VERTICAL_STYLE,
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'row',
    justifyContent: 'space-between',
    pointerEvents: 'none'
  },
  'largeTv+extraLargeTv': {
    flex: 'unset',
    height: 'unset',
    alignItems: 'flex-end'
  }
};

export const SECTION_CENTER_DEFAULT_STYLE = {
  position: 'absolute',
  left: '0',
  right: '0',
  bottom: '0',
  top: '0',
  margin: 'auto',
  width: 'auto',
  height: 250,
  display: 'flex',
  pointerEvents: 'none'
};

export const ADPAUSE_SCREEN = {
  background: 'purple',
  outlineOffset: '-1px',
  width: '100%',
  position: 'absolute',
  top: 0,
  left: 0
};

export const ADPAUSE_SCREEN_BACKGROUND = {
  width: '100%',
  height: '100%',
  backgroundColor: 'black',
  backgroundSize: 'cover',
  display: 'flex'
};

export const ADPAUSE_SCREEN_CONTROLS_WRAPPER = {
  background: 'transparent',
  width: '100%',
  height: '100%',
  position: 'relative'
};

export const ADPAUSE_SCREEN_AD_LABEL = {
  default: { userSelect: 'none', position: 'absolute', left: 30, top: 36 },
  small: { left: 18, top: 18 },
  extraSmall: { left: 12, top: 12 },
  'large+extraLarge': { left: 48, top: 48 }
};

export const ADPAUSE_SCREEN_CLOSE_BUTTON_WRAPPER = {
  default: { position: 'absolute', right: 30, top: 36 },
  small: { right: 18, top: 18 },
  extraSmall: { right: 12, top: 12 },
  'large+extraLarge': { right: 48, top: 48 }
};

export const ADPAUSE_SCREEN_RESUME_BUTTON_WRAPPER = {
  default: { position: 'absolute', left: 30, bottom: 30 },
  small: { left: 18, bottom: 5 },
  extraSmall: { left: 12, bottom: 10 },
  'large+extraLarge': { left: 48, bottom: 40 }
};

export const ADPAUSE_SCREEN_FULLSCREEN_BUTTON_WRAPPER = {
  default: { position: 'absolute', right: 30, bottom: 30 },
  small: { right: 18, bottom: 5 },
  extraSmall: { right: 12, bottom: 10 },
  'large+extraLarge': { right: 48, bottom: 40 }
};
