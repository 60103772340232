import {
  ReplaySubject, Subject, bufferCount, debounceTime, distinctUntilChanged, filter, map, merge, of, pipe, race, startWith,
  switchMap, take, timer, withLatestFrom
} from 'rxjs';
import {
  NON_PAUSEROLLABLE, NON_PUBBABLE, NON_PUBBABLE_PAUSEROLL, PAUSEROLL, PAUSEROLL_ACTIVITY, PAUSEROLL_AD_BLOCKED, PAUSEROLL_CAPPING_MAX,
  PAUSEROLL_CAPPING_PREROLL, PAUSEROLL_TIMER, PAUSEROLL_LEAVE_TAB, PAUSEROLL_NO_PUB,
  PAUSEROLL_PLAY, PAUSEROLL_SHOW_MARGIN, PAUSEROLL_CAUSE_EMPTY,
  PAUSEROLL_CAPPING_PAUSEROLL, PAUSEROLL_FLAG_STOP_EVENTS, PAUSEROLL_FLAG,
  PAUSEROLL_SERVER_TIME_MULTIPLIER,
  PAUSEROLL_CAUSE_PUB
} from './constants';
import { VOID_FN, isUrlReachable, openUrl } from '../../utils';
import { FREEWHEEL_DEFAULT_QUERIES } from '../../ad/freewheel/types';
import { freewheel } from '../../configuration';
import { BEFORE_HOOK, INTERRUPTION_END } from '../interruptor/types';
import Interruptor from '../interruptor';
import { USER_CLOSED_MENU, USER_CLOSED_PAUSEROLL, USER_OPENED_MENU, USER_PANEL_CLOSED, USER_PANEL_OPENED, USER_PAUSE, VIDEO_START } from '../../types';
import { PLAY } from '../command/types';
import {
  selectorAdPauserollable,
  selectorArrayNotEmpty,
  selectorCappingPauseroll,
  selectorCappingPreroll,
  selectorIsAd,
  selectorOk,
  selectorPauseroll,
  selectorPauserollDelay,
  selectorReachable
} from './utils';
import { PLAYBACK_PAUSE, PLAYBACK_PLAY, PLAYBACK_PLAYING, PLAYBACK_TIMEUPDATE } from '../../store/types';
import { Disposable } from '..';

export class PauseRollController extends Disposable {
  constructor({
    playerConfig$,
    medias$,
    events$,
    userEvents$,
    interruptor: { interruptions$ },
    visibilityController: { hidden$: isTabHidden$ },
    commandController: { commands$ },
    freewheelController: { pauseRollContent$, isAd$ }
  }) {
    super();
    this.stream$ = new ReplaySubject(1).pipe(startWith({ payload: { isDisplayable: false, cause: PAUSEROLL_NO_PUB } }));
    this.onPlaying$ = new Subject();
    this.start$ = new Subject();
    this.all$ = new Subject();
    this.pauserolls$ = new ReplaySubject(1);
    this.pause$ = new Subject();
    this.flags$ = new Subject();
    this.halt$ = new Subject();
    this.adClicked$ = new Subject();

    medias$.pipe((map(() => false))).subscribe(this.halt$);

    PauseRollController.createFlagStream({
      onPlaying$: this.onPlaying$,
      medias$,
      interruptions$,
      pauserolls$: this.pauserolls$,
      isAd$
    }).subscribe(this.flags$);

    events$.pipe(
      filter((e) => e === VIDEO_START),
      switchMap(() => userEvents$.pipe(
        filter(({ action }) => action === USER_PAUSE)
      ))
    ).subscribe(this.pause$);

    PauseRollController.handlePauseAndCapping({
      commands$,
      userEvents$,
      stream$: this.onPlaying$,
      medias$
    })
      .subscribe(this.flags$);

    PauseRollController
      .createOnPlayingStreams({ userEvents$, events$, isTabHidden$, commands$ })
      .subscribe(this.all$);

    PauseRollController
      .createStartStreams({ playerConfig$, medias$, pauserolls$: this.pauserolls$ })
      .subscribe(this.start$);

    this.start$.pipe(
      PauseRollController.handleFlow({
        stream$: this.onPlaying$,
        pause$: this.pause$,
        flags$: this.flags$,
        all$: this.all$,
        pauserolls$: this.pauserolls$,
        medias$
      })
    ).subscribe(this.onPlaying$);

    pauseRollContent$
      .subscribe(this.pauserolls$);

    PauseRollController
      .shiftPauserollList({ stream$: merge(this.all$, this.onPlaying$), pauserolls$: this.pauserolls$ })
      .subscribe(this.pauserolls$);

    PauseRollController.setupImpressionsStream(this.stream$, this.pauserolls$)
      .subscribe((impressions) => { impressions.forEach((url) => fetch(url)); });

    merge(this.onPlaying$, this.flags$)
      .pipe(
        withLatestFrom(this.halt$),
        filter(([{ cause }, halt]) => cause !== PAUSEROLL_CAUSE_EMPTY && !halt),
        map(([payload]) => ({ name: PAUSEROLL, payload }))
      )
      .subscribe((payload) => {
        this.stream$.next(payload);
        if (PAUSEROLL_FLAG_STOP_EVENTS.includes(payload.payload.cause)) {
          this.halt$.next(true);
        }
      });

    this.stream$.subscribe({
      next: (e) => {
        events$.next(e);
      }
    });

    PauseRollController.createClicStream({ adClicked$: this.adClicked$, pauserolls$: this.pauserolls$ })
      .subscribe(openUrl);
  }

  static setupImpressionsStream(stream$, pauseroll$) {
    return stream$.pipe(
      filter(({ payload: { isDisplayable } }) => isDisplayable),
      withLatestFrom(pauseroll$),
      map(([, [{ impression }]]) => impression)
    );
  }

  static createFlagStream({ interruptions$, pauserolls$, onPlaying$, medias$, isAd$ }) {
    const preroll$ = PauseRollController
      .createStream({
        stream$: PauseRollController.cappingPreroll({ interruptions$, medias$ }),
        selector: VOID_FN,
        goodCause: PAUSEROLL_CAPPING_PREROLL,
        badCause: PAUSEROLL_PLAY
      });

    const withPauseRoll$ = pauserolls$.pipe(
      withLatestFrom(onPlaying$),
      map(([items]) => Boolean(items.length))
    );

    const cappingMax$ = PauseRollController
      .createStream({
        stream$: withPauseRoll$,
        selector: VOID_FN,
        badCause: PAUSEROLL_CAPPING_MAX
      });

    const adRunning$ = PauseRollController
      .createStream({
        stream$: isAd$,
        selector: selectorIsAd,
        badCause: PAUSEROLL_PLAY,
        goodCause: PAUSEROLL_CAUSE_PUB
      });

    return merge(
      preroll$,
      cappingMax$,
      adRunning$
    ).pipe(
      filter(({ cause }) => cause)
    );
  }

  static handlePauseAndCapping({ commands$, userEvents$, stream$, medias$ }) {
    const closed$ = userEvents$.pipe(filter(({ action }) => action === USER_CLOSED_PAUSEROLL));
    const play$ = commands$.pipe(filter(({ type }) => type === PLAY));

    return stream$.pipe(
      filter(({ isDisplayable }) => isDisplayable),
      switchMap(() => merge(closed$, play$).pipe(
        take(1),
        switchMap(() => merge(
          of({ isDisplayable: false, cause: PAUSEROLL_CAPPING_PAUSEROLL }),
          PauseRollController.createCappingCountDown(medias$)
        ))
      ))
    );
  }

  static shiftPauserollList({ stream$, pauserolls$ }) {
    return stream$.pipe(
      filter(({ isDisplayable }) => isDisplayable),
      switchMap(() => stream$.pipe(
        filter(({ isDisplayable, cause }) => !isDisplayable && cause === PAUSEROLL_PLAY),
        take(1),
        withLatestFrom(pauserolls$),
        map(([, pauserolls]) => {
          const a = [...pauserolls];
          a.shift();
          return a;
        }),
        debounceTime(PAUSEROLL_SHOW_MARGIN)
      ))
    );
  }

  static handleFlow({ stream$, pause$, flags$, all$, pauserolls$, medias$ }) {
    return pipe(
      switchMap((conf) => {
        if (!conf?.allowNext) {
          return of(conf);
        }
        /*  We want always send the success to the stream => events$ */
        stream$.next(conf);
        return pause$.pipe(
          withLatestFrom(flags$, pauserolls$),
          filter(([, capping, pauserolls]) => PauseRollController.checkBlockAndNext({ stream$, capping, pauserolls })),
          switchMap(() => PauseRollController.createRacerStream({ all$, medias$ }))
        );
      })
    );
  }

  static checkBlockAndNext({ stream$, capping, pauserolls }) {
    if (!pauserolls.length) {
      stream$.next({ isDisplayable: false, cause: PAUSEROLL_CAPPING_MAX });
      return false;
    }
    const hasCapping = PAUSEROLL_FLAG.includes(capping.cause);

    if (!hasCapping) {
      stream$.next({ isDisplayable: false, cause: PAUSEROLL_TIMER });
    } else {
      stream$.next(capping);
    }
    return !hasCapping;
  }

  static createRacerStream({ all$, medias$ }) {
    return race(all$, PauseRollController.counterStrike(medias$));
  }

  static counterStrike(medias$) {
    return medias$.pipe(
      switchMap((medias) => timer(selectorPauserollDelay(medias) * PAUSEROLL_SERVER_TIME_MULTIPLIER).pipe(
        map(() => ({ isDisplayable: true }))
      ))
    );
  }

  static createCappingCountDown(medias$) {
    return medias$.pipe(
      switchMap((medias) => timer(selectorCappingPauseroll(medias) * PAUSEROLL_SERVER_TIME_MULTIPLIER).pipe(
        map(() => ({ isDisplayable: false, cause: PAUSEROLL_PLAY }))
      ))
    );
  }

  static createStartStreams({ playerConfig$, medias$, pauserolls$ }) {
    const pauserollOption$ = PauseRollController.createStream({
      stream$: playerConfig$,
      selector: selectorPauseroll,
      badCause: NON_PUBBABLE_PAUSEROLL
    });

    const isPauseRollable$ = PauseRollController.createStream({
      stream$: medias$,
      selector: selectorAdPauserollable,
      badCause: NON_PAUSEROLLABLE
    });

    const isAdserverReachable$ = PauseRollController.createStream({
      stream$: isUrlReachable(`${freewheel.server}${FREEWHEEL_DEFAULT_QUERIES}`),
      selector: selectorReachable,
      badCause: PAUSEROLL_AD_BLOCKED
    });

    const pauseRollList$ = PauseRollController.createStream({
      stream$: pauserolls$,
      selector: selectorArrayNotEmpty,
      badCause: PAUSEROLL_NO_PUB
    });

    return medias$.pipe(
      switchMap(() => PauseRollController.createStream({ stream$: playerConfig$, selector: ({ showAd }) => showAd, badCause: NON_PUBBABLE })
        .pipe(
          PauseRollController.pipeAndSwitchToNext(pauserollOption$),
          PauseRollController.pipeAndSwitchToNext(isPauseRollable$),
          PauseRollController.pipeAndSwitchToNext(isAdserverReachable$),
          PauseRollController.pipeAndSwitchToNext(pauseRollList$)
        ))
    );
  }

  static createOnPlayingStreams({ userEvents$, events$, isTabHidden$, commands$ }) {
    const play$ = commands$
      .pipe(
        filter(({ type }) => type === PLAY),
        map(() => ({ isDisplayable: false, cause: PAUSEROLL_PLAY }))
      );

    const stream$ = PauseRollController.createMenuClauseStream(events$, userEvents$);

    const menuOpenedOrClosed$ = PauseRollController.createStream({
      stream$,
      selector: selectorOk
    });

    const leaveCurrentTab$ = PauseRollController.createStream({
      stream$: isTabHidden$,
      selector: VOID_FN,
      goodCause: PAUSEROLL_LEAVE_TAB
    });

    return merge(
      play$,
      menuOpenedOrClosed$,
      leaveCurrentTab$
    );
  }

  static cappingPreroll({ interruptions$, medias$ }) {
    const counter$ = medias$.pipe(
      switchMap((medias) => timer(selectorCappingPreroll(medias) * PAUSEROLL_SERVER_TIME_MULTIPLIER).pipe(
        map(() => false),
        startWith(true)
      ))
    );
    return interruptions$.pipe(
      filter(({ type, status }) => (
        type === BEFORE_HOOK
        && Interruptor.AD_END_FLAGS.includes(status)
      )),
      switchMap(({ status }) => (status === INTERRUPTION_END
        /* In case of preroll we need to wait 8 minutes */
        // ? watchTime(currentTime$, PAUSEROLL_WAIT_AFTER_PRELOAD_TIME).pipe(
        ? counter$
        : of(false)
      ))
    );
  }

  static pipeAndSwitchToNext(next$) {
    return pipe(
      switchMap((config) => (config?.allowNext
        ? next$
        : of(config)
      ).pipe(take(1)))
    );
  }

  static createStream({ stream$, selector, badCause = PAUSEROLL_CAUSE_EMPTY, goodCause = PAUSEROLL_CAUSE_EMPTY }) {
    return stream$.pipe(
      map((data) => (selector(data)
        ? {
          isDisplayable: false,
          cause: goodCause || data?.overrideCause || PAUSEROLL_CAUSE_EMPTY,
          allowNext: true }
        : {
          isDisplayable: false,
          cause: badCause || data?.overrideCause || PAUSEROLL_CAUSE_EMPTY,
          allowNext: false
        }))
    );
  }

  static createClicStream({ adClicked$, pauserolls$ }) {
    return adClicked$.pipe(
      withLatestFrom(pauserolls$),
      map(([, [{ click }]]) => click)
    );
  }

  static createIsPlayingStream(events$) {
    return merge(
      events$.pipe(
        bufferCount(3, 1),
        map((events) => events.includes(PLAYBACK_TIMEUPDATE)),
        distinctUntilChanged()
      ),
      events$.pipe(
        filter((e) => [PLAYBACK_PAUSE, PLAYBACK_PLAY, PLAYBACK_PLAYING].includes(e)),
        map((e) => [PLAYBACK_PLAY, PLAYBACK_PLAYING].includes(e))
      )
    );
  }

  static createMenuClauseStream(events$, userEvents$) {
    const isPlaying$ = PauseRollController.createIsPlayingStream(events$);
    return userEvents$.pipe(
      filter(({ action }) => [
        USER_OPENED_MENU,
        USER_CLOSED_MENU,
        USER_PANEL_OPENED,
        USER_PANEL_CLOSED].includes(action)),
      withLatestFrom(isPlaying$),
      map(([{ action }, isPlaying]) => ([USER_OPENED_MENU, USER_PANEL_OPENED].includes(action)
        ? { overrideCause: PAUSEROLL_ACTIVITY }
        : { overrideCause: isPlaying ? PAUSEROLL_PLAY : PAUSEROLL_TIMER
        }))
    );
  }

  handleAdClick() {
    this.adClicked$.next();
  }
}
